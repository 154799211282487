import { Dispatch, SetStateAction, useState } from 'react';
import { Currency, OperatorMarketId } from '@greenisland-store/gameServer';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, MenuItem, MenuProps, SxProps } from '@mui/material';
import Menu from '@mui/material/Menu';
import { alpha, styled, useTheme } from '@mui/material/styles';

import {
  TournamentDurationsEnum,
  TournamentPrizePoolEnum,
} from '../../../../pages/GameServer/TournamentConfigs/components/forms/helpers/tournamentConfigFormInterfaces';
import { DurationsEnum } from '../../../../pages/OnlineCasino/Marketing/Campaigns/forms/helpers';
import { LoyaltyDependentType } from '../../../../pages/OnlineCasino/Marketing/Components/LoyaltyDependentValue/LoyaltyDependentValue';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 130,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

interface ConfigurationOption {
  label: string;
  value:
    | TournamentDurationsEnum
    | Currency
    | TournamentPrizePoolEnum
    | LoyaltyDependentType
    | DurationsEnum
    | OperatorMarketId;
}

interface Props<T> {
  chosenValue: T;
  options: T[];
  onChange: Dispatch<SetStateAction<T>>;
  disabled?: boolean;
  sx?: SxProps;
}

const SelectDropdownButton = <T extends ConfigurationOption>({
  chosenValue,
  options,
  onChange,
  disabled = true,
  sx,
}: Props<T>) => {
  const theme = useTheme();
  const [durationMenuBtn, setDurationMenuBtn] = useState<null | HTMLElement>(null);
  const isDurationMenuBtn = Boolean(durationMenuBtn);

  const handleMenuItemClick = (option: T) => {
    onChange(option);
    setDurationMenuBtn(null);
  };

  return (
    <Box
      sx={{
        [theme.breakpoints.up('xs')]: {
          ml: 0,
          ...sx,
        },
        [theme.breakpoints.up('md')]: {
          ml: 4,
          ...sx,
        },
      }}
    >
      {!disabled && (
        <Button
          id="select-duration-btn"
          aria-controls={isDurationMenuBtn ? 'select-duration-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isDurationMenuBtn ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={event => setDurationMenuBtn(event.currentTarget)}
          disabled={disabled}
          endIcon={<KeyboardArrowDownIcon />}
        >
          {chosenValue.label}
        </Button>
      )}
      <StyledMenu
        id="select-duration-menu"
        MenuListProps={{
          'aria-labelledby': 'select-duration-btn',
        }}
        anchorEl={durationMenuBtn}
        open={isDurationMenuBtn}
        onClose={() => setDurationMenuBtn(null)}
        defaultValue={chosenValue.value}
      >
        {options.map(option => (
          <MenuItem
            key={option.value}
            onClick={() => handleMenuItemClick(option)}
            sx={{ textTransform: 'uppercase' }}
            disableRipple
          >
            {option.label}
          </MenuItem>
        ))}
      </StyledMenu>
    </Box>
  );
};

export default SelectDropdownButton;
