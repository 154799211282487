import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faAdd, faPencil } from '@fortawesome/free-solid-svg-icons';
import { CampaignSchema, useGetCampaignsOverview } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, LinearProgress, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { DataGridProProps, GridActionsCellItem, GridColumns, GridRowId, GridRowParams } from '@mui/x-data-grid-pro';

import { DataGridContainer, FontAwesomeIcon, PermissionWrapper } from '@greenisland-common/components/atoms';
import DataGridCustomPagination from '@greenisland-common/components/atoms/DataGridPagination/DataGridCustomPagination';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import { useUpdateSearchParams } from '@greenisland-common/hooks/useUpdateSearchParams';

import CampaignFormDialog from './forms/CampaignFormDialog';
import CampaignOverviewDetails from './overview/CampaignOverviewDetails';
import { getDateTimeFromUnix } from '../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../app/hooks';
import CampaignFilters from './CampaignFilters';
import { CampaignParamsType } from './consts';
import { useGetCampaignFiltersQuery, useGetFilteredCampaigns } from './hooks';

const PAGE = 0;
const PAGE_SIZE = 25;

type Row = CampaignSchema;

const Campaigns = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { campaignIdQuery, actionIdQuery } = useGetCampaignFiltersQuery();
  const updateCampaignsQueryParamsHandler = useUpdateSearchParams();

  const canReadCampaigns = usePermission(OnlineCasinoPermissions.getCampaignsOverview);
  const canAddCampaign = usePermission(OnlineCasinoPermissions.addCampaign);
  const canEditCampaign = usePermission(OnlineCasinoPermissions.updateCampaign);

  const [expandedRowIds, setExpandedRowIds] = useState<GridRowId[]>([]);
  const [isCampaignDialogOpen, setIsCampaignDialogOpen] = useState(false);
  const [chosenCampaign, setChosenCampaign] = useState<Row | null>(null);

  const { campaignPageQuery, campaignPageSizeQuery } = useGetCampaignFiltersQuery();
  const { data: campaigns, isLoading, isError } = useGetCampaignsOverview({}, { query: { enabled: canReadCampaigns } });

  const { filteredCampaigns } = useGetFilteredCampaigns(campaigns);

  useEffect(() => {
    if (campaignIdQuery || (campaignIdQuery && actionIdQuery)) {
      setExpandedRowIds([campaignIdQuery]);
    } else {
      setExpandedRowIds([]);
    }
  }, [actionIdQuery, campaignIdQuery]);

  const columns = useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'campaignId',
        headerName: capitalize(t('id')),
        sortable: true,
        minWidth: 130,
        flex: 0.1,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value || '-'}</Typography>;
        },
      },
      {
        field: 'creationDate',
        headerName: capitalize(t('created')),
        sortable: true,
        minWidth: 130,
        flex: 0.1,
        renderCell: params => {
          const startDate = params.value;
          if (startDate) {
            const formattedDaysDate = getDateTimeFromUnix(startDate).split(' ')[0];
            const formattedHoursDate = getDateTimeFromUnix(startDate).split(' ')[1];
            return (
              <Box>
                <Typography variant="body2">{formattedDaysDate}</Typography>
                <Typography variant="body2" color="GrayText">
                  {formattedHoursDate}
                </Typography>
              </Box>
            );
          }
          return <Typography variant="body2">-</Typography>;
        },
      },
      {
        field: 'campaignName',
        headerName: capitalize(t('name')),
        sortable: true,
        minWidth: 210,
        flex: 0.1,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value || '-'}</Typography>;
        },
      },
      {
        field: 'year',
        headerName: capitalize(t('year')),
        sortable: true,
        minWidth: 80,
        flex: 0.05,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value || '-'}</Typography>;
        },
      },
      {
        field: 'theme',
        headerName: capitalize(t('theme')),
        sortable: true,
        minWidth: 130,
        flex: 0.1,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value || '-'}</Typography>;
        },
      },
      ...(canEditCampaign
        ? [
            {
              field: 'actions',
              type: 'actions',
              minWidth: 50,
              flex: 0.03,
              headerName: '',
              getActions: ({ row }: GridRowParams<Row>) => [
                <>
                  <Tooltip title={t('editCampaign')} key="details">
                    <>
                      <GridActionsCellItem
                        label={t('editCampaign')}
                        icon={<FontAwesomeIcon icon={faPencil} />}
                        onClick={() => {
                          if (row) {
                            setChosenCampaign(row);
                            setIsCampaignDialogOpen(true);
                          }
                        }}
                        key="edit"
                      />
                    </>
                  </Tooltip>
                </>,
              ],
            },
          ]
        : []),
    ],
    [canEditCampaign, t]
  );

  const rows = useMemo(
    () =>
      filteredCampaigns?.map<Row>(campaign => ({
        ...campaign,
        id: campaign?.campaignId,
      })) ?? [],
    [filteredCampaigns]
  );

  const handleChangePage = (page: number) => {
    updateCampaignsQueryParamsHandler(CampaignParamsType.CAMPAIGN_PAGE, page.toString());
  };

  const handleChangeRowsPerPage = (value: number) => {
    updateCampaignsQueryParamsHandler(CampaignParamsType.CAMPAIGN_PAGE_SIZE, value.toString());
    updateCampaignsQueryParamsHandler(CampaignParamsType.CAMPAIGN_PAGE, '0');
  };

  const getDetailPanelHeight = useCallback((): 'auto' => 'auto', []);

  const getDetailPanelContent: DataGridProProps['getDetailPanelContent'] = useCallback(({ row }: { row: Row }) => {
    if (!row?.campaignId) {
      return null;
    }

    return <CampaignOverviewDetails campaignId={row?.campaignId} />;
  }, []);

  return (
    <Stack spacing={2}>
      <PermissionWrapper
        errorMessage={t('marketing.campaigns.errors.fetchError')}
        isError={isError}
        isLoading={false}
        permission={OnlineCasinoPermissions.getCampaignsOverview}
      >
        <>
          {canAddCampaign && (
            <Box>
              <Button
                variant="contained"
                size="medium"
                onClick={() => setIsCampaignDialogOpen(true)}
                startIcon={<FontAwesomeIcon icon={faAdd} />}
                sx={{
                  [theme.breakpoints.up('xs')]: {
                    mr: 0,
                  },
                  [theme.breakpoints.up('md')]: {
                    mr: 2,
                  },
                }}
              >
                {capitalize(t('addCampaign'))}
              </Button>
            </Box>
          )}
          <CampaignFilters campaigns={filteredCampaigns} />
          <DataGridContainer>
            <LimitedDataGrid
              density="compact"
              autoHeight
              loading={isLoading}
              columns={columns}
              rows={rows}
              getDetailPanelHeight={getDetailPanelHeight}
              getDetailPanelContent={getDetailPanelContent}
              detailPanelExpandedRowIds={expandedRowIds}
              onDetailPanelExpandedRowIdsChange={ids => setExpandedRowIds(ids)}
              pagination
              page={campaignPageQuery ? Number(campaignPageQuery) : PAGE}
              pageSize={campaignPageSizeQuery ? Number(campaignPageSizeQuery) : PAGE_SIZE}
              paginationMode="client"
              components={{
                LoadingOverlay: LinearProgress,
                Footer: () => (
                  <>
                    <DataGridCustomPagination
                      page={campaignPageQuery ? Number(campaignPageQuery) : PAGE}
                      rowsPerPage={campaignPageSizeQuery ? Number(campaignPageSizeQuery) : PAGE_SIZE}
                      rowsPerPageOptions={[10, 25, 50, 100, 200]}
                      count={
                        filteredCampaigns?.length
                          ? Math.ceil(
                              filteredCampaigns?.length /
                                (campaignPageSizeQuery ? Number(campaignPageSizeQuery) : PAGE_SIZE)
                            )
                          : 0
                      }
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      paginationMode="client"
                    />
                  </>
                ),
              }}
            />
          </DataGridContainer>
          {isCampaignDialogOpen && (
            <CampaignFormDialog
              open={true}
              onClose={() => {
                setIsCampaignDialogOpen(false);
                setChosenCampaign(null);
              }}
              chosenCampaign={chosenCampaign}
            />
          )}
        </>
      </PermissionWrapper>
    </Stack>
  );
};

export default Campaigns;
