import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  EMediaGalleryEntryTypeSchema,
  FreeSpinsCampaignSchema,
  ImageSchema,
  IndividualActionSchema,
  UpdateIndividualActionSchema,
  useGetFreeSpinsCampaigns,
} from '@greenisland-api';
import { Autocomplete, capitalize, TextField, Typography } from '@mui/material';

import Input from '@greenisland-common/components/molecules/Input';
import { MediaFieldsWithPreview } from '@greenisland-common/components/molecules/MediaField';

import { formatCurrency } from '../../../../../../app/helpers/transformFunctions';
import { getDefaultIndividualActionNumberInputProps, useGenerateFreeSpinsAutocompleteItems } from '../helpers';
import IndividualActionFormWrapper from '../IndividualActionFormWrapper';

interface Props {
  individualAction?: IndividualActionSchema;
}

const IndividualActionFreeSpinsForm = ({ individualAction }: Props) => {
  const { t } = useTranslation();
  const { register, watch, setValue, control, errors } = useFormContext<UpdateIndividualActionSchema>();

  const isFreeSpinsEnabled = !!watch('freeSpins.enabled');
  const watchFreeSpinsCampaign: FreeSpinsCampaignSchema | undefined = watch('freeSpins.freeSpinsCampaign');
  const watchFreeSpinsImages: ImageSchema[] | null = watch('freeSpins.images');

  const { data: freeSpinsCampaigns, isLoading: areFreeSpinsCampaignsLoading } = useGetFreeSpinsCampaigns({
    query: { enabled: isFreeSpinsEnabled },
  });

  const [selectedProvider, setSelectedProvider] = useState<string | null>(null);
  const [selectedGame, setSelectedGame] = useState<string | null>(null);
  const [selectedAmountOfSpins, setSelectedAmountOfSpins] = useState<string | null>(null);
  const [selectedStake, setSelectedStake] = useState<string | null>(null);

  useEffect(() => {
    if (watchFreeSpinsCampaign) {
      setSelectedProvider(watchFreeSpinsCampaign?.provider?.name ?? null);
      setSelectedAmountOfSpins(watchFreeSpinsCampaign?.amountOfSpins?.toString() ?? null);
      setSelectedStake(watchFreeSpinsCampaign?.stake?.toString() ?? null);
      setSelectedGame(watchFreeSpinsCampaign?.game?.name ?? null);
    }
  }, [watchFreeSpinsCampaign]);

  useEffect(() => {
    if (watchFreeSpinsImages) {
      const imagesIds = watchFreeSpinsImages?.map(image => image?.id);
      setValue('freeSpins.imageIds', imagesIds?.length ? imagesIds : null);
    }
  }, [setValue, watchFreeSpinsImages]);

  const { campaigns, sortedProviders, sortedGames, sortedAmountOfSpins, sortedStakes } =
    useGenerateFreeSpinsAutocompleteItems(
      freeSpinsCampaigns,
      selectedProvider,
      selectedGame,
      selectedAmountOfSpins,
      selectedStake
    );

  return (
    <IndividualActionFormWrapper enabled={isFreeSpinsEnabled} name="freeSpins.enabled" listItemText={t('freeSpins')}>
      <>
        <Autocomplete
          fullWidth={true}
          value={selectedProvider}
          onChange={(_event, value) => setSelectedProvider(value)}
          options={sortedProviders}
          renderInput={params => (
            <TextField required={false} {...params} size="small" label={capitalize(t('provider'))} />
          )}
        />
        <Autocomplete
          fullWidth={true}
          value={selectedGame}
          onChange={(_event, value) => setSelectedGame(value)}
          options={sortedGames}
          renderInput={params => <TextField required={false} {...params} size="small" label={capitalize(t('game'))} />}
        />
        <Autocomplete
          fullWidth={true}
          value={selectedAmountOfSpins}
          onChange={(_event, value) => setSelectedAmountOfSpins(value)}
          options={sortedAmountOfSpins}
          renderInput={params => (
            <TextField required={false} {...params} size="small" label={capitalize(t('amountOfSpins'))} />
          )}
        />
        <Autocomplete
          fullWidth={true}
          value={selectedStake}
          onChange={(_event, value) => setSelectedStake(value)}
          options={sortedStakes}
          getOptionLabel={(stake: string) => formatCurrency(parseFloat(stake))}
          renderInput={params => <TextField required={false} {...params} size="small" label={capitalize(t('stake'))} />}
        />
        <Controller
          control={control}
          name="freeSpins.freeSpinsCampaign"
          defaultValue={individualAction?.freeSpins?.freeSpinsCampaign}
          render={({ value, onChange }) => (
            <Autocomplete
              fullWidth={true}
              loading={areFreeSpinsCampaignsLoading}
              value={value}
              onChange={(_event, value) => onChange(value)}
              options={campaigns}
              getOptionLabel={(campaign: FreeSpinsCampaignSchema) => String(campaign?.name)}
              renderOption={(props, option) => (
                <li {...props} key={option?.name}>
                  {option?.name}
                </li>
              )}
              sx={{ mb: 2 }}
              renderInput={params => (
                <TextField
                  required={isFreeSpinsEnabled}
                  {...params}
                  size="small"
                  label={capitalize(t('freeSpinCampaign'))}
                />
              )}
            />
          )}
        />
        <Input
          size="small"
          name="freeSpins.expiresInDays"
          label={capitalize(t('expiresInDays'))}
          inputRef={register({
            required: isFreeSpinsEnabled,
            setValueAs: value => (value ? Number(value) : 1),
          })}
          required={isFreeSpinsEnabled}
          InputProps={getDefaultIndividualActionNumberInputProps(1)}
          disabled={!isFreeSpinsEnabled}
          error={Boolean(errors?.freeSpins?.expiresInDays)}
          helperText={errors?.freeSpins?.expiresInDays?.message}
          fullWidth={true}
          defaultValue={individualAction?.freeSpins?.expiresInDays}
        />
        <Typography fontWeight={600}>{capitalize(t('depositBonus.subtitle.imageSettings'))}</Typography>
        <MediaFieldsWithPreview
          control={control}
          size="small"
          name="freeSpins.images"
          error={Boolean(errors?.freeSpins?.imageIds)}
          type="text"
          required={false}
          label={capitalize(t('addImages'))}
          mediaGalleryEntryType={EMediaGalleryEntryTypeSchema.Template}
          isMultiSelect={true}
        />
      </>
    </IndividualActionFormWrapper>
  );
};

export default IndividualActionFreeSpinsForm;
