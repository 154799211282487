import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CasinoSchema,
  DepositManuallyDataSchema,
  EDepositManuallySourceSchema,
  useDepositManually,
  useGetCasinos,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Stack,
  styled,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import { SelectInput } from '@greenisland-common/components/atoms';

import { formatCurrency } from '../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../app/hooks';

const StyledCard = styled(Card)(({ theme }) => ({
  width: 'calc(100% / 3)',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}));

const DepositManually = () => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const canReadCasinoList = usePermission(OnlineCasinoPermissions.getCasinos);
  const canDepositManually = usePermission(OnlineCasinoPermissions.depositManually);

  const { data: casinos, isLoading } = useGetCasinos({
    query: { enabled: canReadCasinoList },
  });

  const methods = useForm<DepositManuallyDataSchema>({
    defaultValues: { source: EDepositManuallySourceSchema.wireTransfer, userId: userId, amount: 0, sourceData: '' },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = methods;

  const [openDialog, setOpenDialog] = useState<DepositManuallyDataSchema | undefined>(undefined);

  useEffect(() => {
    setValue('source', EDepositManuallySourceSchema.wireTransfer);
  }, [setValue]);

  const isCasinoSource = watch('source') === EDepositManuallySourceSchema.casino;

  const onOpenDialog = (data: DepositManuallyDataSchema) => {
    setOpenDialog(data);
  };

  return canDepositManually ? (
    <FormProvider {...methods}>
      <StyledCard>
        <form onSubmit={handleSubmit(onOpenDialog)}>
          <CardContent>
            <Stack spacing={2}>
              <TextField
                label={t('userId')}
                name={'userId'}
                fullWidth
                size="small"
                inputRef={register({ required: true })}
                error={!!errors.userId}
                helperText={errors.userId ? t('userIdRequired') : ''}
              />
              <TextField
                name="amount"
                label={t('amountOfDeposit')}
                fullWidth
                type="number"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon={faEuroSign} />
                    </InputAdornment>
                  ),
                }}
                inputRef={register({
                  validate: value => value > 0,
                  required: true,
                })}
                inputProps={{ step: 0.01 }}
                error={!!errors.amount}
                helperText={errors.amount ? t('amountValidationMessageZero') : ''}
              />
              <SelectInput
                size="small"
                name="source"
                label={t('source')}
                rules={{ required: true }}
                options={Object.values(EDepositManuallySourceSchema).map(provider => ({
                  label: t(provider),
                  value: provider,
                }))}
              />
              {isCasinoSource ? (
                <SelectInput
                  size="small"
                  name="sourceData"
                  label={t('casino')}
                  rules={{ required: true }}
                  options={
                    casinos && casinos?.length > 0
                      ? casinos.map(casino => ({ label: casino.name, value: casino.casinoId }))
                      : [{ label: t('placeholder'), value: 'placeholder' }]
                  }
                />
              ) : (
                <TextField
                  size="small"
                  name="sourceData"
                  label={t('iban')}
                  fullWidth
                  inputRef={register({ required: true })}
                  error={!!errors.sourceData}
                  helperText={errors.sourceData ? t('fieldIsRequired') : ''}
                />
              )}
            </Stack>
          </CardContent>
          <CardActions>
            <Button size="large" variant="contained" color="primary" fullWidth type="submit" disabled={isLoading}>
              {t('deposit.title')}
            </Button>
          </CardActions>
        </form>
      </StyledCard>
      {openDialog && <ConfirmDialog casinoList={casinos ?? []} data={openDialog} setOpenDialog={setOpenDialog} />}
    </FormProvider>
  ) : null;
};

const ConfirmDialog = (props: {
  data: DepositManuallyDataSchema;
  setOpenDialog: React.Dispatch<React.SetStateAction<DepositManuallyDataSchema | undefined>>;
  casinoList: CasinoSchema[] | undefined;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, setOpenDialog, casinoList } = props;
  const { t } = useTranslation();
  const { mutate: depositMutate, isLoading: isDepositManuallyLoading } = useDepositManually({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: error => {
        enqueueSnackbar(error?.message || t('somethingWentWrong'), { variant: 'error' });
      },
      onSettled: () => {
        setOpenDialog(undefined);
      },
    },
  });

  const onSubmit = () => {
    if (data) {
      depositMutate({
        data: {
          ...data,
          amount: Number(data.amount),
        },
      });
    }
  };

  return (
    <Dialog open={!!data}>
      <DialogTitle>{t('confirmDeposit')}</DialogTitle>
      <DialogContent>
        <Box>
          <Box>
            {t('userId')}: {data?.userId}
          </Box>
          <Box>
            {t('amountOfDeposit')}: {data && formatCurrency(Number(data.amount))}
          </Box>
          <Box>
            {t('source')}: {data?.source}
          </Box>
          <Box>
            {data?.source === EDepositManuallySourceSchema.wireTransfer ? 'IBAN' : t('casino')}:{' '}
            {casinoList?.find(casino => casino.casinoId === data.sourceData)?.name}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialog(undefined)} color="secondary">
          {t('cancel')}
        </Button>
        <LoadingButton
          loading={isDepositManuallyLoading}
          variant="contained"
          type="submit"
          color="primary"
          onClick={() => onSubmit()}
        >
          {t('confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DepositManually;
