import { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, capitalize, FormControlLabel, InputAdornment, Switch, useTheme } from '@mui/material';

import Input from '@greenisland-common/components/molecules/Input';

import LoyaltyDependentValue, {
  LoyaltyDependentIconType,
  LoyaltyDependentType,
} from '../../../Components/LoyaltyDependentValue/LoyaltyDependentValue';
import {
  BonusLoyaltyDependentTypeEnum,
  ExtendedUpdateIndividualActionSchema,
  useGetDepositBonusLoyaltyDependentIcon,
} from '../helpers';

interface Props {
  isExtendedLoyaltyDependentMode: boolean;
  handleLoyaltyDependentChange: (event: ChangeEvent<HTMLInputElement>, type: BonusLoyaltyDependentTypeEnum) => void;
  mainLabel: string;
  inputLabel: string;
  name: string;
  enabledDeposit: boolean;
  loyaltyDependantType: BonusLoyaltyDependentTypeEnum;
  iconType: LoyaltyDependentIconType;
  loyaltyDependentOptions: {
    name: string;
    label: string;
  }[];
}

const IndividualActionBonusLoyaltyDependantSection = ({
  isExtendedLoyaltyDependentMode,
  handleLoyaltyDependentChange,
  mainLabel,
  inputLabel,
  name,
  enabledDeposit,
  loyaltyDependantType,
  iconType,
  loyaltyDependentOptions,
}: Props) => {
  const theme = useTheme();
  const { register, errors } = useFormContext<ExtendedUpdateIndividualActionSchema>();

  const icon = useGetDepositBonusLoyaltyDependentIcon(iconType);

  return (
    <Box p={1} borderRadius="5px" border={`1px solid ${theme.palette.text.disabled}`}>
      <FormControlLabel
        control={
          <Switch
            checked={isExtendedLoyaltyDependentMode}
            onChange={e => handleLoyaltyDependentChange(e, loyaltyDependantType)}
          />
        }
        label={capitalize(mainLabel)}
        disabled={!enabledDeposit}
        sx={{
          width: 'fit-content',
          '.MuiFormControlLabel-label': {
            fontWeight: isExtendedLoyaltyDependentMode ? 600 : 400,
          },
        }}
      />
      <Input
        size="small"
        key={name}
        name={name}
        error={Boolean(errors[name as keyof typeof errors])}
        disabled={!enabledDeposit || isExtendedLoyaltyDependentMode}
        required={enabledDeposit && !isExtendedLoyaltyDependentMode}
        type="number"
        inputProps={{ min: 0 }}
        InputProps={{
          startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
        }}
        inputRef={register({
          required: enabledDeposit && !isExtendedLoyaltyDependentMode,
          min: 0,
          setValueAs: value => parseInt(value),
        })}
        label={capitalize(inputLabel)}
        sx={{
          display: isExtendedLoyaltyDependentMode ? 'none' : 'flex',
          mt: 2,
        }}
      />
      <LoyaltyDependentValue
        title={inputLabel}
        type={LoyaltyDependentType.DECIMAL}
        disabled={true}
        showSelectOptions={false}
        iconType={iconType}
        options={loyaltyDependentOptions}
        sx={{
          display: isExtendedLoyaltyDependentMode ? 'flex' : 'none',
        }}
        isRequired={enabledDeposit && isExtendedLoyaltyDependentMode}
      />
    </Box>
  );
};

export default IndividualActionBonusLoyaltyDependantSection;
