import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import {
  CampaignTemplateDetailsSchema,
  CampaignTemplateSchema,
  getGetCampaignTemplateQueryKey,
  TemplateTypeSchema,
  useAddCampaignTemplate,
  useGetCampaign,
  useGetCampaignTemplate,
  useGetTemplateTypes,
  useUpdateCampaignTemplate,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useAppSelector } from '@greenisland-core/store';
import { Box, CircularProgress, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';

import { usePermission } from '../../../../../app/hooks';
import TemplateForm from '../forms/TemplateForm';

const TemplateOverviewDetails = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { campaignId, templateType }: any = useParams();
  const navigate = useNavigate();

  const canReadTemplateTypes = usePermission(OnlineCasinoPermissions.getTemplateTypes);
  const canReadCampaign = usePermission(OnlineCasinoPermissions.getCampaign);
  const canReadDefaultCampaignTemplate = usePermission(OnlineCasinoPermissions.getDefaultCampaignTemplate);

  const { selectedEntity, selectedProduct } = useAppSelector(state => state.entities);

  const { data: templateTypes, isLoading: isTemplateTypesLoading } = useGetTemplateTypes({
    query: { enabled: canReadTemplateTypes },
  });

  const { data: getCampaign, isLoading: isGetCampaignLoading } = useGetCampaign(campaignId, {
    query: { enabled: canReadCampaign && !!campaignId },
  });

  const { data: campaignTemplate, isLoading: isCampaignTemplateLoading } = useGetCampaignTemplate(
    campaignId,
    templateType,
    {
      query: { enabled: canReadDefaultCampaignTemplate && !!campaignId && !!templateType && templateType !== 'add' },
    }
  );

  const { mutate: addCampaignTemplate } = useAddCampaignTemplate({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        queryClient.invalidateQueries(getGetCampaignTemplateQueryKey(campaignId, templateType));
        navigate(`../campaigns/overview?campaignId=${campaignId}`);
      },
      onError: error => {
        enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
      },
    },
  });

  const { mutate: updateCampaignTemplate } = useUpdateCampaignTemplate({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        navigate(`../campaigns/overview?campaignId=${campaignId}`);
        queryClient.invalidateQueries(getGetCampaignTemplateQueryKey(campaignId, templateType));
      },
      onError: error => {
        enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
      },
    },
  });

  const filterTemplateTypes = () => {
    let filteredTemplateTypes = templateTypes;

    if (templateType === 'add') {
      if (getCampaign && getCampaign?.templates) {
        const usedTemplatesTypes = getCampaign?.templates?.map((template: CampaignTemplateSchema) => template.type);
        filteredTemplateTypes = templateTypes?.filter(
          (type: any) => usedTemplatesTypes && !usedTemplatesTypes?.includes(type.name)
        );
      }
    } else {
      const usedTemplatesTypes = getCampaign?.templates?.map((template: CampaignTemplateSchema) => template.type);

      filteredTemplateTypes = templateTypes?.filter(
        (type: any) => usedTemplatesTypes && usedTemplatesTypes?.includes(type.name)
      );
    }

    return filteredTemplateTypes as TemplateTypeSchema[];
  };

  const submitCampaignTemplate = (data: CampaignTemplateDetailsSchema) => {
    if (templateType === 'add') {
      addCampaignTemplate({ campaignId, data });
    } else {
      updateCampaignTemplate({ campaignId, templateType, data });
    }
  };

  const loading =
    templateType !== 'add'
      ? !campaignTemplate || !getCampaign || isTemplateTypesLoading || isCampaignTemplateLoading || isGetCampaignLoading
      : !getCampaign && isTemplateTypesLoading && isCampaignTemplateLoading && !isGetCampaignLoading;

  return canReadTemplateTypes ? (
    <Stack spacing={2}>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : (
        <TemplateForm
          onSubmit={data => submitCampaignTemplate(data)}
          templateTypes={filterTemplateTypes()}
          templateData={templateType !== 'add' ? campaignTemplate : undefined}
          returnLink={`/${selectedEntity}/${selectedProduct}/marketing/campaigns/overview/${campaignId}`}
        />
      )}
    </Stack>
  ) : null;
};

export default memo(TemplateOverviewDetails);
