import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  getGetPlayingBreakQueryKey,
  getGetUserGeneralQueryKey,
  useCancelPlayingBreak,
  UserGeneralSchema,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useTenantContext } from 'src/app/context/TenantContext';

import GenericField from './GenericField';

interface Props {
  user: UserGeneralSchema;
}

const PlayingBreakField = ({ user }: Props) => {
  const tenant = useTenantContext();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { mutate } = useCancelPlayingBreak({
    mutation: {
      onSuccess: async () => {
        queryClient.invalidateQueries(getGetUserGeneralQueryKey(user.userId));
        queryClient.invalidateQueries(getGetPlayingBreakQueryKey(parseInt(user.userId)));
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: async () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  const { isOnPlayingBreak, statusEndDate } = user;

  return (
    <GenericField
      value={isOnPlayingBreak && statusEndDate ? format(statusEndDate * 1000, 'dd/MM/yyyy - HH:mm') : isOnPlayingBreak}
      error={isOnPlayingBreak}
      disabled={!isOnPlayingBreak || user.isSuspended || user.isUnderInvestigation}
      tooltip={
        isOnPlayingBreak
          ? t('lookup.verification.reducePlayingBreakTitle', { days: tenant.playingBreakConfigurations.timeoutDays })
          : t('lookup.verification.addPlayingBreakTitle')
      }
      description={t('lookup.verification.reducePlayingBreakDescription')}
      togglePermissions={OnlineCasinoPermissions.setUserStatus}
      onToggle={() => mutate({ userId: parseInt(user.userId) })}
    />
  );
};

export default PlayingBreakField;
