import { ChangeEvent, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import {
  EAggregationFunctionSchema,
  EMediaGalleryEntryTypeSchema,
  EPayoutTypeSchema,
  EUpdateBoostBadRequestTypeSchema,
  getGetBoostsQueryKey,
  ImageSchema,
  UpdateGlobalBoostSchema,
  useCreateGlobalBoost,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import {
  Box,
  Button,
  capitalize,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { getUnixTime } from 'date-fns';
import { useSnackbar } from 'notistack';

import { FontAwesomeIcon, PermissionWrapper, SelectInput } from '@greenisland-common/components/atoms';
import DateTimeInput from '@greenisland-common/components/molecules/DateTimeInput/DateTimeInput';
import Input from '@greenisland-common/components/molecules/Input';
import { MediaFieldsWithPreview } from '@greenisland-common/components/molecules/MediaField';
import SelectInputDurationField from '@greenisland-common/components/organisms/SelectInputDurationField';

import { ContentFilterButton } from '../../../Content/ContentFilter/components';
import {
  BonusLoyaltyDependentTypeEnum,
  calculatePromotionDuration,
  ExtendedBoostSchema,
  getDefaultIndividualActionNumberInputProps,
  useBonusDepositLoyaltyDependentOptions,
  useDurationValues,
} from '../../Campaigns/forms/helpers';
import IndividualActionBonusLoyaltyDependantSection from '../../Campaigns/forms/individualActionSections/IndividualActionBonusLoyaltyDependantSection';
import IndividualActionBoostEarlyMaxPayoutFormSection from '../../Campaigns/forms/individualActionSections/IndividualActionBoostEarlyMaxPayoutFormSection';
import { LoyaltyDependentIconType } from '../../Components/LoyaltyDependentValue/LoyaltyDependentValue';
import { GLOBAL_BOOST_ERROR_REASONS } from '../constants/GlobalBoostsErrorReasons';

interface Props {
  open: boolean;
  onClose: () => void;
}

const MAX_PAYOUT_LOYALTY_DEPENDANT_KEY = 'loyaltyDependentMaxPayout';

const IndividualActionEarlyMaxPayoutFormSection = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const durationOptions = useDurationValues();
  const loyaltyDependentOptions = useBonusDepositLoyaltyDependentOptions(
    BonusLoyaltyDependentTypeEnum.LIMIT,
    MAX_PAYOUT_LOYALTY_DEPENDANT_KEY
  );

  const methods = useForm<ExtendedBoostSchema>({
    shouldFocusError: true,
    shouldUnregister: false,
    mode: 'onChange',
    defaultValues: {
      isMaxPayoutEnabled: false,
      isEarlyMaxPayoutEnabled: false,
    },
  });

  const { handleSubmit, watch, setValue, register, errors, control } = methods;

  const watchIsMaxPayoutEnabled = !!watch('isMaxPayoutEnabled');
  const watchIsEarlyMaxPayoutEnabled = watch('isEarlyMaxPayoutEnabled');
  const watchBoostImages: ImageSchema[] | null = watch('images');

  const [boostDurationSelectOption, setBoostDurationSelectOption] = useState(durationOptions.SECONDS);

  useEffect(() => {
    if (watchBoostImages) {
      const imagesIds = watchBoostImages?.map(image => image?.id);
      setValue('imageIds', imagesIds?.length ? imagesIds : null);
    }
  }, [setValue, watchBoostImages]);

  useEffect(() => {
    if (boostDurationSelectOption) {
      setValue('durationSelectOption', boostDurationSelectOption.value);
    }
  }, [boostDurationSelectOption, register, setValue]);

  useEffect(() => {
    if (!watchIsEarlyMaxPayoutEnabled) {
      setValue('earlyMaxPayoutEnabled', undefined);
    }
  }, [setValue, watchIsEarlyMaxPayoutEnabled]);

  const handleLoyaltyDependentChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue('isMaxPayoutEnabled', event.target.checked);
  };

  const {
    mutate: createGlobalBoost,
    isLoading,
    isError,
  } = useCreateGlobalBoost({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        queryClient.invalidateQueries(getGetBoostsQueryKey());
        onClose();
      },
      onError: error => {
        if (error) {
          if ('type' in error && error.type) {
            const errorReason: EUpdateBoostBadRequestTypeSchema | undefined = error.type;
            const translationKey = errorReason ? GLOBAL_BOOST_ERROR_REASONS[errorReason] : 'somethingWentWrong';
            enqueueSnackbar(t(translationKey), { variant: 'error' });
          } else {
            enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
          }
        }
      },
    },
  });

  const onSubmit = (data: ExtendedBoostSchema) => {
    const generateGlobalBoostsPayload = {
      ...data,
      factor: data?.factor ? data?.factor / 100.0 : 100,
      startDate: data?.startDate ? getUnixTime(data?.startDate) : null,
      duration: calculatePromotionDuration(data?.duration, data?.durationSelectOption),
      loyaltyDependentMaxPayout: data?.isMaxPayoutEnabled ? data?.loyaltyDependentMaxPayout : null,
    } as UpdateGlobalBoostSchema;

    return createGlobalBoost({ data: generateGlobalBoostsPayload });
  };

  return (
    <Dialog open={open} fullWidth={true}>
      <PermissionWrapper
        errorMessage={t('boost.global.errors.createError')}
        isError={isError}
        isLoading={false}
        permission={OnlineCasinoPermissions.createGlobalBoost}
      >
        <Stack spacing={2}>
          <FormProvider {...methods}>
            <DialogTitle>{t('boost.global.create.title')}</DialogTitle>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <FontAwesomeIcon icon={faClose} />
            </IconButton>
            <DialogContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Input
                    size="small"
                    name="name"
                    label={capitalize(t('boost.name'))}
                    control={control}
                    type="text"
                    defaultValue=""
                    required
                    rules={{ required: t('fieldIsRequired'), maxLength: 256 }}
                    sx={{ mb: 2 }}
                    error={Boolean(errors?.name)}
                    fullWidth={true}
                    helperText={
                      errors?.name
                        ? capitalize(t('boost.helpText.name.error'))
                        : capitalize(t('boost.helpText.name.default'))
                    }
                  />
                  <Typography fontWeight={600}>{capitalize(t('boost.subtitle.payoutSettings'))}</Typography>
                  <SelectInput
                    size="small"
                    name="aggregationFunction"
                    label={t('boost.aggregationFunction')}
                    options={[
                      { value: EAggregationFunctionSchema.Max, label: EAggregationFunctionSchema.Max },
                      { value: EAggregationFunctionSchema.Sum, label: EAggregationFunctionSchema.Sum },
                    ]}
                    required={true}
                    defaultValue={EAggregationFunctionSchema.Max}
                  />
                  <Input
                    size="small"
                    name="factor"
                    label={capitalize(t('boost.factor'))}
                    control={control}
                    defaultValue={100}
                    required
                    rules={{ required: t('fieldIsRequired'), setValueAs: value => (value ? Number(value) : 100) }}
                    sx={{ mb: 2 }}
                    error={Boolean(errors?.factor)}
                    fullWidth={true}
                    helperText={
                      errors?.factor
                        ? capitalize(t('boost.helpText.factor.error'))
                        : capitalize(t('boost.helpText.factor.default'))
                    }
                    InputProps={{
                      ...getDefaultIndividualActionNumberInputProps(1),
                      endAdornment: <InputAdornment position={'end'}>%</InputAdornment>,
                    }}
                  />
                  <SelectInput
                    size="small"
                    name="payoutType"
                    label={t('boost.payoutType')}
                    options={[
                      { value: EPayoutTypeSchema.Cash, label: EPayoutTypeSchema.Cash },
                      { value: EPayoutTypeSchema.Voucher, label: EPayoutTypeSchema.Voucher },
                    ]}
                    required={true}
                    defaultValue={EPayoutTypeSchema.Cash}
                  />
                  <IndividualActionBonusLoyaltyDependantSection
                    enabledDeposit={open}
                    name="maxPayout"
                    isExtendedLoyaltyDependentMode={watchIsMaxPayoutEnabled}
                    loyaltyDependantType={BonusLoyaltyDependentTypeEnum.LIMIT}
                    mainLabel={t('boost.loyaltyDependantMaxPayout')}
                    inputLabel={t('boost.maxPayout')}
                    handleLoyaltyDependentChange={handleLoyaltyDependentChange}
                    iconType={LoyaltyDependentIconType.EURO}
                    loyaltyDependentOptions={loyaltyDependentOptions}
                  />
                  <IndividualActionBoostEarlyMaxPayoutFormSection
                    switchControlName="isEarlyMaxPayoutEnabled"
                    name="earlyMaxPayoutEnabled"
                  />
                  <Typography fontWeight={600}>{capitalize(t('boost.subtitle.contentSettings'))}</Typography>
                  <ContentFilterButton control={control} name="contentFilterId" />
                  <Typography fontWeight={600}>{capitalize(t('boost.imageSettings'))}</Typography>
                  <MediaFieldsWithPreview
                    control={control}
                    size="small"
                    name="images"
                    error={Boolean(errors?.imageIds)}
                    type="text"
                    required={false}
                    label={capitalize(t('addImages'))}
                    mediaGalleryEntryType={EMediaGalleryEntryTypeSchema.BoostImage}
                    isMultiSelect={true}
                  />
                  <DateTimeInput name="startDate" label={capitalize(t('boost.startDate'))} required={true} />
                  <SelectInputDurationField
                    size="small"
                    name="duration"
                    label={capitalize(t('boost.duration'))}
                    chosenValue={boostDurationSelectOption}
                    options={Object.values(durationOptions)}
                    onChange={setBoostDurationSelectOption}
                    required={true}
                    InputProps={getDefaultIndividualActionNumberInputProps(1)}
                    inputRef={register({
                      required: true,
                      setValueAs: value => (value ? Number(value) : 1),
                    })}
                    disabled={false}
                    error={Boolean(errors?.duration)}
                    sx={{
                      '.MuiInputBase-root': {
                        paddingRight: '2px',
                      },
                    }}
                  />
                </Box>
                <DialogActions>
                  <Button fullWidth={true} variant="text" onClick={onClose} color="secondary">
                    {t('cancel')}
                  </Button>
                  <Button fullWidth={true} color="primary" variant="contained" type="submit" disabled={isLoading}>
                    {t('save')}
                  </Button>
                </DialogActions>
              </form>
            </DialogContent>
          </FormProvider>
        </Stack>
      </PermissionWrapper>
    </Dialog>
  );
};

export default IndividualActionEarlyMaxPayoutFormSection;
