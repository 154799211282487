import {
  type UpdateVoucherActionSchema,
  EAggregationFunctionSchema,
  EBoostTypeSchema,
  EPayoutTypeSchema,
  IndividualActionSchema,
  LoyaltyDependentDecimalSchema,
} from '@greenisland-api';
import { Currency } from '@greenisland-store/gameServer';
import { getUnixTime } from 'date-fns';

import { convertToMediaImageSchema } from '@greenisland-common/helpers';
import { calculateTicks, calculateTime } from '@greenisland-common/helpers/timeConverters';

import {
  DurationsEnum,
  ExtendedBoostSchema,
  ExtendedDepositBonusSchema,
  ExtendedPlaythroughBonusSchema,
  ExtendedUpdateIndividualActionSchema,
} from './individualActionsFormInterfaces';

const getDefaultIndividualActionNumberInputProps = (minValue?: number) => {
  return {
    inputProps: {
      min: minValue || 0,
      type: 'number',
      pattern: '[0-9]*',
    },
  };
};

const calculatePromotionDuration = (expirationDurationValue: number, expirationDurationSelectOption: DurationsEnum) => {
  switch (expirationDurationSelectOption) {
    case DurationsEnum.SECONDS:
      return expirationDurationValue;
    case DurationsEnum.MINUTES:
      return expirationDurationValue * 60;
    case DurationsEnum.HOURS:
      return expirationDurationValue * 3600;
    case DurationsEnum.DAYS:
      return expirationDurationValue * 86400;
    default:
      return 0;
  }
};

const getBonusLoyaltyDependentValues = (
  isLoyaltyDependent: boolean,
  data: number | LoyaltyDependentDecimalSchema
): LoyaltyDependentDecimalSchema => {
  const result: LoyaltyDependentDecimalSchema = {};
  const defaultValues: Partial<LoyaltyDependentDecimalSchema> = {
    standardValue: 0,
    bronzeValue: 0,
    silverValue: 0,
    goldValue: 0,
    platinumValue: 0,
    diamondValue: 0,
    vipValue: 0,
  };

  if (!isLoyaltyDependent) {
    const value = data as number;
    Object.keys(defaultValues).forEach(property => {
      result[property as keyof LoyaltyDependentDecimalSchema] = value || 0;
    });
  } else {
    const values = data as LoyaltyDependentDecimalSchema;
    Object.entries(values).forEach(([property, value]) => {
      if (value) {
        result[property as keyof LoyaltyDependentDecimalSchema] = parseFloat(value.toString());
      }
    });
  }

  return result;
};

const generateBonusPlaythroughPayload = (playthroughBonus: ExtendedPlaythroughBonusSchema) => {
  const isLoyaltyDependentAmountEnabled = !!playthroughBonus?.isLoyaltyDependentAmountEnabled;
  const isLoyaltyDependentPlaythroughEnabled = !!playthroughBonus?.isLoyaltyDependentPlaythroughEnabled;

  const loyaltyDependentAmountData = isLoyaltyDependentAmountEnabled
    ? playthroughBonus?.loyaltyDependentAmount
    : playthroughBonus?.loyaltyDependentSingleValue;

  const loyaltyDependentPlaythroughData = isLoyaltyDependentPlaythroughEnabled
    ? playthroughBonus?.loyaltyDependentPlaythrough
    : playthroughBonus?.loyaltyDependentPlaythroughSingleValue;

  return {
    ...playthroughBonus,
    loyaltyDependentAmount: getBonusLoyaltyDependentValues(isLoyaltyDependentAmountEnabled, loyaltyDependentAmountData),
    loyaltyDependentPlaythrough: getBonusLoyaltyDependentValues(
      isLoyaltyDependentPlaythroughEnabled,
      loyaltyDependentPlaythroughData
    ),
    expiryDuration: calculatePromotionDuration(
      playthroughBonus?.expiryDuration,
      playthroughBonus?.expiryDurationSelectOption
    ),
  };
};

const generateDepositBonusPayload = (depositBonus: ExtendedDepositBonusSchema) => {
  const isLoyaltyDependentPercentageEnabled = !!depositBonus?.isLoyaltyDependentPercentageEnabled;
  const isLoyaltyDependentLimitEnabled = !!depositBonus?.isLoyaltyDependentLimitEnabled;
  const isLoyaltyDependentPlaythroughEnabled = !!depositBonus?.isLoyaltyDependentPlaythroughEnabled;

  const loyaltyDependentPercentageData = isLoyaltyDependentPercentageEnabled
    ? depositBonus?.loyaltyDependentPercentage
    : depositBonus?.loyaltyDependentPercentageSingleValue;

  const loyaltyDependentLimitData = isLoyaltyDependentLimitEnabled
    ? depositBonus?.loyaltyDependentLimit
    : depositBonus?.loyaltyDependentLimitSingleValue;

  const loyaltyDependentPlaythroughData = isLoyaltyDependentPlaythroughEnabled
    ? depositBonus?.loyaltyDependentPlaythrough
    : depositBonus?.loyaltyDependentPlaythroughSingleValue;

  return {
    ...depositBonus,
    loyaltyDependentPercentage: getBonusLoyaltyDependentValues(
      isLoyaltyDependentPercentageEnabled,
      loyaltyDependentPercentageData
    ),
    loyaltyDependentLimit: getBonusLoyaltyDependentValues(isLoyaltyDependentLimitEnabled, loyaltyDependentLimitData),
    loyaltyDependentPlaythrough: getBonusLoyaltyDependentValues(
      isLoyaltyDependentPlaythroughEnabled,
      loyaltyDependentPlaythroughData
    ),
    expiryDuration: calculatePromotionDuration(depositBonus?.expiryDuration, depositBonus?.expiryDurationSelectOption),
    expiryDurationDeposit: calculatePromotionDuration(
      depositBonus?.expiryDurationDeposit,
      depositBonus?.expiryDurationDepositSelectOption
    ),
    absoluteExpiryDate: depositBonus?.absoluteExpiryDate ? getUnixTime(depositBonus.absoluteExpiryDate) : null,
  };
};

const generateBoostPayload = (boost: ExtendedBoostSchema) => {
  return {
    ...boost,
    factor: boost?.factor ? boost?.factor / 100.0 : 100,
    loyaltyDependentMaxPayout: boost?.isMaxPayoutEnabled ? boost?.loyaltyDependentMaxPayout : null,
    duration: calculatePromotionDuration(boost?.duration, boost?.durationSelectOption),
    expiryDuration: boost?.expiryDuration
      ? calculatePromotionDuration(Number(boost?.expiryDuration), boost?.expiryDurationDepositSelectOption)
      : null,
    startDate: boost?.startDate ? getUnixTime(boost.startDate) : null,
  };
};

const generateVoucherPayload = (voucher: UpdateVoucherActionSchema) => {
  return {
    ...voucher,
    durationTicks: voucher?.durationTicks ? calculateTicks(voucher.durationTicks, 'days') : null,
  };
};

const getIndividualActionsDefaultValues = (data?: IndividualActionSchema): any | null => {
  return {
    name: data?.name ?? '',
    voucher: {
      enabled: data?.voucher?.enabled || false,
      internalVoucher: data?.voucher?.internalVoucher || false,
      productGroupId: data?.voucher?.productGroupId || undefined,
      productId: data?.voucher?.productId || undefined,
      productValue: data?.voucher.productValue || 1,
      durationTicks: data?.voucher?.durationTicks ? calculateTime(data?.voucher?.durationTicks, 'days') : undefined,
    },
    loyaltyPoints: {
      enabled: data?.loyaltyPoints?.enabled || false,
      spendablePoints: data?.loyaltyPoints?.spendablePoints || 0,
      statusPoints: data?.loyaltyPoints?.statusPoints || 0,
    },
    playthroughBonus: {
      enabled: data?.playthroughBonus?.enabled || false,
      name: data?.playthroughBonus?.name || '',
      isLoyaltyDependentAmountEnabled: !!data?.playthroughBonus?.loyaltyDependentAmount,
      loyaltyDependentSingleValue: 0,
      loyaltyDependentAmount: data?.playthroughBonus?.loyaltyDependentAmount || undefined,
      isLoyaltyDependentPlaythroughEnabled: !!data?.playthroughBonus?.loyaltyDependentAmount,
      loyaltyDependentPlaythroughSingleValue: 0,
      loyaltyDependentPlaythrough: data?.playthroughBonus?.loyaltyDependentPlaythrough || undefined,
      expiryDuration: data?.playthroughBonus?.expiryDuration || 0,
      expiryDurationSelectOption: DurationsEnum.SECONDS,
      contentFilterId: data?.playthroughBonus?.contentFilterId || undefined,
      images: data?.playthroughBonus?.images ? convertToMediaImageSchema(data?.playthroughBonus?.images) : null,
      imageIds: data?.playthroughBonus?.imageIds || null,
    },
    depositBonus: {
      enabled: data?.depositBonus?.enabled || false,
      name: data?.depositBonus?.name || '',
      isLoyaltyDependentPercentageEnabled: !!data?.depositBonus?.loyaltyDependentPercentage,
      loyaltyDependentPercentageSingleValue: 0,
      loyaltyDependentPercentage: data?.depositBonus?.loyaltyDependentPercentage || undefined,
      isLoyaltyDependentLimitEnabled: !!data?.depositBonus?.loyaltyDependentLimit,
      loyaltyDependentLimitSingleValue: 0,
      loyaltyDependentLimit: data?.depositBonus?.loyaltyDependentLimit || undefined,
      isLoyaltyDependentPlaythroughEnabled: !!data?.depositBonus?.loyaltyDependentPlaythrough,
      loyaltyDependentPlaythroughSingleValue: 0,
      loyaltyDependentPlaythrough: data?.depositBonus?.loyaltyDependentPlaythrough || undefined,
      contentFilterId: data?.depositBonus?.contentFilterId || undefined,
      images: data?.depositBonus?.images ? convertToMediaImageSchema(data?.depositBonus?.images) : null,
      imageIds: data?.depositBonus?.imageIds || null,
      expiryDuration: data?.depositBonus?.expiryDuration || 0,
      expiryDurationSelectOption: DurationsEnum.SECONDS,
      expiryDurationDeposit: data?.depositBonus?.expiryDurationDeposit || 0,
      expiryDurationDepositSelectOption: DurationsEnum.SECONDS,
      absoluteExpiryDate: data?.depositBonus?.absoluteExpiryDate
        ? new Date(data?.depositBonus?.absoluteExpiryDate * 1000)
        : null,
    },
    freeSpins: {
      enabled: data?.freeSpins?.enabled || false,
      freeSpinsCampaign: data?.freeSpins?.freeSpinsCampaign || null,
      expiresInDays: data?.freeSpins?.expiresInDays || 1,
      images: data?.freeSpins?.images ? convertToMediaImageSchema(data?.freeSpins?.images) : null,
      imageIds: data?.freeSpins?.imageIds || null,
    },
    freeCash: {
      enabled: data?.freeCash?.enabled || false,
      amount: data?.freeCash?.amount || 1,
      currency: Currency.EUR,
    },
    vault: {
      enabled: data?.vault?.enabled || false,
      amountOfTokens: data?.vault?.amountOfTokens || 1,
    },
    plainCampaignMail: {
      enabled: data?.plainCampaignMail?.enabled || false,
    },
    boost: {
      enabled: data?.boost?.enabled || false,
      name: data?.boost?.name || '',
      boostType: data?.boost?.boostType || EBoostTypeSchema.FixedTime,
      contentFilterId: data?.boost?.contentFilterId || undefined,
      images: data?.boost?.images ? convertToMediaImageSchema(data?.boost?.images) : null,
      imageIds: data?.boost?.imageIds || null,
      duration: data?.boost?.duration || 1,
      durationSelectOption: DurationsEnum.SECONDS,
      expiryDuration: data?.boost?.expiryDuration || null,
      expiryDurationDepositSelectOption: DurationsEnum.SECONDS,
      startDate: data?.boost?.startDate ? new Date(data?.boost?.startDate * 1000) : null,
      aggregationFunction: data?.boost?.aggregationFunction || EAggregationFunctionSchema.Max,
      factor: data?.boost?.factor ? data?.boost?.factor * 100.0 : 100,
      payoutType: data?.boost?.payoutType || EPayoutTypeSchema.Cash,
      isMaxPayoutEnabled: !!data?.boost?.loyaltyDependentMaxPayout,
      maxPayout: data?.boost?.maxPayout || undefined,
      loyaltyDependentMaxPayout: data?.boost?.loyaltyDependentMaxPayout || undefined,
      isEarlyMaxPayoutEnabled: !!data?.boost?.earlyMaxPayoutEnabled,
      earlyMaxPayoutEnabled: data?.boost?.earlyMaxPayoutEnabled || undefined,
    },
    boosterPack: {
      enabled: data?.boosterPack?.enabled || false,
      boosterPackId: data?.boosterPack?.boosterPackId || undefined,
    },
    trustpilotReviewEmail: {
      enabled: data?.trustpilotReviewEmail?.enabled || false,
    },
  };
};

const generateIndividualActionPayload = (data: ExtendedUpdateIndividualActionSchema): any => {
  return {
    name: data?.name,
    voucher: data?.voucher?.enabled ? generateVoucherPayload(data?.voucher) : null,
    loyaltyPoints: data?.loyaltyPoints?.enabled ? data?.loyaltyPoints : null,
    playthroughBonus: data?.playthroughBonus?.enabled ? generateBonusPlaythroughPayload(data.playthroughBonus) : null,
    depositBonus: data?.depositBonus?.enabled ? generateDepositBonusPayload(data.depositBonus) : null,
    freeSpins: data?.freeSpins?.enabled ? data?.freeSpins : null,
    freeCash: data?.freeCash?.enabled ? data?.freeCash : null,
    vault: data?.vault?.enabled ? data?.vault : null,
    plainCampaignMail: data?.plainCampaignMail?.enabled ? data?.plainCampaignMail : null,
    boost: data?.boost?.enabled ? generateBoostPayload(data.boost) : null,
    boosterPack: data?.boosterPack?.enabled ? data?.boosterPack : null,
    trustpilotReviewEmail: data?.trustpilotReviewEmail?.enabled ? data?.trustpilotReviewEmail : null,
  };
};

export {
  getIndividualActionsDefaultValues,
  generateIndividualActionPayload,
  calculatePromotionDuration,
  getDefaultIndividualActionNumberInputProps,
};
