import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  EVerificationStatusSchema,
  getGetUserVerificationsQueryKey,
  useGetAllCountries,
  useGetUserVerifications,
  usePostCommunicateVerificationNeeded,
  VerificationsSchema,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { Link, PermissionWrapper } from '@greenisland-common/components/atoms';

import BankAccounts from './DetailsComponents/BankAccounts/BankAccounts';
import Domicile from './DetailsComponents/Domicile';
import FinancialVerification from './DetailsComponents/financialVerification/FinancialVerification';
import Identity from './DetailsComponents/Identity/Identity';
import Wallets from './DetailsComponents/Wallets';
import { usePermission } from '../../../../../app/hooks';
import { EventStreamParamsType } from '../../../Lookup/Components/DetailsComponents/Constants/Eventstreams';

const VerificationDetails = () => {
  const { t } = useTranslation();
  const { userId = '' } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const qc = useQueryClient();

  const canReadUserVerifications = usePermission(OnlineCasinoPermissions.getUserVerifications);
  const canReadCountries = usePermission(OnlineCasinoPermissions.getAllCountries);
  const canAccessEventStream = usePermission(OnlineCasinoPermissions.getEventstreamStreamId);
  const canCommunicateVerificationNeeded = usePermission(OnlineCasinoPermissions.postCommunicateVerificationNeeded);

  const { data: countries, isLoading: isCountriesLoading } = useGetAllCountries({
    query: {
      enabled: canReadCountries,
    },
  });

  const {
    data: userVerifications,
    isLoading,
    isError,
  } = useGetUserVerifications(userId, { query: { enabled: !!userId && canReadUserVerifications && canReadCountries } });

  const communicateVerificationNeededMutation = usePostCommunicateVerificationNeeded({
    mutation: {
      onSuccess: async () => {
        await qc.invalidateQueries(getGetUserVerificationsQueryKey(userId));
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  const communicateVerificationNeeded = () => communicateVerificationNeededMutation.mutate({ userId });

  const checkStatus = (status: string) =>
    status === EVerificationStatusSchema.NeedsVerification || status === EVerificationStatusSchema.Declined;

  const checkIfVerificationIsNeeded = (userVerifications: VerificationsSchema) => {
    for (const [key, value] of Object.entries(userVerifications)) {
      switch (key) {
        case 'identity':
        case 'domicile':
          if (value && checkStatus(value.verificationStatus)) return true;
          break;
        case 'wallets':
        case 'bankAccounts':
          for (const item of value) {
            if (item && checkStatus(item.verificationStatus)) return true;
          }
          break;
        default:
          break;
      }
    }
    return false;
  };

  return (
    <PermissionWrapper
      errorMessage={t('users.verificationDetails.errors.fetchError')}
      isError={isError}
      isLoading={isLoading || isCountriesLoading}
      isCircularLoadingStyle={true}
      permission={OnlineCasinoPermissions.getUserVerifications}
    >
      <>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">{`${userVerifications?.userName} - ${t('communicationLanguage', {
            language: userVerifications?.language,
          })}`}</Typography>
          <Box>
            {canAccessEventStream && userVerifications?.streamId && (
              <Button
                sx={{ mr: 2 }}
                size="small"
                variant="outlined"
                color="secondary"
                component={Link}
                to={`../../lookup/eventstreams?${EventStreamParamsType.STREAM_ID}=${userVerifications.streamId}`}
              >
                {t('history')}
              </Button>
            )}
            {canCommunicateVerificationNeeded &&
              userVerifications &&
              checkIfVerificationIsNeeded(userVerifications) && (
                <Button variant="outlined" color="primary" size="small" onClick={communicateVerificationNeeded}>
                  {communicateVerificationNeededMutation.isLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    t('communicateVerificationNeeds')
                  )}
                </Button>
              )}
          </Box>
        </Box>
        {userVerifications?.identity && countries && (
          <Identity identity={userVerifications.identity} countries={countries} />
        )}
        {userVerifications?.domicile && countries && (
          <Domicile domicile={userVerifications.domicile} countries={countries} />
        )}
        {userVerifications?.bankAccounts && <BankAccounts bankAccounts={userVerifications.bankAccounts} />}
        {userVerifications?.wallets && <Wallets wallets={userVerifications.wallets} />}
        {userVerifications?.financialVerification && (
          <FinancialVerification financialVerification={userVerifications.financialVerification} />
        )}
      </>
    </PermissionWrapper>
  );
};

export default memo(VerificationDetails);
