import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetVerifications } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, CircularProgress, Link, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import { NoData, StyledDataGrid } from '@greenisland-common/components/atoms';

import ResetScanAttemptsFormDialog from './Components/ResetScanAttemptsFormDialog';
import { transformUnixDateV2 } from '../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../app/hooks';
import useFeatureFlags from '../../../../common/hooks/useFeatureFlags';
import { LOOKUP_PERMISSIONS } from '../../Lookup/LookupPermissions';

const VerificationsMain = () => {
  const { t } = useTranslation();

  const canReadTasksVerifications = usePermission(OnlineCasinoPermissions.getVerifications);
  const canReadUserVerifications = usePermission(OnlineCasinoPermissions.getUserVerifications);
  const canLookupUser = usePermission(LOOKUP_PERMISSIONS, { strict: false });

  const isCheckinEnabled = useFeatureFlags('isCheckinEnabled');
  const [isResetScanAttemptsDialogOpen, setIsResetScanAttemptsDialogOpen] = useState(false);

  const { data: verifications, isLoading: isVerificationsLoading } = useGetVerifications({
    query: { enabled: canReadTasksVerifications },
  });

  const columns: GridColDef[] = useMemo(() => {
    if (!verifications?.length) return [];

    return Object.keys(verifications[0])
      .map(key => {
        switch (key) {
          case 'modifiedOn':
            return {
              ...transformUnixDateV2(key, t),
              flex: 1,
            };
          case 'username':
            return {
              field: key,
              headerName: t(key),
              flex: 1,
              renderCell: params =>
                canLookupUser ? (
                  <Link href={`../users/${params.row.userId}/details`}>{params.value}</Link>
                ) : (
                  params.value
                ),
            } as GridColDef;
          default:
            return { field: key, headerName: t(key), flex: 1 };
        }
      })
      .concat({
        field: 'details',
        headerName: t('details'),
        flex: 0.5,
        sortable: false,
        filterable: false,
        renderCell: params =>
          canReadUserVerifications ? (
            <Link href={`../users/${params.row.userId}/verifications`}>{t('details')}</Link>
          ) : null,
      });
  }, [t, verifications, canLookupUser, canReadUserVerifications]);

  if (isVerificationsLoading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  if (!canReadTasksVerifications || !verifications?.length) return <NoData />;

  return (
    <Stack spacing={4}>
      {isCheckinEnabled && (
        <Box display="flex" justifyContent="flex-end">
          <Button variant="contained" size="small" onClick={() => setIsResetScanAttemptsDialogOpen(true)}>
            {t('tasks.verifications.checkin.titles.resetScanAttempts')}
          </Button>
        </Box>
      )}
      <StyledDataGrid
        columns={columns}
        rows={verifications}
        loading={isVerificationsLoading}
        getRowId={row => row.userId}
        autoHeight
        disableSelectionOnClick
        pagination
        initialState={{
          columns: {
            columnVisibilityModel: {
              userId: false,
            },
          },
        }}
      />
      <ResetScanAttemptsFormDialog
        onClose={() => setIsResetScanAttemptsDialogOpen(false)}
        open={isResetScanAttemptsDialogOpen}
      />
    </Stack>
  );
};

export default VerificationsMain;
