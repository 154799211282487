import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { faAdd, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CampaignTemplateSchema } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid-pro';

import { DataGridContainer } from '@greenisland-common/components/atoms';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import { usePermission } from '../../../../../app/hooks';

type Row = CampaignTemplateSchema;

interface Props {
  campaignId: string;
  templates?: Row[];
}

const Templates = ({ campaignId, templates }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const canAddTemplate = usePermission([
    OnlineCasinoPermissions.addCampaignTemplate,
    OnlineCasinoPermissions.getTemplateTypes,
  ]);
  const canEditTemplate = usePermission(OnlineCasinoPermissions.updateCampaignTemplate);

  const columns = useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'type',
        headerName: capitalize(t('type')),
        sortable: false,
        minWidth: 120,
        flex: 0.1,
        renderCell: ({ value }) => {
          return (
            <Tooltip title={capitalize(t(value))}>
              <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} variant="body2" fontSize="sm">
                {value || '-'}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        field: 'actions',
        headerName: '',
        flex: 0.01,
        minWidth: 50,
        sortable: false,
        renderCell: params =>
          canEditTemplate && (
            <Tooltip title={t('editTemplate')}>
              <IconButton size="small" onClick={() => navigate(`${campaignId}/template/${params.row.type}`)}>
                <FontAwesomeIcon size="xs" icon={faEye} />
              </IconButton>
            </Tooltip>
          ),
      },
    ],
    [t, canEditTemplate, navigate, campaignId]
  );

  const rows = useMemo(
    () =>
      templates?.map<Row>(template => ({
        ...template,
        id: template?.campaignId,
      })) ?? [],
    [templates]
  );

  return (
    <Stack
      px={2}
      sx={{
        [theme.breakpoints.up('xs')]: {
          width: '100%',
        },
        [theme.breakpoints.up('md')]: {
          width: '50%',
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" fontWeight={500} mb={2} fontSize="x-large">
          {capitalize(t('templates'))}
        </Typography>
        {canAddTemplate && (
          <Button
            variant="contained"
            size="small"
            startIcon={<FontAwesomeIcon icon={faAdd} />}
            onClick={() => navigate(`${campaignId}/template/add`)}
          >
            {capitalize(t('addTemplate'))}
          </Button>
        )}
      </Box>
      <DataGridContainer>
        <LimitedDataGrid density="compact" rowHeight={60} columns={columns} rows={rows} autoHeight />
      </DataGridContainer>
    </Stack>
  );
};

export default Templates;
