import { useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IndividualActionSchema, UpdateIndividualActionSchema, useGetBoosterPacks } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Autocomplete, capitalize, TextField } from '@mui/material';

import { PermissionWrapper } from '@greenisland-common/components/atoms';

import { usePermission } from '../../../../../../app/hooks';
import IndividualActionFormWrapper from '../IndividualActionFormWrapper';

const PAGE_SIZE = 100;

interface Props {
  individualAction?: IndividualActionSchema;
}

const IndividualActionBoosterPackForm = ({ individualAction }: Props) => {
  const { t } = useTranslation();
  const canReadBoosterPacks = usePermission(OnlineCasinoPermissions.getBoosterPacks);

  const { watch, setValue, control } = useFormContext<UpdateIndividualActionSchema>();

  const isBoosterPackEnabled = !!watch('boosterPack.enabled');
  const watchBoosterPackId = watch('boosterPack.boosterPackId');

  const {
    data: boosterPacks,
    isLoading,
    isError,
  } = useGetBoosterPacks(
    {
      pageSize: PAGE_SIZE,
    },
    {
      query: { enabled: canReadBoosterPacks && isBoosterPackEnabled },
    }
  );

  const boosterPackOptions = useMemo(() => {
    return (
      boosterPacks?.items
        ?.sort((a, b) => a?.name?.localeCompare(b?.name))
        ?.map(entry => ({ label: `${entry.id} - ${entry.name}`, value: Number(entry.id) })) ?? []
    );
  }, [boosterPacks?.items]);

  useEffect(() => {
    if (isBoosterPackEnabled && !watchBoosterPackId) {
      setValue('boosterPack.boosterPackId', undefined);
    }
  }, [isBoosterPackEnabled, setValue, watchBoosterPackId]);

  return (
    <IndividualActionFormWrapper
      enabled={isBoosterPackEnabled}
      name="boosterPack.enabled"
      listItemText={capitalize(t('marketing.boosterPacks.titles.boosterPack'))}
    >
      <PermissionWrapper
        errorMessage={t('marketing.boosterPacks.permissions.fetchError')}
        isError={isError}
        isLoading={isLoading}
        isCircularLoadingStyle={true}
        permission={OnlineCasinoPermissions.getBoosterPacks}
      >
        <Controller
          control={control}
          name="boosterPack.boosterPackId"
          defaultValue={individualAction?.boosterPack?.boosterPackId}
          rules={{
            setValueAs: boosterPackId => boosterPackId && boosterPackId,
          }}
          render={({ value, onChange }) => (
            <Autocomplete
              fullWidth={true}
              loading={isLoading}
              value={boosterPackOptions?.find(option => option?.value === value) ?? null}
              onChange={(_event, card) => onChange(card?.value)}
              options={boosterPackOptions}
              renderInput={params => (
                <TextField
                  required={isBoosterPackEnabled}
                  {...params}
                  size="small"
                  label={t('marketing.boosterPacks.titles.selectBoosterPack')}
                />
              )}
            />
          )}
        />
      </PermissionWrapper>
    </IndividualActionFormWrapper>
  );
};

export default IndividualActionBoosterPackForm;
