import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IndividualActionSchema, UpdateIndividualActionSchema } from '@greenisland-api';
import { capitalize } from '@mui/material';

import Input from '@greenisland-common/components/molecules/Input';

import { getDefaultIndividualActionNumberInputProps } from '../helpers';
import IndividualActionFormWrapper from '../IndividualActionFormWrapper';

interface Props {
  individualAction?: IndividualActionSchema;
}

const IndividualActionVaultTokenForm = ({ individualAction }: Props) => {
  const { t } = useTranslation();
  const { register, watch, errors } = useFormContext<UpdateIndividualActionSchema>();

  const isVaultEnabled = !!watch('vault.enabled');

  return (
    <IndividualActionFormWrapper enabled={isVaultEnabled} name="vault.enabled" listItemText={t('vaultTokens')}>
      <Input
        size="small"
        name="vault.amountOfTokens"
        label={capitalize(t('amountOfTokens'))}
        inputRef={register({
          required: isVaultEnabled,
          setValueAs: value => (value ? Number(value) : undefined),
        })}
        InputProps={getDefaultIndividualActionNumberInputProps(1)}
        required={isVaultEnabled}
        disabled={!isVaultEnabled}
        error={Boolean(errors?.vault?.amountOfTokens)}
        helperText={errors?.vault?.amountOfTokens?.message}
        fullWidth={true}
        defaultValue={individualAction?.vault?.amountOfTokens}
      />
    </IndividualActionFormWrapper>
  );
};

export default IndividualActionVaultTokenForm;
