import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ErrorSchema,
  getGetCampaignQueryKey,
  getGetIndividualActionQueryKey,
  useAddIndividualAction,
  useGetIndividualAction,
  useUpdateIndividualAction,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  capitalize,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { useSnackbar } from 'notistack';

import { PermissionWrapper } from '@greenisland-common/components/atoms';
import Input from '@greenisland-common/components/molecules/Input';

import IndividualActionBoosterPackForm from './individualActionSections/IndividualActionBoosterPackForm';
import IndividualActionBoostForm from './individualActionSections/IndividualActionBoostForm';
import IndividualActionDepositBonusForm from './individualActionSections/IndividualActionDepositBonusForm';
import IndividualActionFreeCashForm from './individualActionSections/IndividualActionFreeCashForm';
import IndividualActionFreeSpinsForm from './individualActionSections/IndividualActionFreeSpinsForm';
import IndividualActionLoyaltyPointsForm from './individualActionSections/IndividualActionLoyaltyPointsForm';
import IndividualActionPlainMailForm from './individualActionSections/IndividualActionPlainMailForm';
import IndividualActionPlaythroughBonusForm from './individualActionSections/IndividualActionPlaythroughBonusForm';
import IndividualActionTrustpilotForm from './individualActionSections/IndividualActionTrustpilotForm';
import IndividualActionVaultTokenForm from './individualActionSections/IndividualActionVaultTokensForm';
import IndividualActionVoucherForm from './individualActionSections/IndividualActionVoucherForm';
import { usePermission } from '../../../../../app/hooks';
import useFeatureFlags from '../../../../../common/hooks/useFeatureFlags';
import {
  ExtendedUpdateIndividualActionSchema,
  generateIndividualActionPayload,
  getIndividualActionsDefaultValues,
} from './helpers';

interface Props {
  campaignId: string;
  actionId?: string | undefined;
  isOpen: boolean;
  onClose: () => void;
}

const IndividualActionFormDialog = ({ campaignId = '', actionId = '', isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const isVouchersEnabled = useFeatureFlags('isVouchersEnabled');
  const isLoyaltyEnabled = useFeatureFlags('isLoyaltyEnabled');
  const isBonusesEnabled = useFeatureFlags('isBonusesEnabled');
  const isVaultEnabled = useFeatureFlags('isVaultEnabled');
  const isBoostsEnabled = useFeatureFlags('isBoostEnabled');
  const isTrustpilotEnabled = useFeatureFlags('isTrustpilotEnabled');
  const isBoosterPacksEnabled = useFeatureFlags('isBoosterPacksEnabled');

  const canReadIndividualAction = usePermission(OnlineCasinoPermissions.getIndividualAction);

  const methods = useForm<ExtendedUpdateIndividualActionSchema>({
    shouldFocusError: true,
    shouldUnregister: false,
    mode: 'onSubmit',
    defaultValues: getIndividualActionsDefaultValues(),
  });

  const { handleSubmit, reset, errors, control } = methods;

  const closeAndResetFormHandler = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  const onSuccessHandler = useCallback(() => {
    enqueueSnackbar(t('success'), { variant: 'success' });
    queryClient.invalidateQueries(getGetCampaignQueryKey(campaignId));
    queryClient.invalidateQueries(getGetIndividualActionQueryKey(campaignId, actionId));
    closeAndResetFormHandler();
  }, [actionId, campaignId, closeAndResetFormHandler, enqueueSnackbar, queryClient, t]);

  const onErrorHandler = useCallback(
    (error: ErrorSchema) => {
      enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
    },
    [enqueueSnackbar, t]
  );

  const {
    data: individualAction,
    isLoading: isIndividualActionLoading,
    isError: isIndividualActionError,
    isRefetching: isIndividualActionRefetching,
  } = useGetIndividualAction(campaignId, actionId, {
    query: {
      enabled: canReadIndividualAction && !!campaignId && !!actionId,
      onSuccess: data => {
        const defaultValues = getIndividualActionsDefaultValues(data);

        if (defaultValues) {
          reset(defaultValues);
        }
      },
    },
  });

  const { mutate: addIndividualAction, isLoading: isAddActionLoading } = useAddIndividualAction({
    mutation: {
      onSuccess: onSuccessHandler,
      onError: onErrorHandler,
    },
  });
  const { mutate: updateIndividualAction, isLoading: isUpdateActionLoading } = useUpdateIndividualAction({
    mutation: {
      onSuccess: onSuccessHandler,
      onError: onErrorHandler,
    },
  });

  const onSubmit = (data: ExtendedUpdateIndividualActionSchema) => {
    const payload = generateIndividualActionPayload(data);

    if (actionId) {
      return updateIndividualAction({
        campaignId,
        actionId,
        data: payload,
      });
    }
    return addIndividualAction({
      campaignId,
      data: payload,
    });
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen}>
      <DialogTitle>{capitalize(t('marketing.campaigns.titles.actionDetails'))}</DialogTitle>
      <IconButton
        edge="start"
        color="inherit"
        onClick={closeAndResetFormHandler}
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 16,
          top: 8,
        }}
      >
        <FontAwesomeIcon icon={faClose} />
      </IconButton>
      <DialogContent>
        <PermissionWrapper
          errorMessage={'marketing.campaigns.errors.individualActionFetchError'}
          isError={isIndividualActionError}
          isLoading={isIndividualActionLoading || isIndividualActionRefetching}
          isCircularLoadingStyle={true}
          permission={OnlineCasinoPermissions.getIndividualAction}
        >
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={2}>
                {individualAction?.actionId && (
                  <Typography variant="body2" fontSize="medium" fontWeight={500} pb={1}>
                    {`${capitalize(t('id'))}: ${individualAction.actionId}`}
                  </Typography>
                )}
                <Input
                  size="small"
                  label={capitalize(t('name'))}
                  control={control}
                  type="text"
                  name="name"
                  defaultValue={individualAction?.name}
                  required
                  rules={{ required: t('fieldIsRequired') }}
                  sx={{ mb: 2 }}
                  error={Boolean(errors?.name)}
                  fullWidth={true}
                />
                <Box>
                  {isVouchersEnabled && <IndividualActionVoucherForm individualAction={individualAction} />}
                  {isLoyaltyEnabled && <IndividualActionLoyaltyPointsForm individualAction={individualAction} />}
                  {isBonusesEnabled && (
                    <>
                      <IndividualActionPlaythroughBonusForm individualAction={individualAction} />
                      <IndividualActionDepositBonusForm individualAction={individualAction} />
                    </>
                  )}
                  <IndividualActionFreeSpinsForm individualAction={individualAction} />
                  <IndividualActionFreeCashForm individualAction={individualAction} />
                  {isVaultEnabled && <IndividualActionVaultTokenForm individualAction={individualAction} />}
                  <IndividualActionPlainMailForm />
                  {isBoostsEnabled && <IndividualActionBoostForm individualAction={individualAction} />}
                  {isBoosterPacksEnabled && <IndividualActionBoosterPackForm individualAction={individualAction} />}
                  {isTrustpilotEnabled && <IndividualActionTrustpilotForm />}
                </Box>
                <DialogActions>
                  <Button variant="text" onClick={closeAndResetFormHandler} color="secondary">
                    {t('cancel')}
                  </Button>
                  <LoadingButton
                    color="primary"
                    type="submit"
                    variant="contained"
                    loading={isAddActionLoading || isUpdateActionLoading}
                    disabled={isAddActionLoading || isUpdateActionLoading}
                  >
                    {t('save')}
                  </LoadingButton>
                </DialogActions>
              </Stack>
            </form>
          </FormProvider>
        </PermissionWrapper>
      </DialogContent>
    </Dialog>
  );
};

export default IndividualActionFormDialog;
