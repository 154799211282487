import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { faCheck, faUserMinus, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AgentTaskSchema,
  EAgentTaskCategorySchema,
  EAgentTaskStatusSchema,
  EAgentTaskTypeSchema,
  getGetAgentTasksQueryKey,
  useAssignAgentTask,
  useGetAgentTasks,
  useUnassignAgentTask,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useGetCurrentAgent } from '@greenisland-store/authorization';
import { Box, Button, capitalize, Tooltip } from '@mui/material';
import { DataGridProProps, GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';

import { DataGridContainer, DataGridPlainHeader, PermissionWrapper } from '@greenisland-common/components/atoms';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import { useFeatureFlags } from '@greenisland-common/hooks';

import SuspiciousDepositAction from './Actions/Fraud/SuspiciousDepositAction';
import ResolveKYCRiskAction from './Actions/KYC/ResolveKYCRiskAction';
import PlayingBreakCancelRequestAction from './Actions/Playingbreak/PlayingBreakRequestAction';
import { getDateTimeFromUnix } from '../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../app/hooks';
import AgentTaskDetail from './AgentTaskDetail';
import ResolveAgentTaskDialog from './ResolveAgentTaskDialog';

const PAGE_SIZE = 25;

interface Props {
  types?: EAgentTaskTypeSchema[];
  categories?: EAgentTaskCategorySchema[];
  defaultPageSize?: number;
}

const AgentTasksTableData = ({ types, categories, defaultPageSize = PAGE_SIZE }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [page, setPage] = useState(0);

  const [open, setOpen] = useState(false);
  const [resolvableAgentTaskId, setResolvableAgentTaskId] = useState<string>('');

  const isKycEnabled = useFeatureFlags('isKycEnabled');

  const canMarkAsSolved = usePermission(OnlineCasinoPermissions.resolveOpenAgentTask);
  const canAssignAgentTask = usePermission(OnlineCasinoPermissions.assignAgentTask);
  const canUnassignAgentTask = usePermission(OnlineCasinoPermissions.unassignAgentTask);
  const canReadAgentTasks = usePermission(OnlineCasinoPermissions.getAgentTasks);

  const { data, isLoading, isError } = useGetAgentTasks(
    {
      page,
      pageSize,
      status: EAgentTaskStatusSchema.Open,
      ...(types && types.length > 0 && { types }),
      ...(categories && categories.length > 0 && { categories }),
    },
    { query: { enabled: canReadAgentTasks } }
  );

  const { data: currentAgent } = useGetCurrentAgent();

  const {
    mutate: assignAgentTask,
    isLoading: isAssignLoading,
    variables: assignVariables,
  } = useAssignAgentTask({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getGetAgentTasksQueryKey());
        enqueueSnackbar(capitalize(t('success')), { variant: 'success' });
      },
      onError: error => {
        enqueueSnackbar(error, { variant: 'error' });
      },
    },
  });

  const {
    mutate: unassignAgentTask,
    isLoading: isUnassignLoading,
    variables: unassignVariables,
  } = useUnassignAgentTask({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getGetAgentTasksQueryKey());
        enqueueSnackbar(capitalize(t('success')), { variant: 'success' });
      },
      onError: error => {
        enqueueSnackbar(error, { variant: 'error' });
      },
    },
  });

  const getDetailAction = useCallback(
    (row: AgentTaskSchema) => {
      if (row.type === EAgentTaskTypeSchema.PlayingBreakCancellationRequested)
        return <PlayingBreakCancelRequestAction userId={row.userId ?? undefined} />;
      if (row.type === EAgentTaskTypeSchema.KYCRiskDetected && isKycEnabled)
        return <ResolveKYCRiskAction agentTaskId={row.id} />;
      if (row.type === EAgentTaskTypeSchema.SuspiciousDeposit && row.userId && row.metaData)
        return <SuspiciousDepositAction userId={row.userId.toString()} depositId={row.metaData.onlineDepositId} />;
      return <SuspiciousDepositAction userId={row.userId?.toString() ?? ''} depositId={row.metaData?.onlineDepositId} />;
    },
    [isKycEnabled]
  );

  const getDetailPanelContent: DataGridProProps['getDetailPanelContent'] = useCallback(
    ({ row }: { row: AgentTaskSchema }) => {
      const tasksWithCustomMetadata = ['KSAAddictionPreventionPlayingBreakCanBeCancelled'];

      return (
        <AgentTaskDetail data={row} showCustomMetadata={tasksWithCustomMetadata.includes(row.type)}>
          {getDetailAction(row)}
        </AgentTaskDetail>
      );
    },
    [getDetailAction]
  );

  const getDetailPanelHeight = useCallback((): 'auto' => 'auto', []);

  const headers: GridColumns = [
    {
      headerName: capitalize(t('timestamp')),
      field: 'created',
      renderCell: ({ value }) => <DataGridPlainHeader value={getDateTimeFromUnix(value)} />,
      flex: 0.1,
      minWidth: 150,
    },
    {
      headerName: capitalize(t('type')),
      field: 'type',
      renderCell: ({ value }) => (
        <Tooltip title={value.toString()} placement="top">
          <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <DataGridPlainHeader value={value} />
          </Box>
        </Tooltip>
      ),
      flex: 0.1,
      minWidth: 150,
    },
    {
      headerName: capitalize(t('description')),
      field: 'description',
      renderCell: ({ value }) => (
        <Tooltip title={value.toString()} placement="top">
          <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <DataGridPlainHeader value={value} />
          </Box>
        </Tooltip>
      ),
      flex: 0.6,
      minWidth: 300,
    },
    {
      headerName: capitalize(t('actions')),
      field: 'actions',
      renderCell: ({ value }) => value,
      flex: 0.1,
      maxWidth: 125,
    },
    {
      headerName: capitalize(t('assignee')),
      field: 'assignee',
      renderCell: ({ value }) => (
        <Tooltip title={value} placement="top">
          <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <DataGridPlainHeader value={value} />
          </Box>
        </Tooltip>
      ),
      flex: 0.1,
      minWidth: 120,
    },
    {
      headerName: undefined,
      field: 'assign',
      renderHeader: () => <DataGridPlainHeader value={''} />,
      renderCell: ({ value }) => value,
      maxWidth: 52,
      sortable: false,
    },
  ];

  const rows: GridRowsProp =
    data?.agentTasks?.map(task => ({
      ...task,
      actions: canMarkAsSolved ? (
        <div style={{ display: 'inline-block' }}>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              setResolvableAgentTaskId(task.id);
              setOpen(true);
            }}
          >
            {t('resolve')}
            <Box ml={1}>
              <FontAwesomeIcon icon={faCheck} />
            </Box>
          </Button>
        </div>
      ) : null,
      assignee: task.assigneeUsername || capitalize(t('unassigned')),
      assign:
        canAssignAgentTask && !task.assigneeUsername ? (
          <Tooltip title={t('tasks.agenttask.assign.me')} placement="top">
            <div style={{ display: 'inline-block', cursor: 'pointer' }}>
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  assignAgentTask({ agentTaskId: task.id });
                }}
                style={{ minWidth: 'auto' }}
                disabled={assignVariables?.agentTaskId == task.id && isAssignLoading}
              >
                <Box>
                  <FontAwesomeIcon icon={faUserPlus} />
                </Box>
              </Button>
            </div>
          </Tooltip>
        ) : canUnassignAgentTask && task.assigneeUsername && currentAgent?.email == task.assigneeUsername ? (
          <Tooltip title={t('tasks.agenttask.unassign')} placement="top">
            <div style={{ display: 'inline-block', cursor: 'pointer' }}>
              <Button
                variant="outlined"
                size="small"
                color="error"
                onClick={() => {
                  unassignAgentTask({ agentTaskId: task.id });
                }}
                style={{ minWidth: 'auto' }}
                disabled={unassignVariables?.agentTaskId == task.id && isUnassignLoading}
              >
                <Box>
                  <FontAwesomeIcon icon={faUserMinus} />
                </Box>
              </Button>
            </div>
          </Tooltip>
        ) : null,
    })) ?? [];

  return (
    <Box mt={1}>
      <PermissionWrapper
        errorMessage={t('tasks.agentTasks.permissions.fetchError')}
        isError={isError}
        isLoading={false}
        permission={OnlineCasinoPermissions.getAgentTasks}
      >
        <>
          <DataGridContainer>
            <LimitedDataGrid
              loading={isLoading}
              autoHeight
              columns={headers}
              rows={rows}
              pagination
              pageSize={pageSize}
              rowsPerPageOptions={[10, 25, 50, 100]}
              paginationMode="server"
              page={data?.pagingDetails?.currentPage}
              rowCount={data?.pagingDetails?.totalItems}
              onPageChange={newPage => {
                if (!isLoading) {
                  setPage(newPage);
                }
              }}
              onPageSizeChange={setPageSize}
              getDetailPanelHeight={getDetailPanelHeight}
              getDetailPanelContent={getDetailPanelContent}
              componentsProps={{ pagination: { rowsPerPageOptions: [10, 25, 50, 100, 200] } }}
            />
          </DataGridContainer>
          <ResolveAgentTaskDialog open={open} setOpen={setOpen} agentTaskId={resolvableAgentTaskId} />
        </>
      </PermissionWrapper>
    </Box>
  );
};

export default AgentTasksTableData;
