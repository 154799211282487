import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EBoostTypeSchema, UpdateIndividualActionSchema } from '@greenisland-api';
import { capitalize, Typography } from '@mui/material';

import DateTimeInput from '@greenisland-common/components/molecules/DateTimeInput/DateTimeInput';
import SelectInputDurationField from '@greenisland-common/components/organisms/SelectInputDurationField';

import { getDefaultIndividualActionNumberInputProps, useDurationValues } from '../helpers';

const IndividualActionBoostFormTimeSettings = () => {
  const { t } = useTranslation();
  const durationOptions = useDurationValues();
  const { register, watch, errors, setValue } = useFormContext<UpdateIndividualActionSchema>();

  const isBoostEnabled = !!watch('boost.enabled');
  const watchBoostType = (watch('boost.boostType') as EBoostTypeSchema) || EBoostTypeSchema.FixedTime;

  const [boostDurationSelectOption, setBoostDurationSelectOption] = useState(durationOptions.SECONDS);
  const [boostExpiraryDurationSelectOption, setBoostExpiraryDurationSelectOption] = useState(durationOptions.SECONDS);

  useEffect(() => {
    if (isBoostEnabled) {
      if (watchBoostType !== EBoostTypeSchema.FixedTime) {
        setValue('boost.startDate', null);
      }
      if (watchBoostType !== EBoostTypeSchema.SelfActivated) {
        setValue('boost.expiryDuration', null);
      }
    }
  }, [isBoostEnabled, setValue, watchBoostType]);

  useEffect(() => {
    if (isBoostEnabled) {
      if (boostDurationSelectOption) {
        setValue('boost.durationSelectOption', boostDurationSelectOption.value);
      }
      if (boostExpiraryDurationSelectOption) {
        setValue('boost.expiryDurationDepositSelectOption', boostExpiraryDurationSelectOption.value);
      }
    }
  }, [boostDurationSelectOption, boostExpiraryDurationSelectOption, isBoostEnabled, register, setValue]);

  if (!watchBoostType) {
    return null;
  }

  return (
    <>
      <Typography fontWeight={600}>{capitalize(t('boost.subtitle.timeSettings'))}</Typography>
      {watchBoostType === EBoostTypeSchema.FixedTime && (
        <DateTimeInput
          name="boost.startDate"
          label={capitalize(t('boost.startDate'))}
          disabled={!isBoostEnabled}
          required={isBoostEnabled}
        />
      )}
      <SelectInputDurationField
        size="small"
        name="boost.duration"
        label={capitalize(t('boost.duration'))}
        chosenValue={boostDurationSelectOption}
        options={Object.values(durationOptions)}
        onChange={setBoostDurationSelectOption}
        required={isBoostEnabled}
        disabled={!isBoostEnabled}
        InputProps={getDefaultIndividualActionNumberInputProps(1)}
        inputRef={register({
          required: isBoostEnabled,
          setValueAs: value => (value ? Number(value) : 1),
        })}
        error={Boolean(errors?.boost?.duration)}
        sx={{
          '.MuiInputBase-root': {
            paddingRight: '2px',
          },
        }}
      />
      {watchBoostType === EBoostTypeSchema.SelfActivated && (
        <SelectInputDurationField
          size="small"
          name="boost.expiryDuration"
          label={capitalize(t('boost.expirationDuration'))}
          chosenValue={boostExpiraryDurationSelectOption}
          options={Object.values(durationOptions)}
          onChange={setBoostExpiraryDurationSelectOption}
          required={isBoostEnabled}
          disabled={!isBoostEnabled}
          InputProps={getDefaultIndividualActionNumberInputProps(1)}
          inputRef={register({
            required: isBoostEnabled,
            setValueAs: value => (value ? Number(value) : null),
          })}
          error={Boolean(errors?.boost?.expiryDuration)}
          sx={{
            '.MuiInputBase-root': {
              paddingRight: '2px',
            },
          }}
        />
      )}
    </>
  );
};

export default IndividualActionBoostFormTimeSettings;
