import { EConcludeInvestigationBadRequestTypeSchema, EPlayingBreakBadRequestTypeSchema } from '@greenisland-api';

type PlayingBreakErrorReasons = {
  [key in EPlayingBreakBadRequestTypeSchema]: string;
};

type PlayingBreakInvestigationErrorReasons = {
  [key in EConcludeInvestigationBadRequestTypeSchema]: string;
};

export const PLAYING_BREAK_ERROR_REASONS: PlayingBreakErrorReasons = {
  [EPlayingBreakBadRequestTypeSchema.noActivePlayingBreak]: 'settings.playingBreak.errors.noActivePlayingBreak',
  [EPlayingBreakBadRequestTypeSchema.stricterPlayingBreakAlreadyPresent]:
    'settings.playingBreak.errors.stricterPlayingBreakAlreadyPresent',
  [EPlayingBreakBadRequestTypeSchema.cooldownLowerThanMinimumPlayingBreak]:
    'settings.playingBreak.errors.cooldownLowerThanMinimumPlayingBreak',
  [EPlayingBreakBadRequestTypeSchema.cancellationAlreadyRequested]:
    'settings.playingBreak.errors.cancellationAlreadyRequested',
  [EPlayingBreakBadRequestTypeSchema.endDateInPast]: 'settings.playingBreak.errors.endDateInPast',
  [EPlayingBreakBadRequestTypeSchema.alreadyShorterThanCancellationWaitPeriod]:
    'settings.playingBreak.errors.alreadyShorterThanCancellationWaitPeriod',
  [EPlayingBreakBadRequestTypeSchema.invalidUserId]: 'settings.playingBreak.errors.invalidUserId',
  [EPlayingBreakBadRequestTypeSchema.regulatorNotAllowedToCancel]:
    'settings.playingBreak.errors.regulatorNotAllowedToCancel',
};

export const PLAYING_BREAK_INVESTIGATION_ERROR_REASONS: PlayingBreakInvestigationErrorReasons = {
  [EConcludeInvestigationBadRequestTypeSchema.endDateInPast]:
    'settings.playingBreak.errors.investigation.endDateInPast',
  [EConcludeInvestigationBadRequestTypeSchema.noActiveInvestigation]:
    'settings.playingBreak.errors.investigation.noActiveInvestigation',
  [EConcludeInvestigationBadRequestTypeSchema.invalidUserId]: 'settings.playingBreak.errors.investigation.invalidUserId',
  [EConcludeInvestigationBadRequestTypeSchema.noReasonSpecified]: 'settings.playingBreak.errors.investigation.noReasonSpecified',
};
