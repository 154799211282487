import { useCallback } from 'react';
import { DeepMap, FieldError, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  IndividualActionSchema,
  UpdateIndividualActionSchema,
  UpdateLoyaltyPointsActionSchema,
} from '@greenisland-api';
import { capitalize, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { getDefaultIndividualActionNumberInputProps } from '../helpers';
import IndividualActionFormWrapper from '../IndividualActionFormWrapper';

interface LoyaltyPointsErrors extends DeepMap<UpdateLoyaltyPointsActionSchema, FieldError> {
  customErrors?: {
    atLeastOneFieldMustBeGreaterThanZero: FieldError;
  };
}

interface Props {
  individualAction?: IndividualActionSchema;
}

const IndividualActionLoyaltyPointsForm = ({ individualAction }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { register, watch, errors, trigger, getValues } = useFormContext<UpdateIndividualActionSchema>();
  const loyaltyPointsCustomErrors: LoyaltyPointsErrors | undefined = errors.loyaltyPoints;
  const areLoyaltyPointsEnabled = !!watch('loyaltyPoints.enabled');

  const validateLoyaltyPointsHandler = useCallback(
    (currentValue: number, compareTo: number) => {
      if (areLoyaltyPointsEnabled) {
        return currentValue > 0 || compareTo > 0 || t('atLeastOneFieldMustBeGreaterThanZero');
      }
      return true;
    },
    [areLoyaltyPointsEnabled, t]
  );

  return (
    <IndividualActionFormWrapper
      enabled={areLoyaltyPointsEnabled}
      name="loyaltyPoints.enabled"
      listItemText={t('loyaltyPoints')}
    >
      <TextField
        size="small"
        name="loyaltyPoints.spendablePoints"
        label={capitalize(t('spendablePointsLoyaltyPoints'))}
        inputRef={register({
          required: areLoyaltyPointsEnabled,
          setValueAs: value => (value ? Number(value) : undefined),
        })}
        onChange={() => trigger('loyaltyPoints.statusPoints')}
        required={areLoyaltyPointsEnabled}
        InputProps={getDefaultIndividualActionNumberInputProps()}
        disabled={!areLoyaltyPointsEnabled}
        error={Boolean(errors?.loyaltyPoints?.spendablePoints)}
        helperText={errors?.loyaltyPoints?.spendablePoints?.message}
        fullWidth={true}
        defaultValue={individualAction?.loyaltyPoints?.spendablePoints}
      />
      <TextField
        size="small"
        name="loyaltyPoints.statusPoints"
        label={capitalize(t('statusPointsLoyaltyPoints'))}
        inputRef={register({
          required: areLoyaltyPointsEnabled,
          setValueAs: value => (value ? Number(value) : undefined),
          validate: {
            atLeastOneGreatherThanZero: value =>
              validateLoyaltyPointsHandler(value, getValues().loyaltyPoints.spendablePoints),
          },
        })}
        required={areLoyaltyPointsEnabled}
        InputProps={getDefaultIndividualActionNumberInputProps()}
        disabled={!areLoyaltyPointsEnabled}
        error={Boolean(errors?.loyaltyPoints?.statusPoints)}
        helperText={errors?.loyaltyPoints?.statusPoints?.message}
        fullWidth={true}
        defaultValue={individualAction?.loyaltyPoints?.statusPoints}
      />
      {loyaltyPointsCustomErrors?.customErrors && (
        <Typography ml={2} variant="body2" fontSize="small" color={theme.palette.error.main}>
          {loyaltyPointsCustomErrors?.customErrors?.atLeastOneFieldMustBeGreaterThanZero?.message}
        </Typography>
      )}
    </IndividualActionFormWrapper>
  );
};

export default IndividualActionLoyaltyPointsForm;
