import { useCallback, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import {
  EFulfillmentActionSchema,
  ELoyaltyShopManagementBadRequestTypeSchema,
  EMIMETypeSchema,
  getGetAllLoyaltyShopItemsQueryKey,
  LoyaltyShopArgumentsSchema,
  LoyaltyShopsSchema,
  LoyaltyShopsSchemaItem,
  useAddLoyaltyShopItem,
  useGetCampaignActions,
  useUpdateLoyaltyShopItemV2,
} from '@greenisland-api';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  capitalize,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { useSnackbar } from 'notistack';

import { FontAwesomeIcon, SelectInput } from '@greenisland-common/components/atoms';
import SwitchInput from '@greenisland-common/components/atoms/SwitchInput';
import FileUpload from '@greenisland-common/components/molecules/FileUpload';

import LoyaltyDependentValue, {
  LoyaltyDependentType,
} from '../../../Marketing/Components/LoyaltyDependentValue/LoyaltyDependentValue';
import { LOYALTY_SHOP_MANAGEMENT_ERROR_REASONS } from '../Constants/loyaltyShopManagementErrorReasons';

interface Props {
  open: boolean;
  onClose: () => void;
  loyaltyShopItems?: LoyaltyShopsSchema;
  chosenLoyaltyShopItem: LoyaltyShopsSchemaItem | null;
}

const LoyaltyShopDialog = ({ open, onClose, loyaltyShopItems, chosenLoyaltyShopItem }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const queryClient = useQueryClient();

  const loyaltyMaxPurchaseQuantityItems = [
    {
      label: t('marketing.loyaltyDependentMaxPayout.standard'),
      name: 'loyaltyDependentMaxPurchaseQuantity.standardValue',
    },
    {
      label: t('marketing.loyaltyDependentMaxPayout.bronze'),
      name: 'loyaltyDependentMaxPurchaseQuantity.bronzeValue',
    },
    {
      label: t('marketing.loyaltyDependentMaxPayout.silver'),
      name: 'loyaltyDependentMaxPurchaseQuantity.silverValue',
    },
    {
      label: t('marketing.loyaltyDependentMaxPayout.gold'),
      name: 'loyaltyDependentMaxPurchaseQuantity.goldValue',
    },
    {
      label: t('marketing.loyaltyDependentMaxPayout.platinum'),
      name: 'loyaltyDependentMaxPurchaseQuantity.platinumValue',
    },
    {
      label: t('marketing.loyaltyDependentMaxPayout.diamond'),
      name: 'loyaltyDependentMaxPurchaseQuantity.diamondValue',
    },
    {
      label: t('marketing.loyaltyDependentMaxPayout.vip'),
      name: 'loyaltyDependentMaxPurchaseQuantity.vipValue',
    },
  ];

  const { data: actions, isLoading: isLoadingActions } = useGetCampaignActions();

  const methods = useForm<LoyaltyShopArgumentsSchema & { isLoyaltyDependentMaxPurchaseQuantityActive: boolean }>({
    shouldFocusError: true,
    mode: 'onChange',
    defaultValues: {
      isLoyaltyDependentMaxPurchaseQuantityActive: !!chosenLoyaltyShopItem?.loyaltyDependentMaxPurchaseQuantity,
      loyaltyDependentMaxPurchaseQuantity: chosenLoyaltyShopItem?.loyaltyDependentMaxPurchaseQuantity,
    },
  });

  const { register, control, handleSubmit, reset, errors, watch } = methods;

  const isMaxPurchaseQuantitySectionActive = !!watch('isLoyaltyDependentMaxPurchaseQuantityActive');
  const watchFulfillmentAction = watch('fulfillmentAction');

  const onSuccessHandler = useCallback(() => {
    enqueueSnackbar(t('success'), { variant: 'success' });
    queryClient.invalidateQueries(getGetAllLoyaltyShopItemsQueryKey({ visibleOnly: false }));
    onClose();
    reset();
  }, [enqueueSnackbar, onClose, queryClient, reset, t]);

  const onErrorHandler = useCallback(
    error => {
      if (error) {
        const errorReason = error?.type;
        const translationKey =
          LOYALTY_SHOP_MANAGEMENT_ERROR_REASONS[errorReason as ELoyaltyShopManagementBadRequestTypeSchema] ??
          'somethingWentWrong';
        enqueueSnackbar(t(translationKey), { variant: 'error' });
      }
    },
    [enqueueSnackbar, t]
  );

  const { mutate: addLoyaltyShopItem, isLoading: isAddLoyaltyShopItemLoading } = useAddLoyaltyShopItem({
    mutation: { onSuccess: onSuccessHandler, onError: onErrorHandler },
  });
  const { mutate: updateLoyaltyShopItem, isLoading: isUpdateLoyaltyShopItemLoader } = useUpdateLoyaltyShopItemV2({
    mutation: { onSuccess: onSuccessHandler, onError: onErrorHandler },
  });

  const loyaltyShop = useMemo(
    () => loyaltyShopItems?.find(item => item?.loyaltyShopId === chosenLoyaltyShopItem?.loyaltyShopId),
    [chosenLoyaltyShopItem?.loyaltyShopId, loyaltyShopItems]
  );

  const fulfillmentActionIdOptions = useMemo(() => {
    return (
      actions
        ?.sort((a, b) => a?.actionName?.localeCompare(b?.actionName))
        ?.map(entry => ({ label: entry?.actionName, value: entry?.actionId })) ?? []
    );
  }, [actions]);

  const onSubmit = (data: LoyaltyShopArgumentsSchema) => {
    const loyaltyDependentMaxPurchaseQuantityPayload =
      isMaxPurchaseQuantitySectionActive && data?.loyaltyDependentMaxPurchaseQuantity
        ? Object.fromEntries(
            Object.entries(data.loyaltyDependentMaxPurchaseQuantity).map(([key, value]) => [key, Number(value)])
          )
        : undefined;

    if (chosenLoyaltyShopItem) {
      return updateLoyaltyShopItem({
        loyaltyShopItemId: chosenLoyaltyShopItem?.loyaltyShopId,
        data: { ...data, loyaltyDependentMaxPurchaseQuantity: loyaltyDependentMaxPurchaseQuantityPayload },
      });
    }
    return addLoyaltyShopItem({
      data: { ...data, loyaltyDependentMaxPurchaseQuantity: loyaltyDependentMaxPurchaseQuantityPayload },
    });
  };

  return (
    <Dialog fullWidth open={open} maxWidth="sm">
      <DialogTitle>
        {capitalize(t(`loyalty.loyaltyShop.titles.${!chosenLoyaltyShopItem ? 'add' : 'edit'}`))}
      </DialogTitle>
      <IconButton
        edge="start"
        color="inherit"
        onClick={onClose}
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 16,
          top: 8,
        }}
      >
        <FontAwesomeIcon icon={faClose} />
      </IconButton>
      <DialogContent>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SwitchInput
              label={capitalize(t('visible'))}
              name="visible"
              defaultValue={chosenLoyaltyShopItem?.visible}
              sx={{ mb: 2 }}
            />
            <Stack spacing={2}>
              <TextField
                required
                size="small"
                label={capitalize(t('title'))}
                name="title"
                error={!!errors.title}
                inputRef={register({ required: t('fieldIsRequired') })}
                defaultValue={chosenLoyaltyShopItem?.title}
              />
              <TextField
                required
                size="small"
                label={capitalize(t('price'))}
                name="price"
                error={!!errors.price}
                type={'number'}
                inputRef={register({ required: t('fieldIsRequired'), valueAsNumber: true })}
                defaultValue={chosenLoyaltyShopItem?.price}
              />
              <SelectInput
                size="small"
                name="fulfillmentAction"
                label={capitalize(t('fulfillmentAction'))}
                options={Object.keys(EFulfillmentActionSchema).map(key => ({ value: key, label: t(key) }))}
                defaultValue={loyaltyShop?.fulfillmentAction || EFulfillmentActionSchema.Action}
                rules={{ required: t('fieldIsRequired') }}
                required={true}
                clearSelect={false}
              />
              {watchFulfillmentAction === EFulfillmentActionSchema.Action ? (
                <Controller
                  control={control}
                  name="fulfillmentActionId"
                  defaultValue={chosenLoyaltyShopItem?.fulfillmentActionId}
                  render={({ value, onChange }) => (
                    <Autocomplete
                      value={fulfillmentActionIdOptions?.find(option => option?.value === value) ?? null}
                      onChange={(_event, value) => onChange(value?.value)}
                      options={fulfillmentActionIdOptions}
                      getOptionLabel={option => option.label ?? ''}
                      renderOption={(props, option) => (
                        <li {...props} key={option.value}>
                          {option.label}
                        </li>
                      )}
                      loading={isLoadingActions}
                      renderInput={params => <TextField required {...params} size="small" label={t('actions')} />}
                    />
                  )}
                />
              ) : (
                <TextField
                  size="small"
                  label={t('fulfillmentItem')}
                  name="fulfillmentItem"
                  error={!!errors.fulfillmentItem}
                  inputRef={register({ required: t('fieldIsRequired') })}
                  defaultValue={loyaltyShop?.fulfillmentItem}
                />
              )}
              <Typography variant="body2" fontWeight={500} fontSize="medium">
                {capitalize(t('image'))}
              </Typography>
              <Controller
                render={({ value, onChange }) => {
                  return (
                    <FileUpload
                      error={!!errors.image}
                      id="loyaltyShop"
                      type="image"
                      onChange={onChange}
                      defaultFile={loyaltyShop?.imageUrl || value}
                      buttonTitle="uploadImage"
                      shouldBeAbleToSave={false}
                      requirements={{
                        dimensions: { width: 600, height: 400 },
                        mimeTypes: [EMIMETypeSchema['image/jpeg'], EMIMETypeSchema['image/jpg']],
                      }}
                    />
                  );
                }}
                control={control}
                defaultValue={null}
                name={'image'}
              />
              <Box p={1} borderRadius="5px" border={`1px solid ${theme.palette.text.disabled}`}>
                <SwitchInput
                  label={capitalize(t('loyalty.loyaltyShop.titles.loyaltyDependentMaxPurchaseQuantity'))}
                  name="isLoyaltyDependentMaxPurchaseQuantityActive"
                  sx={{ m: 0 }}
                />
                <LoyaltyDependentValue
                  type={LoyaltyDependentType.QUANTITY}
                  disabled={true}
                  options={loyaltyMaxPurchaseQuantityItems}
                  sx={{
                    display: isMaxPurchaseQuantitySectionActive ? 'flex' : 'none',
                    mt: 1,
                  }}
                  isRequired={isMaxPurchaseQuantitySectionActive}
                />
              </Box>
            </Stack>
            <DialogActions>
              <Button variant="outlined" onClick={onClose}>
                {t('cancel')}
              </Button>
              <LoadingButton
                loading={isAddLoyaltyShopItemLoading || isUpdateLoyaltyShopItemLoader}
                variant="contained"
                type="submit"
                disabled={isAddLoyaltyShopItemLoading || isUpdateLoyaltyShopItemLoader}
              >
                {t('save')}
              </LoadingButton>
            </DialogActions>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default LoyaltyShopDialog;
