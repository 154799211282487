import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { CampaignsOverviewSchema, useGetCampaignActions } from '@greenisland-api';
import {
  Autocomplete,
  Box,
  Button,
  capitalize,
  Card,
  CardContent,
  createFilterOptions,
  Stack,
  TextField,
} from '@mui/material';

import { useUpdateSearchParams } from '@greenisland-common/hooks/useUpdateSearchParams';

import { CampaignParamsType } from './consts';
import { useGetCampaignFilterSortedOptions, useGetCampaignFiltersQuery } from './hooks';

interface Props {
  campaigns?: CampaignsOverviewSchema;
}

const CampaignFilters = ({ campaigns }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const updateCampaignsQueryParamsHandler = useUpdateSearchParams();
  const { data: actions, isLoading: isLoadingActions } = useGetCampaignActions();

  const { campaignYearQuery, campaignIdQuery, campaignNameQuery, actionIdQuery } = useGetCampaignFiltersQuery();
  const { campaignIdsOptions, campaignYearsOptions, actionIdsOptions } = useGetCampaignFilterSortedOptions(
    campaigns,
    actions
  );

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: { label: string; value: string }) => `${option.label} ${option.value}`,
  });

  useEffect(() => {
    if (actionIdQuery) {
      const foundActionCampaignId = actionIdsOptions?.find(option => option?.value === actionIdQuery)?.campaignId;
      if (foundActionCampaignId) {
        updateCampaignsQueryParamsHandler(CampaignParamsType.CAMPAIGN_ID, foundActionCampaignId || '');
      }
    }
  }, [actionIdQuery, actionIdsOptions, updateCampaignsQueryParamsHandler]);

  return (
    <Card>
      <CardContent>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 1, md: 2 }}>
          <Box sx={{ width: { xs: '100%', md: '20%' } }}>
            <Autocomplete
              fullWidth={true}
              id={CampaignParamsType.CAMPAIGN_ID}
              multiple={false}
              options={campaignIdsOptions}
              getOptionLabel={option => option || ''}
              renderInput={params => <TextField {...params} size="small" label={capitalize(t('campaignId'))} />}
              value={campaignIdQuery || null}
              onChange={(_, campaignId) =>
                updateCampaignsQueryParamsHandler(CampaignParamsType.CAMPAIGN_ID, campaignId || '')
              }
            />
          </Box>
          <Box sx={{ width: { xs: '100%', md: '20%' } }}>
            <Autocomplete
              fullWidth={true}
              id={CampaignParamsType.YEAR}
              multiple={false}
              options={campaignYearsOptions}
              getOptionLabel={option => option || ''}
              renderInput={params => <TextField {...params} size="small" label={capitalize(t('years'))} />}
              value={campaignYearQuery || null}
              onChange={(_, year) => updateCampaignsQueryParamsHandler(CampaignParamsType.YEAR, year || '')}
            />
          </Box>
          <Box sx={{ width: { xs: '100%', md: '20%' } }}>
            <TextField
              fullWidth
              size="small"
              label={t('campaignName')}
              type="text"
              value={campaignNameQuery || ''}
              onChange={event =>
                updateCampaignsQueryParamsHandler(CampaignParamsType.CAMPAIGN_NAME, event.target.value)
              }
            />
          </Box>
          <Box sx={{ width: { xs: '100%', md: '20%' } }}>
            <Autocomplete
              fullWidth={true}
              id={CampaignParamsType.ACTION_ID}
              multiple={false}
              options={actionIdsOptions}
              getOptionLabel={option => option.label ?? ''}
              value={actionIdsOptions?.find(option => option?.value === actionIdQuery) || null}
              filterOptions={filterOptions}
              renderInput={params => <TextField required={false} {...params} size="small" label={t('actions')} />}
              renderOption={(props, option) => (
                <li {...props} key={option.value}>
                  {option.label}
                </li>
              )}
              loading={isLoadingActions}
              onChange={(_, actionId) =>
                updateCampaignsQueryParamsHandler(CampaignParamsType.ACTION_ID, actionId?.value || '')
              }
            />
          </Box>
          <Button
            sx={{ width: 'fit-content' }}
            size="medium"
            variant="contained"
            color="warning"
            onClick={() => navigate(pathname, { replace: true })}
          >
            {t('resetFilters')}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CampaignFilters;
