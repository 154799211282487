enum CampaignParamsType {
  YEAR = 'year',
  CAMPAIGN_ID = 'campaignId',
  CAMPAIGN_NAME = 'campaignName',
  CAMPAIGN_PAGE = 'page',
  CAMPAIGN_PAGE_SIZE = 'pageSize',
  ACTION_ID = 'actionId',
}

export { CampaignParamsType };
export * from './processIndividualActionErrorReasons';
