import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CampaignActionsSchema, CampaignSchema, CampaignsOverviewSchema } from '@greenisland-api';
import Fuse, { FuseResult } from 'fuse.js';

import { CampaignParamsType } from '../consts';

const useGetCampaignFiltersQuery = () => {
  const [searchParams] = useSearchParams();

  const campaignYearQuery = searchParams.get(CampaignParamsType.YEAR);
  const campaignIdQuery = searchParams.get(CampaignParamsType.CAMPAIGN_ID);
  const campaignNameQuery = searchParams.get(CampaignParamsType.CAMPAIGN_NAME);
  const campaignPageQuery = searchParams.get(CampaignParamsType.CAMPAIGN_PAGE);
  const campaignPageSizeQuery = searchParams.get(CampaignParamsType.CAMPAIGN_PAGE_SIZE);
  const actionIdQuery = searchParams.get(CampaignParamsType.ACTION_ID);

  return {
    campaignYearQuery,
    campaignIdQuery,
    campaignNameQuery,
    campaignPageQuery,
    campaignPageSizeQuery,
    actionIdQuery,
  };
};

const useGetCampaignFilterSortedOptions = (
  campaigns: CampaignsOverviewSchema | undefined,
  actions: CampaignActionsSchema | undefined
) => {
  const { campaignIdQuery } = useGetCampaignFiltersQuery();

  const extractAndSortUniqueValues = (key: 'campaignId' | 'year') => {
    if (!campaigns?.length) return [];
    return Array.from(new Set(campaigns.map(campaign => campaign?.[key]).filter(Boolean) as string[])).sort((a, b) =>
      b.localeCompare(a)
    );
  };

  const actionIdsOptions = useMemo(() => {
    const sortedActions =
      actions
        ?.sort((a, b) => a?.actionName?.localeCompare(b?.actionName))
        ?.map(entry => ({ label: entry?.actionName, value: entry?.actionId, campaignId: entry?.campaignId })) ?? [];

    if (campaignIdQuery) {
      return sortedActions?.filter(action => action?.campaignId === campaignIdQuery);
    }
    return sortedActions;
  }, [actions, campaignIdQuery]);

  const campaignIdsOptions = extractAndSortUniqueValues('campaignId');
  const campaignYearsOptions = extractAndSortUniqueValues('year');

  return { campaignIdsOptions, campaignYearsOptions, actionIdsOptions };
};

const useGetFilteredCampaigns = (campaigns: CampaignsOverviewSchema | undefined) => {
  const { campaignNameQuery, campaignYearQuery, campaignIdQuery } = useGetCampaignFiltersQuery();

  const filteredCampaigns = useMemo(() => {
    let result: CampaignSchema[] | undefined = campaigns;

    if (campaignIdQuery) {
      result = result?.filter(campaign => campaign?.campaignId === campaignIdQuery);
    }
    if (campaignYearQuery) {
      result = result?.filter(campaign => campaign?.year === campaignYearQuery);
    }
    if (campaignNameQuery) {
      const fuseSetup = new Fuse(result || [], { keys: ['campaignName'], threshold: 0.3 });
      const matchedCampaigns: FuseResult<CampaignSchema>[] = fuseSetup.search(campaignNameQuery || '');
      result = matchedCampaigns?.map(campaign => campaign?.item);
    }

    return result?.length ? result : [];
  }, [campaigns, campaignIdQuery, campaignYearQuery, campaignNameQuery]);

  return {
    filteredCampaigns,
  };
};

export { useGetCampaignFiltersQuery, useGetCampaignFilterSortedOptions, useGetFilteredCampaigns };
