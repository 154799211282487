import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faAdd, faCopy, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CampaignActionSchema } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid-pro';

import { DataGridContainer } from '@greenisland-common/components/atoms';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import { usePermission } from '../../../../../app/hooks';
import { theme } from '../../../../../app/theme/theme';
import IndividualActionFormDialog from '../forms/IndividualActionFormDialog';
import ProcessActionFormDialog from '../forms/ProcessActionFormDialog';
import { useGetCampaignFiltersQuery } from '../hooks';

type Row = CampaignActionSchema;

interface Props {
  campaignId: string;
  individualActions?: Row[];
}

const IndividualActions = ({ campaignId, individualActions }: Props) => {
  const { t } = useTranslation();
  const { actionIdQuery } = useGetCampaignFiltersQuery();

  const canAddIndividualAction = usePermission(OnlineCasinoPermissions.addIndividualAction);
  const canEditIndividualAction = usePermission(OnlineCasinoPermissions.updateIndividualAction);
  const canProcessOneAction = usePermission(OnlineCasinoPermissions.triggerIndividualAction);
  const canProcessMultipleActions = usePermission(
    [
      OnlineCasinoPermissions.triggerIndividualActionAllAccounts,
      OnlineCasinoPermissions.triggerIndividualActionAllClosedAccounts,
    ],
    { strict: false }
  );

  const [selectedIndividualAction, setSelectedIndividualAction] = useState<Row | undefined>(undefined);
  const [isIndividualActionDialogOpen, setIsIndividualActionDialogOpen] = useState(false);
  const [isProcessIndividualActionDialogOpen, setIsProcessIndividualActionDialogOpen] = useState(false);
  const [isProcessMultiple, setIsProcessMultiple] = useState(false);

  const setIndividualActionHandler = useCallback((action: Row | undefined, dialogState = false) => {
    setSelectedIndividualAction(action);
    setIsIndividualActionDialogOpen(dialogState);
  }, []);

  const setProcessIndividualActionHandler = useCallback(
    (action: Row | undefined, isMultiple = false, dialogState = false) => {
      setIsProcessMultiple(isMultiple);
      setSelectedIndividualAction(action);
      setIsProcessIndividualActionDialogOpen(dialogState);
    },
    []
  );

  const columns = useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'name',
        headerName: capitalize(t('name')),
        sortable: false,
        minWidth: 450,
        flex: 0.1,
        renderCell: ({ value }) => {
          return (
            <Tooltip title={capitalize(t(value))}>
              <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} variant="body2" fontSize="sm">
                {value || '-'}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        field: 'actionId',
        headerName: capitalize(t('actionId')),
        sortable: false,
        flex: 0.1,
        minWidth: 350,
        renderCell: ({ value }) => {
          return (
            <Box display="flex" alignItems="center" width="100%">
              <Box display="flex" alignItems="center" justifyContent="space-between" width="85%">
                <Tooltip title={capitalize(t(value))}>
                  <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} variant="body2" fontSize="sm">
                    {value || '-'}
                  </Typography>
                </Tooltip>
                <Tooltip title={capitalize(t('copy'))}>
                  <Box>
                    <FontAwesomeIcon
                      style={{ color: theme.palette.text.secondary, cursor: 'pointer' }}
                      icon={faCopy}
                      onClick={() => navigator.clipboard.writeText(value)}
                    />
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          );
        },
      },
      {
        field: '',
        headerName: '',
        sortable: false,
        minWidth: 50,
        flex: 0.01,
        renderCell: ({ row }) => {
          return (
            canEditIndividualAction && (
              <Tooltip title={t('editAction')}>
                <IconButton size="small" onClick={() => setIndividualActionHandler(row, true)}>
                  <FontAwesomeIcon size="xs" icon={faPencil} />
                </IconButton>
              </Tooltip>
            )
          );
        },
      },
      {
        field: 'actions',
        headerName: '',
        flex: 0.1,
        minWidth: 400,
        sortable: false,
        align: 'right',
        renderCell: params => (
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            {canProcessOneAction && (
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() => setProcessIndividualActionHandler(params.row, false, true)}
              >
                <Typography fontSize="small">{t('processForOne')}</Typography>
              </Button>
            )}
            {canProcessMultipleActions && (
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() => setProcessIndividualActionHandler(params.row, true, true)}
              >
                <Typography fontSize="small">{t('processForMultiple')}</Typography>
              </Button>
            )}
          </Stack>
        ),
      },
    ],
    [
      t,
      canEditIndividualAction,
      setIndividualActionHandler,
      canProcessOneAction,
      canProcessMultipleActions,
      setProcessIndividualActionHandler,
    ]
  );

  const rows = useMemo(() => {
    const mappedIndividualActions =
      individualActions?.map<Row>(individualAction => ({
        ...individualAction,
        id: individualAction?.actionId,
      })) || [];

    if (actionIdQuery) {
      return mappedIndividualActions?.filter(action => action?.actionId === actionIdQuery);
    }
    return mappedIndividualActions;
  }, [actionIdQuery, individualActions]);

  return (
    <Stack p={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" fontWeight={500} mb={2} fontSize="x-large">
          {capitalize(t('individualActions'))}
        </Typography>
        {canAddIndividualAction && (
          <Button
            variant="contained"
            size="small"
            startIcon={<FontAwesomeIcon icon={faAdd} />}
            onClick={() => setIndividualActionHandler(undefined, true)}
          >
            {capitalize(t('addAction'))}
          </Button>
        )}
      </Box>
      <DataGridContainer>
        <LimitedDataGrid density="compact" columns={columns} rows={rows} autoHeight />
      </DataGridContainer>
      {isIndividualActionDialogOpen && (
        <IndividualActionFormDialog
          isOpen={true}
          campaignId={campaignId}
          actionId={selectedIndividualAction?.actionId}
          onClose={() => setIndividualActionHandler(undefined, false)}
        />
      )}
      {isProcessIndividualActionDialogOpen && selectedIndividualAction && (
        <ProcessActionFormDialog
          isProcessMultiple={isProcessMultiple}
          selectedIndividualAction={selectedIndividualAction}
          campaignId={campaignId}
          onClose={() => setProcessIndividualActionHandler(undefined)}
        />
      )}
    </Stack>
  );
};

export default IndividualActions;
