import { useCallback, useEffect, useMemo, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { faCertificate, faEye } from '@fortawesome/free-solid-svg-icons';
import {
  Base64FileSchema,
  ErrorSchema,
  EVerificationStatusSchema,
  getGetUserVerificationsQueryKey,
  useArchiveUserBankAccountVerification,
  useGetDocumentById,
  UserDocumentMetadataSchema,
  useUnarchiveUserBankAccountVerification,
  useUpdateUserVerificationsBankAccountStatus,
  VerificationsBankAccountSchema,
  VerificationsBankAccountsSchema,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, capitalize, Card, CardContent, CardHeader, Tooltip, Typography } from '@mui/material';
import { GridColumns, GridRowParams } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';

import { CheckboxIcon, FontAwesomeIcon, Link, NoData, StyledDataGrid } from '@greenisland-common/components/atoms';
import ImageDialog from '@greenisland-common/components/molecules/ImageDialog';
import PdfDialog from '@greenisland-common/components/molecules/PdfDialog';

import { formatBase64String } from '@greenisland-common/helpers';

import { getStatusColorClass } from '../../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../../app/hooks';
import DeclineVerificationDialog from '../DeclineVerificationDialog';
import UploadBankAccountVerificationField from './UploadBankAccountVerificationField';
import { LoadingButton } from '@mui/lab';

interface Props {
  bankAccounts: VerificationsBankAccountsSchema;
}

const BankAccounts = ({ bankAccounts }: Props) => {
  const { t } = useTranslation();
  const { userId = '' } = useParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const canUpdateVerificationStatus = usePermission(OnlineCasinoPermissions.updateUserVerificationsBankAccountStatus);
  const canArchiveBankAccount = usePermission(OnlineCasinoPermissions.archiveUserBankAccountVerification);
  const canUnarchiveBankAccount = usePermission(OnlineCasinoPermissions.unarchiveUserBankAccountVerification);

  const [chosenItemId, setChosenItemId] = useState<string | null>(null);

  const [openMedia, setOpenMedia] = useState<Array<Base64FileSchema & { id: number }>>([]);
  const [documentMetadata, setDocumentMetadata] = useState<UserDocumentMetadataSchema | undefined>(undefined);
  const [declineVerificationDialog, setDeclineVerificationDialog] = useState<{
    open: boolean;
    bankAccount: VerificationsBankAccountSchema | undefined;
  }>({
    open: false,
    bankAccount: undefined,
  });

  const { refetch, isRefetching, isLoading } = useGetDocumentById(documentMetadata?.documentId as number, {
    query: { enabled: false },
  });

  const onSuccessHandler = () => {
    enqueueSnackbar(t('success'), { variant: 'success' });
    queryClient.invalidateQueries(getGetUserVerificationsQueryKey(userId));
  };

  const onErrorHandler = (error: ErrorSchema) => {
    enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
  };

  const mutation = {
    onSuccess: onSuccessHandler,
    onError: onErrorHandler,
  };

  const {
    mutate: updateUserVerificationsBankAccountStatus,
    isLoading: isUpdatingUserVerificationsBankAccountStatusLoading,
  } = useUpdateUserVerificationsBankAccountStatus({ mutation });

  const { mutate: archiveBankAccount, isLoading: isArchivingBankAccountLoading } =
    useArchiveUserBankAccountVerification({
      mutation,
    });

  const { mutate: unarchiveBankAccount, isLoading: isUnarchivingBankAccountLoading } =
    useUnarchiveUserBankAccountVerification({
      mutation,
    });

  const hasActions = useMemo(
    () =>
      bankAccounts?.some(
        ({ verificationStatus }) =>
          verificationStatus === EVerificationStatusSchema.Unverified ||
          verificationStatus === EVerificationStatusSchema.WaitingForApproval
      ),
    [bankAccounts]
  );

  const headers: GridColumns<VerificationsBankAccountSchema> = [
    {
      headerName: '',
      field: 'isMainBankAccount',
      type: 'actions',
      renderCell: ({ value }) =>
        value ? (
          <Tooltip title={t('mainBankAccount')}>
            <FontAwesomeIcon sx={{ ml: 1 }} icon={faCertificate} />
          </Tooltip>
        ) : (
          ''
        ),
      minWidth: 50,
      flex: 0.1,
    },
    {
      headerName: capitalize(t('bankAccount')),
      field: 'bankAccountNumber',
      renderCell: ({ value, row }) => (
        <span>
          <Link to={`../userbankaccounts?iban=${value}`}>{value.match(/.{1,4}/g)?.join(' ')}</Link>
          {row?.bankAccountDocumentMetadata ? (
            <LoadingButton
              loading={row?.bankAccountId === chosenItemId && (isRefetching || isLoading)}
              color="secondary"
              variant="text"
              onClick={() => {
                setChosenItemId(row.bankAccountId);
                setDocumentMetadata(row.bankAccountDocumentMetadata);
              }}
              sx={{ ml: 0.5 }}
              disableRipple
            >
              <FontAwesomeIcon sx={{ width: 15 }} icon={faEye} />
            </LoadingButton>
          ) : null}
        </span>
      ),
      minWidth: 280,
      flex: 0.2,
    },
    {
      headerName: capitalize(t('verificationStatus.title')),
      field: 'verificationStatus',
      renderCell: ({ value }) => (
        <Typography variant="body2" sx={getStatusColorClass(value)}>
          {t(value)}
        </Typography>
      ),
      minWidth: 160,
      flex: 0.1,
    },
    {
      headerName: capitalize(t('active')),
      field: 'archived',
      renderCell: ({ value }) => <CheckboxIcon checked={!value} />,
      align: 'center',
      headerAlign: 'center',
      minWidth: 140,
      flex: 0.1,
    },
    {
      align: 'left',
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams<VerificationsBankAccountSchema>) => [
        <Box key="update" sx={{ ml: 1, display: 'inline' }}>
          <LoadingButton
            loading={params?.row?.bankAccountId === chosenItemId && isUpdatingUserVerificationsBankAccountStatusLoading}
            disabled={
              !canUpdateVerificationStatus || params.row.verificationStatus !== EVerificationStatusSchema.Unverified
            }
            sx={{ mr: 1 }}
            size="small"
            variant="outlined"
            onClick={() => {
              setChosenItemId(params?.row?.bankAccountId);
              updateUserVerificationsBankAccountStatus({
                userId,
                bankAccountId: params.row.bankAccountId,
                data: { verificationStatus: EVerificationStatusSchema.NeedsVerification },
              });
            }}
          >
            {t('needsVerification')}
          </LoadingButton>
          <>
            <LoadingButton
              loading={
                params?.row?.bankAccountId === chosenItemId && isUpdatingUserVerificationsBankAccountStatusLoading
              }
              disabled={
                !canUpdateVerificationStatus ||
                params.row.verificationStatus !== EVerificationStatusSchema.WaitingForApproval
              }
              sx={{ mr: 1 }}
              size="small"
              color="primary"
              variant="contained"
              onClick={() => {
                setChosenItemId(params?.row?.bankAccountId);
                updateUserVerificationsBankAccountStatus({
                  userId,
                  bankAccountId: params.row.bankAccountId,
                  data: { verificationStatus: EVerificationStatusSchema.Verified },
                });
              }}
            >
              {t('verify')}
            </LoadingButton>
            <LoadingButton
              loading={
                params?.row?.bankAccountId === chosenItemId && isUpdatingUserVerificationsBankAccountStatusLoading
              }
              disabled={
                !canUpdateVerificationStatus ||
                params.row.verificationStatus !== EVerificationStatusSchema.WaitingForApproval
              }
              sx={{ mr: 1 }}
              size="small"
              variant="outlined"
              color="error"
              onClick={() => {
                setChosenItemId(params?.row?.bankAccountId);
                setDeclineVerificationDialog({ bankAccount: params.row, open: true });
              }}
            >
              {t('decline')}
            </LoadingButton>
          </>
          {!params?.row?.archived && (
            <LoadingButton
              loading={params?.row?.bankAccountId === chosenItemId && isArchivingBankAccountLoading}
              disabled={!canArchiveBankAccount}
              sx={{ mr: 1, width: 100 }}
              size="small"
              color="error"
              variant="outlined"
              onClick={() => {
                setChosenItemId(params?.row?.bankAccountId);
                archiveBankAccount({ userId, bankAccountNumber: params.row.bankAccountNumber });
              }}
            >
              {t('archive')}
            </LoadingButton>
          )}
          {params?.row?.archived && (
            <LoadingButton
              loading={params?.row?.bankAccountId === chosenItemId && isUnarchivingBankAccountLoading}
              disabled={!canUnarchiveBankAccount}
              sx={{ mr: 1, width: 100 }}
              size="small"
              color="primary"
              variant="outlined"
              onClick={() => {
                setChosenItemId(params?.row?.bankAccountId);
                unarchiveBankAccount({ userId, bankAccountId: params.row.bankAccountId });
              }}
            >
              {t('activate')}
            </LoadingButton>
          )}
        </Box>,
        <UploadBankAccountVerificationField
          key="upload"
          userId={userId}
          bankAccountId={params.row.bankAccountId}
          metadata={params?.row?.bankAccountDocumentMetadata}
        />,
      ],
      minWidth: canUpdateVerificationStatus && hasActions ? 700 : 600,
      flex: 0.5,
    },
  ];

  const rows = bankAccounts?.map(item => ({ ...item, id: item?.bankAccountId }));

  const previewMetadataImageHandler = useCallback(async () => {
    if (documentMetadata) {
      const response = await refetch();
      if (response?.data?.document) {
        const documentData = response.data.document;

        setOpenMedia(prevMedia => [
          ...prevMedia,
          { ...documentData, content: formatBase64String(documentData.content, documentData.mimeType), id: Date.now() },
        ]);
        setDocumentMetadata(undefined);
      }
    }
  }, [documentMetadata, refetch]);

  useEffect(() => {
    if (documentMetadata) {
      previewMetadataImageHandler();
    }
  }, [documentMetadata, previewMetadataImageHandler]);

  return (
    <>
      <Card sx={{ mt: 2, pl: 0, pr: 0 }}>
        <CardHeader title={t('bankAccounts')} />
        <CardContent sx={{ p: 2 }}>
          {rows.length ? (
            <StyledDataGrid hideFooter autoHeight density="compact" columns={headers} rows={rows} />
          ) : (
            <NoData />
          )}
          <DeclineVerificationDialog
            open={declineVerificationDialog.open}
            setOpen={() => setDeclineVerificationDialog({ ...declineVerificationDialog, open: false })}
            action={values =>
              updateUserVerificationsBankAccountStatus({
                userId,
                bankAccountId: declineVerificationDialog?.bankAccount?.bankAccountId || '',
                data: values,
              })
            }
            bankaccount={declineVerificationDialog.bankAccount}
          />
        </CardContent>
      </Card>
      {openMedia?.map(media => (
        <Fragment key={media?.id}>
          {media && media?.mimeType !== 'application/pdf' && (
            <ImageDialog
              openImageDialog={true}
              setOpenImageDialog={() => setOpenMedia(prev => prev?.filter(openModal => openModal?.id !== media.id))}
              image={media.content}
            />
          )}
          {media && media?.mimeType === 'application/pdf' && (
            <PdfDialog
              openPdfDialog={true}
              setOpenPdfDialog={() => setOpenMedia(prev => prev?.filter(openModal => openModal?.id !== media.id))}
              image={media.content}
            />
          )}
        </Fragment>
      ))}
    </>
  );
};

export default BankAccounts;
