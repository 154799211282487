import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  faArrowsAlt,
  faCircleXmark,
  faRedo,
  faSearchMinus,
  faSearchPlus,
  faUndo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Dialog, DialogProps, IconButton, styled, Tooltip } from '@mui/material';

const DialogImage = styled('img')(({ theme }) => ({
  objectFit: 'cover',
  margin: 'auto',
  height: '40rem',
  [theme.breakpoints.down('xl')]: {
    height: '30rem',
  },
  [theme.breakpoints.down('lg')]: {
    height: 'auto',
    width: '100%',
  },
  cursor: 'grab',
  '&:active': {
    cursor: 'grabbing',
  },
}));

const TransformIcons = styled(Box)({
  position: 'absolute',
  display: 'flex',
  bottom: 5,
  transform: 'translateX(-50%)',
  left: '50%',
  zIndex: 100,
  '& button': {
    margin: '0 5px',
  },
});

const CloseIcon = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  zIndex: 100,
  color: theme.palette.error.main,
}));

const DragIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  left: 0,
  zIndex: 100,
  cursor: 'move',
  background: theme.palette.primary.main,
  color: 'white',
  width: 48,
  minWidth: 48,
  maxWidth: 48,
  height: 48,
  minHeight: 48,
  maxHeight: 48,
  borderRadius: '50%',
  boxShadow: '0 1px 3px rgb(0 0 0 / 20%), 0 1px 1px rgb(0 0 0 / 14%), 0 2px 1px rgb(0 0 0 / 12%)',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const StyledDialog = styled(Dialog)<DialogProps>(({ theme }) => ({
  bottom: 'auto',
  left: 'auto',
  '& .MuiDialog-paper': {
    pointerEvents: 'auto',
    width: '45rem',
    height: '45rem',
    [theme.breakpoints.down('xl')]: {
      width: '35rem',
      height: '35rem',
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      height: 'auto',
    },
    overflow: 'hidden',
  },
  '& .MuiDialog-root': {
    pointerEvents: 'none',
  },
  '& .MuiDialog-container': {
    width: 'fit-content',
    height: 'auto',
    alignItems: 'flex-end',
  },
}));

const ImageDialog = (props: {
  openImageDialog: boolean;
  setOpenImageDialog: (state: boolean) => void;
  image: string;
}) => {
  const { openImageDialog, setOpenImageDialog, image } = props;
  const { t } = useTranslation();

  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1);
  const [isPanning, setPanning] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [dialogPosition, setDialogPosition] = useState({ oldClientX: 0, oldClientY: 0, x: 0, y: 0 });
  const [position, setPosition] = useState({ oldClientX: 0, oldClientY: 0, x: 0, y: 0 });

  const rotateRight = () => {
    if (rotation !== 270) {
      setRotation(rotation + 90);
    } else setRotation(0);

    setPosition({ oldClientX: 0, oldClientY: 0, x: 0, y: 0 });
  };

  const rotateLeft = () => {
    if (rotation !== -270) {
      setRotation(rotation - 90);
    } else setRotation(0);

    setPosition({ oldClientX: 0, oldClientY: 0, x: 0, y: 0 });
  };

  const zoomIn = () => setScale(scale * 1.5);
  const zoomOut = () => setScale(scale / 1.5);

  const onMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    setPanning(true);
    setPosition({
      ...position,
      oldClientX: e.clientX / scale,
      oldClientY: e.clientY / scale,
    });
  };

  const onMouseDownDialog = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsDragging(true);
    setDialogPosition({
      ...dialogPosition,
      oldClientX: e.clientX,
      oldClientY: e.clientY,
    });
  };

  useEffect(() => {
    const mouseup = () => {
      setPanning(false);
      setIsDragging(false);
    };

    const mousemove = (e: MouseEvent) => {
      if (isPanning) {
        switch (rotation) {
          case 90:
          case -270:
            setPosition({
              ...position,
              x: position.x + e.clientY / scale - position.oldClientY,
              y: position.y - e.clientX / scale + position.oldClientX,
              oldClientX: e.clientX / scale,
              oldClientY: e.clientY / scale,
            });
            break;
          case 180:
          case -180:
            setPosition({
              ...position,
              x: position.x - e.clientX / scale + position.oldClientX,
              y: position.y - e.clientY / scale + position.oldClientY,
              oldClientX: e.clientX / scale,
              oldClientY: e.clientY / scale,
            });
            break;
          case 270:
          case -90:
            setPosition({
              ...position,
              x: position.x - e.clientY / scale + position.oldClientY,
              y: position.y + e.clientX / scale - position.oldClientX,
              oldClientX: e.clientX / scale,
              oldClientY: e.clientY / scale,
            });
            break;
          default:
            setPosition({
              ...position,
              x: position.x + e.clientX / scale - position.oldClientX,
              y: position.y + e.clientY / scale - position.oldClientY,
              oldClientX: e.clientX / scale,
              oldClientY: e.clientY / scale,
            });
        }
      } else if (isDragging) {
        setDialogPosition({
          ...dialogPosition,
          x: dialogPosition.x + e.clientX - dialogPosition.oldClientX,
          y: dialogPosition.y + e.clientY - dialogPosition.oldClientY,
          oldClientX: e.clientX,
          oldClientY: e.clientY,
        });
      }
    };

    window.addEventListener('mouseup', mouseup);
    window.addEventListener('mousemove', mousemove);

    return () => {
      window.removeEventListener('mouseup', mouseup);
      window.removeEventListener('mousemove', mousemove);
    };
  });

  const closeDialog = () => {
    setOpenImageDialog(false);
    setRotation(0);
    setScale(1);
  };

  return (
    <StyledDialog
      open={openImageDialog}
      maxWidth="lg"
      fullWidth
      disableEnforceFocus
      disableAutoFocus
      disableScrollLock
      BackdropComponent={() => null}
      style={{ transform: `translate(${dialogPosition.x}px, ${dialogPosition.y}px)` }}
    >
      <DragIcon onMouseDown={onMouseDownDialog}>
        <Tooltip title={t('moveScreen')}>
          <FontAwesomeIcon icon={faArrowsAlt} />
        </Tooltip>
      </DragIcon>
      <CloseIcon onClick={closeDialog}>
        <Tooltip title={t('close')}>
          <FontAwesomeIcon icon={faCircleXmark} />
        </Tooltip>
      </CloseIcon>
      <DialogImage
        src={image}
        alt="id"
        style={{
          transform: `rotate(${rotation}deg) scale(${scale}) translate(${position.x}px, ${position.y}px)`,
        }}
        onMouseDown={onMouseDown}
      />
      <TransformIcons>
        <Tooltip title={t('zoomIn')}>
          <IconButton onClick={zoomIn} size="small">
            <FontAwesomeIcon icon={faSearchPlus} />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('zoomOut')}>
          <IconButton onClick={zoomOut} size="small">
            <FontAwesomeIcon icon={faSearchMinus} />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('undo')}>
          <IconButton onClick={rotateLeft} size="small">
            <FontAwesomeIcon icon={faUndo} />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('redo')}>
          <IconButton onClick={rotateRight} size="small">
            <FontAwesomeIcon icon={faRedo} />
          </IconButton>
        </Tooltip>
      </TransformIcons>
    </StyledDialog>
  );
};

export default ImageDialog;
