import { memo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  ConcludeInvestigationRequestSchema,
  EConcludeInvestigationBadRequestTypeSchema,
  getGetAllPlayingBreaksQueryKey,
  getGetPlayingBreakQueryKey,
  getGetUserGeneralQueryKey,
  useConcludeInvestigation,
} from '@greenisland-api';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  capitalize,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import { PLAYING_BREAK_INVESTIGATION_ERROR_REASONS } from '../Constants/PlayingBreakErrorReasons';
import DateTimeField from '@greenisland-common/components/molecules/DateTimeInput';

import { DATETIME_INPUT_FORMAT } from '@greenisland-common/components/atoms/Constants';
import { getUnixTime, } from 'date-fns';

export type CancelType = 'normal' | 'force';

interface AwardDialogProps {
  openDialog: boolean;
  setOpenDialog: (state: boolean) => void;
  userId: number;
}

const CancelInvestigationPlayingBreakDialog = memo(({ openDialog, setOpenDialog, userId }: AwardDialogProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const methods = useForm<ConcludeInvestigationRequestSchema>({
    defaultValues: {
      endDate: null,
      suspension: false,
      reason: '',
    },
  });
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  const cooldownValues = [
    {
      value: 0,
      label: t('settings.playingBreak.suspensionFalse'),
    },
    {
      value: 1,
      label: t('settings.playingBreak.suspensionTrue'),
    },
  ];

  const { mutate: concludeInvestigationMutation, isLoading } = useConcludeInvestigation({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetPlayingBreakQueryKey(userId));
        queryClient.invalidateQueries(getGetAllPlayingBreaksQueryKey());
        queryClient.invalidateQueries(getGetUserGeneralQueryKey(userId.toString()));
        setOpenDialog(false);
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: error => {
        if (error) {
          if ('type' in error && error.type) {
            const errorReason: EConcludeInvestigationBadRequestTypeSchema = error.type;
            const translationKey = errorReason ? PLAYING_BREAK_INVESTIGATION_ERROR_REASONS[errorReason] : 'somethingWentWrong';
            enqueueSnackbar(t(translationKey), { variant: 'error' });
          } else {
            enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
          }
        }
      },
    },
  });

  const onSubmit = (data: ConcludeInvestigationRequestSchema) => {
    concludeInvestigationMutation({ data: {
      ...data,
      suspension: !!data.suspension,
      endDate: data?.endDate ? getUnixTime(data.endDate) : null
    }, userId });
  };

  return (
    <Dialog fullWidth open={openDialog} onClose={() => setOpenDialog(false)}>
      <DialogTitle>{capitalize(t('settings.playingBreak.concludeInvestigation'))}</DialogTitle>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Stack direction="column" gap={1}>
              <DateTimeField
                name="endDate"
                label={t('expiryDate')}
                inputFormat={DATETIME_INPUT_FORMAT}
                defaultValue={null}
              />
              <Controller
                name="suspension"
                rules={{ required: t('fieldIsRequired') }}
                control={control}
                render={({ onChange, value }) => (
                  <TextField
                    select
                    fullWidth
                    label={t('settings.playingBreak.suspension')}
                    value={value}
                    onChange={onChange}
                    error={Boolean(errors['suspension'])}
                    helperText={errors['suspension']?.message}
                  >
                    {cooldownValues.map(({ value, label }) => (
                      <MenuItem key={value.toString()} value={value}>
                        {t(label)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <TextField
                name="reason"
                label={t('reason')}
                error={Boolean(errors['reason'])}
                helperText={errors['reason']?.message}
                inputRef={register({ required: t('fieldIsRequired') })}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="error" onClick={() => setOpenDialog(false)}>
              {t('cancel')}
            </Button>
            <LoadingButton variant="contained" color="error" type="submit" disabled={isLoading} loading={isLoading}>
              {t('proceed')}
            </LoadingButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
});

export default CancelInvestigationPlayingBreakDialog;
