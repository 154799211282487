import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, capitalize, Stack, SxProps, Typography } from '@mui/material';

import SelectDropdownButton from '@greenisland-common/components/organisms/SelectDropdownButton';

import LoyaltyDependentValueDecimal from './LoyaltyDependentValueDecimal';

export enum LoyaltyDependentType {
  DECIMAL = 'DECIMAL',
  QUANTITY = 'QUANTITY',
}

export enum LoyaltyDependentIconType {
  EURO = 'EURO',
  PERCENTAGE = 'PERCENTAGE',
  BONUS = 'BONUS',
}

interface Props {
  title?: string;
  type: LoyaltyDependentType;
  options: {
    label: string;
    name: string;
  }[];
  disabled?: boolean;
  showSelectOptions?: boolean;
  iconType?: LoyaltyDependentIconType;
  isRequired?: boolean;
  sx?: SxProps;
}

const LoyaltyDependentValue = ({
  title,
  type,
  options,
  disabled = false,
  showSelectOptions = true,
  iconType = LoyaltyDependentIconType.EURO,
  isRequired = true,
  sx,
}: Props) => {
  const { t } = useTranslation();
  const loyaltyDependentValues = [
    { value: LoyaltyDependentType.DECIMAL, label: t('marketing.loyaltyDependent.type.decimal') },
  ];
  const [chosenLoyaltyDependentValue, setChosenLoyaltyDependentValue] = useState(loyaltyDependentValues[0]);

  const renderLoyaltyDependentTypeHandler = useMemo(() => {
    switch (type) {
      case LoyaltyDependentType.DECIMAL:
        return <LoyaltyDependentValueDecimal options={options} iconType={iconType} isRequired={isRequired} />;
      case LoyaltyDependentType.QUANTITY:
        return <LoyaltyDependentValueDecimal options={options} iconType={undefined} isRequired={isRequired} />;
      default:
        return <LoyaltyDependentValueDecimal options={options} iconType={iconType} isRequired={isRequired} />;
    }
  }, [iconType, isRequired, options, type]);

  return (
    <Stack sx={{ ...sx }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} mt={1}>
        {title && (
          <Typography variant="body2" fontSize="medium" fontWeight={500}>
            {capitalize(title)}
          </Typography>
        )}
        {showSelectOptions && (
          <SelectDropdownButton
            chosenValue={chosenLoyaltyDependentValue}
            options={loyaltyDependentValues}
            onChange={setChosenLoyaltyDependentValue}
            disabled={disabled}
          />
        )}
      </Box>
      {renderLoyaltyDependentTypeHandler}
    </Stack>
  );
};

export default LoyaltyDependentValue;
