import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { Box, capitalize, Collapse, Divider, ListItem, ListItemText, Stack, Switch } from '@mui/material';

interface Props {
  enabled: boolean;
  name: string;
  listItemText: string;
}

const IndividualActionFormWrapper: FC<Props> = ({ enabled, name, listItemText, children }) => {
  return (
    <Box style={{ marginTop: 0, marginBottom: enabled ? 20 : 0 }}>
      <Divider />
      <Box sx={enabled ? { borderLeft: 2, borderColor: 'primary.main' } : undefined}>
        <ListItem>
          <ListItemText
            primary={capitalize(listItemText)}
            primaryTypographyProps={{ fontWeight: enabled ? 'bolder' : 'normal' }}
          />
          <Controller
            name={name}
            render={({ onChange, value }) => (
              <Switch edge="end" onChange={event => onChange(event.target.checked)} checked={value} />
            )}
          />
        </ListItem>
        <Collapse in={enabled}>
          <Stack spacing={2} m={2}>
            {children}
          </Stack>
        </Collapse>
      </Box>
    </Box>
  );
};

export default IndividualActionFormWrapper;
