import { ChangeEvent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  EMediaGalleryEntryTypeSchema,
  ImageSchema,
  IndividualActionSchema,
  UpdateIndividualActionSchema,
} from '@greenisland-api';
import { capitalize, Checkbox, FormControlLabel, Typography } from '@mui/material';

import DateTimeInput from '@greenisland-common/components/molecules/DateTimeInput/DateTimeInput';
import Input from '@greenisland-common/components/molecules/Input';
import { MediaFieldsWithPreview } from '@greenisland-common/components/molecules/MediaField';
import SelectInputDurationField from '@greenisland-common/components/organisms/SelectInputDurationField';

import { ContentFilterButton } from '../../../../Content/ContentFilter/components';
import { LoyaltyDependentIconType } from '../../../Components/LoyaltyDependentValue/LoyaltyDependentValue';
import {
  BonusLoyaltyDependentTypeEnum,
  getDefaultIndividualActionNumberInputProps,
  useBonusDepositLoyaltyDependentOptions,
  useDurationValues,
} from '../helpers';
import IndividualActionFormWrapper from '../IndividualActionFormWrapper';
import IndividualActionBonusLoyaltyDependantSection from './IndividualActionBonusLoyaltyDependantSection';

const DEPOSIT_LOYALTY_DEPENDANT_PERCENTAGE_KEY = 'depositBonus.loyaltyDependentPercentage';
const DEPOSIT_LOYALTY_DEPENDANT_LIMIT_KEY = 'depositBonus.loyaltyDependentLimit';
const DEPOSIT_LOYALTY_DEPENDANT_PLAYTHROUGH_KEY = 'depositBonus.loyaltyDependentPlaythrough';

interface Props {
  individualAction?: IndividualActionSchema;
}

const IndividualActionDepositBonusForm = ({ individualAction }: Props) => {
  const { t } = useTranslation();
  const durationOptions = useDurationValues();
  const percentageOptions = useBonusDepositLoyaltyDependentOptions(
    BonusLoyaltyDependentTypeEnum.PERCENTAGE,
    DEPOSIT_LOYALTY_DEPENDANT_PERCENTAGE_KEY
  );
  const limitOptions = useBonusDepositLoyaltyDependentOptions(
    BonusLoyaltyDependentTypeEnum.LIMIT,
    DEPOSIT_LOYALTY_DEPENDANT_LIMIT_KEY
  );
  const playthroughOptions = useBonusDepositLoyaltyDependentOptions(
    BonusLoyaltyDependentTypeEnum.PLAYTHROUGH,
    DEPOSIT_LOYALTY_DEPENDANT_PLAYTHROUGH_KEY
  );

  const { control, register, watch, setValue, errors } = useFormContext<UpdateIndividualActionSchema>();

  const isDepositBonusEnabled = !!watch('depositBonus.enabled');
  const isLoyaltyDependentPercentageEnabled = !!watch('depositBonus.isLoyaltyDependentPercentageEnabled');
  const isLoyaltyDependentLimitEnabled = !!watch('depositBonus.isLoyaltyDependentLimitEnabled');
  const isLoyaltyDependentPlaythroughEnabled = !!watch('depositBonus.isLoyaltyDependentPlaythroughEnabled');
  const watchAbsoluteExpiryDate = !!watch('depositBonus.absoluteExpiryDate');

  const watchDepositBonusImages: ImageSchema[] | null = watch('depositBonus.images');

  const [isAbsoluteExpiryDate, setIsAbsoluteExpiryDate] = useState(watchAbsoluteExpiryDate);
  const [expiraryDurationSelectOption, setExpiraryDurationSelectOption] = useState(durationOptions.SECONDS);
  const [expiraryDurationDepositSelectOption, setExpiraryDurationSepositSelectOption] = useState(
    durationOptions.SECONDS
  );

  useEffect(() => {
    if (!isAbsoluteExpiryDate) {
      setValue('depositBonus.absoluteExpiryDate', null);
    }
  }, [isAbsoluteExpiryDate, setValue]);

  useEffect(() => {
    if (isDepositBonusEnabled) {
      if (expiraryDurationSelectOption) {
        setValue('depositBonus.expiryDurationSelectOption', expiraryDurationSelectOption.value);
      }
      if (expiraryDurationDepositSelectOption) {
        setValue('depositBonus.expiryDurationDepositSelectOption', expiraryDurationDepositSelectOption.value);
      }
    }
  }, [expiraryDurationDepositSelectOption, expiraryDurationSelectOption, isDepositBonusEnabled, register, setValue]);

  useEffect(() => {
    if (watchDepositBonusImages) {
      const imagesIds = watchDepositBonusImages?.map(image => image?.id);
      setValue('depositBonus.imageIds', imagesIds?.length ? imagesIds : null);
    }
  }, [setValue, watchDepositBonusImages]);

  const handleLoyaltyDependentChange = (event: ChangeEvent<HTMLInputElement>, type: BonusLoyaltyDependentTypeEnum) => {
    if (type === BonusLoyaltyDependentTypeEnum.PERCENTAGE) {
      setValue('depositBonus.isLoyaltyDependentPercentageEnabled', event.target.checked);
    } else if (type === BonusLoyaltyDependentTypeEnum.LIMIT) {
      setValue('depositBonus.isLoyaltyDependentLimitEnabled', event.target.checked);
    } else {
      setValue('depositBonus.isLoyaltyDependentPlaythroughEnabled', event.target.checked);
    }
  };

  return (
    <IndividualActionFormWrapper
      enabled={isDepositBonusEnabled}
      name="depositBonus.enabled"
      listItemText={t('depositBonus.mainTitle')}
    >
      <Input
        type="text"
        size="small"
        name="depositBonus.name"
        label={capitalize(t('depositBonus.fields.name'))}
        inputRef={register({ required: isDepositBonusEnabled, maxLength: 256 })}
        disabled={!isDepositBonusEnabled}
        required={isDepositBonusEnabled}
        error={Boolean(errors?.depositBonus?.name)}
        helperText={
          errors?.depositBonus?.name
            ? capitalize(t('depositBonus.helpText.name.error'))
            : capitalize(t('depositBonus.helpText.name.default'))
        }
        fullWidth={true}
        defaultValue={individualAction?.depositBonus?.name}
      />
      <Typography fontWeight={600}>{capitalize(t('depositBonus.subtitle.bonusSettings'))}</Typography>
      <IndividualActionBonusLoyaltyDependantSection
        enabledDeposit={isDepositBonusEnabled}
        name="depositBonus.loyaltyDependentPercentageSingleValue"
        isExtendedLoyaltyDependentMode={isLoyaltyDependentPercentageEnabled}
        loyaltyDependantType={BonusLoyaltyDependentTypeEnum.PERCENTAGE}
        mainLabel={t('depositBonus.fields.percentageIsLoyaltyDependent')}
        inputLabel={t('depositBonus.fields.percentage')}
        handleLoyaltyDependentChange={handleLoyaltyDependentChange}
        iconType={LoyaltyDependentIconType.PERCENTAGE}
        loyaltyDependentOptions={percentageOptions}
      />
      <IndividualActionBonusLoyaltyDependantSection
        enabledDeposit={isDepositBonusEnabled}
        name="depositBonus.loyaltyDependentLimitSingleValue"
        isExtendedLoyaltyDependentMode={isLoyaltyDependentLimitEnabled}
        loyaltyDependantType={BonusLoyaltyDependentTypeEnum.LIMIT}
        mainLabel={t('depositBonus.fields.limitIsLoyaltyDependent')}
        inputLabel={t('depositBonus.fields.limit')}
        handleLoyaltyDependentChange={handleLoyaltyDependentChange}
        iconType={LoyaltyDependentIconType.EURO}
        loyaltyDependentOptions={limitOptions}
      />
      <IndividualActionBonusLoyaltyDependantSection
        enabledDeposit={isDepositBonusEnabled}
        name="depositBonus.loyaltyDependentPlaythroughSingleValue"
        isExtendedLoyaltyDependentMode={isLoyaltyDependentPlaythroughEnabled}
        loyaltyDependantType={BonusLoyaltyDependentTypeEnum.PLAYTHROUGH}
        mainLabel={t('depositBonus.fields.playthroughIsLoyaltyDependent')}
        inputLabel={t('depositBonus.fields.playthrough')}
        handleLoyaltyDependentChange={handleLoyaltyDependentChange}
        iconType={LoyaltyDependentIconType.BONUS}
        loyaltyDependentOptions={playthroughOptions}
      />
      <Typography fontWeight={600}>{capitalize(t('depositBonus.subtitle.contentSettings'))}</Typography>
      <ContentFilterButton control={control} name="depositBonus.contentFilterId" />
      <Typography fontWeight={600}>{capitalize(t('depositBonus.subtitle.imageSettings'))}</Typography>
      <MediaFieldsWithPreview
        control={control}
        size="small"
        name="depositBonus.images"
        error={Boolean(errors?.depositBonus?.imageIds)}
        type="text"
        required={false}
        label={capitalize(t('addImages'))}
        mediaGalleryEntryType={EMediaGalleryEntryTypeSchema.BonusImage}
        isMultiSelect={true}
      />
      <Typography fontWeight={600}>{capitalize(t('depositBonus.subtitle.timeSettings'))}</Typography>
      <SelectInputDurationField
        size="small"
        name="depositBonus.expiryDuration"
        label={capitalize(t('depositBonus.fields.depositExpirationDuration'))}
        chosenValue={expiraryDurationSelectOption}
        options={Object.values(durationOptions)}
        onChange={setExpiraryDurationSelectOption}
        required={isDepositBonusEnabled}
        disabled={!isDepositBonusEnabled}
        helperText={capitalize(t('depositBonus.helpText.depositExpirationDuration.default'))}
        InputProps={getDefaultIndividualActionNumberInputProps()}
        inputRef={register({
          required: isDepositBonusEnabled,
          setValueAs: value => (value ? Number(value) : 0),
        })}
        error={Boolean(errors?.depositBonus?.expiryDuration)}
        sx={{
          '.MuiInputBase-root': {
            paddingRight: '2px',
          },
        }}
      />
      <SelectInputDurationField
        size="small"
        name="depositBonus.expiryDurationDeposit"
        label={capitalize(t('depositBonus.fields.playthroughExpirationDuration'))}
        chosenValue={expiraryDurationDepositSelectOption}
        options={Object.values(durationOptions)}
        onChange={setExpiraryDurationSepositSelectOption}
        required={isDepositBonusEnabled}
        disabled={!isDepositBonusEnabled}
        helperText={capitalize(t('depositBonus.helpText.playthroughExpirationDuration.default'))}
        InputProps={getDefaultIndividualActionNumberInputProps()}
        inputRef={register({
          required: isDepositBonusEnabled,
          setValueAs: value => (value ? Number(value) : 0),
        })}
        error={Boolean(errors?.depositBonus?.expiryDurationDeposit)}
        sx={{
          '.MuiInputBase-root': {
            paddingRight: '2px',
          },
        }}
      />
      <FormControlLabel
        control={<Checkbox color="primary" size="small" />}
        onChange={(_, value) => setIsAbsoluteExpiryDate(value)}
        label={t('depositBonus.fields.addAbsoluteExpiryDate')}
        checked={isAbsoluteExpiryDate}
        sx={{
          width: 'fit-content',
          '.MuiTypography-root': { fontWeight: isAbsoluteExpiryDate ? 600 : 400 },
        }}
      />
      {isAbsoluteExpiryDate && (
        <DateTimeInput
          name="depositBonus.absoluteExpiryDate"
          label={capitalize(t('depositBonus.fields.absoluteExpiryDate'))}
          disabled={!isDepositBonusEnabled}
          required={isAbsoluteExpiryDate}
        />
      )}
    </IndividualActionFormWrapper>
  );
};

export default IndividualActionDepositBonusForm;
