import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Typography } from '@mui/material';

import { usePermission } from 'src/app/hooks';

import { PlayingBreakRequestType } from './PlayinBreakFormContext';
import PlayingBreakDialog from './PlayingBreakDialog';

interface Props {
  userId?: number;
}

const PlayingBreakRequestAction = ({ userId }: Props) => {
  const { t } = useTranslation();
  const canCancelPlayingBreak = usePermission(OnlineCasinoPermissions.cancelPlayingBreakV2);
  const canDeclinePlayingBreak = usePermission(OnlineCasinoPermissions.declinePlayingBreakCancellationRequest);

  const [playingBreakRequestType, setPlayingBreakRequestType] = useState<PlayingBreakRequestType | null>(null);

  if (!userId) {
    return (
      <Typography color={'error'} variant="caption">
        {t('regulator.InvalidUserId')}
      </Typography>
    );
  }

  return (
    <>
      <Box sx={{ display: 'flex', gap: 1, pt: 2 }}>
        {canCancelPlayingBreak && (
          <Button
            variant="contained"
            size="small"
            onClick={() => setPlayingBreakRequestType(PlayingBreakRequestType.CANCEL)}
          >
            {t('playingbreak.cancel')}
          </Button>
        )}
        {canDeclinePlayingBreak && (
          <Button
            color="error"
            variant="contained"
            size="small"
            onClick={() => setPlayingBreakRequestType(PlayingBreakRequestType.REMOVE)}
          >
            {t('playingbreak.removeCancelRequest')}
          </Button>
        )}
      </Box>
      <PlayingBreakDialog
        userId={userId}
        playingBreakRequestType={playingBreakRequestType}
        onClose={() => setPlayingBreakRequestType(null)}
      />
    </>
  );
};

export default PlayingBreakRequestAction;
