import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetWithdrawalsExportFile, useGetWithdrawalsExports, useProcessWithdrawalsExport } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Download as DownloadIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';

import { StyledDataGrid } from '@greenisland-common/components/atoms';

import { downloadBase64File } from '@greenisland-common/helpers';

import {
  transformDecimalV2,
  transformStatusV2,
  transformUnixDateV2,
} from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';

type DownloadButtonProps = {
  withdrawalId: string;
};

const DownloadButton = ({ withdrawalId }: DownloadButtonProps) => {
  const [enabled, setEnabled] = useState(false);
  const { data, isLoading, isSuccess } = useGetWithdrawalsExportFile(withdrawalId, { query: { enabled } });
  const { t } = useTranslation();

  const handleClick = async () => {
    setEnabled(true);
  };

  useEffect(() => {
    if (isSuccess && data) {
      downloadBase64File(data);
    }
  }, [data, isSuccess]);

  return (
    <Tooltip title={t('download')}>
      <Button
        startIcon={<DownloadIcon />}
        onClick={handleClick}
        disabled={isLoading}
        variant="text"
        size="small"
        color="secondary"
      >
        {isLoading ? t('downloading') : t('download')}
      </Button>
    </Tooltip>
  );
};

const WithdrawalsExports = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const canReadWithdrawalsExport = usePermission(OnlineCasinoPermissions.getWithdrawalsExports);
  const canDownloadExport = usePermission(OnlineCasinoPermissions.processWithdrawalsExport);

  const { data: withdrawalsExports, isLoading: isWithdrawalsExportsLoading } = useGetWithdrawalsExports({
    query: { enabled: canReadWithdrawalsExport },
  });

  const { mutate: processWithdrawalsExport, isLoading: isProcessWithdrawalsExportLoading } =
    useProcessWithdrawalsExport({
      mutation: {
        onSuccess: () => {
          enqueueSnackbar(t('success'), { variant: 'success' });
        },
        onError: error => {
          enqueueSnackbar(error?.message || t('somethingWentWrong'), { variant: 'error' });
        },
      },
    });

  const columns: GridColDef[] = [
    {
      ...transformUnixDateV2('createdOn', t),
      flex: 1,
    },
    {
      ...transformDecimalV2('nrOfTransactions', t),
      flex: 0.5,
    },
    {
      ...transformStatusV2('status', t),
      flex: 0.5,
    },
    {
      field: 'process',
      headerName: t('process'),
      flex: 1,
      renderCell: params =>
        params.row.status === 'New' ? (
          <LoadingButton
            size="small"
            onClick={() => processWithdrawalsExport({ data: { exportId: params.row.exportId } })}
            loading={isProcessWithdrawalsExportLoading}
          >
            {t('process')}
          </LoadingButton>
        ) : null,
    },
    {
      field: 'download',
      flex: 1,
      headerName: t('download'),
      renderCell: params => (canDownloadExport ? <DownloadButton withdrawalId={params.row.exportId} /> : null),
    },
  ];

  return (
    <StyledDataGrid
      rows={withdrawalsExports || []}
      columns={columns}
      loading={isWithdrawalsExportsLoading}
      getRowId={row => row.exportId}
      disableSelectionOnClick
    />
  );
};

export default memo(WithdrawalsExports);
