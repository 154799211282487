import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IndividualActionSchema, UpdateIndividualActionSchema } from '@greenisland-api';
import { capitalize } from '@mui/material';

import Input from '@greenisland-common/components/molecules/Input';

import { getDefaultIndividualActionNumberInputProps } from '../helpers';
import IndividualActionFormWrapper from '../IndividualActionFormWrapper';

interface Props {
  individualAction?: IndividualActionSchema;
}

const IndividualActionFreeCashForm = ({ individualAction }: Props) => {
  const { t } = useTranslation();
  const { register, watch, errors } = useFormContext<UpdateIndividualActionSchema>();

  const isFreeCashEnabled = !!watch('freeCash.enabled');

  return (
    <IndividualActionFormWrapper enabled={isFreeCashEnabled} name="freeCash.enabled" listItemText={t('freeCash')}>
      <Input
        size="small"
        name="freeCash.amount"
        label={capitalize(t('amount'))}
        inputRef={register({
          required: isFreeCashEnabled,
          setValueAs: value => (value ? Number(value) : 1),
        })}
        required={isFreeCashEnabled}
        InputProps={getDefaultIndividualActionNumberInputProps(1)}
        disabled={!isFreeCashEnabled}
        error={Boolean(errors?.freeCash?.amount)}
        helperText={errors?.freeCash?.amount?.message}
        fullWidth={true}
        defaultValue={individualAction?.freeCash?.amount}
      />
    </IndividualActionFormWrapper>
  );
};

export default IndividualActionFreeCashForm;
