/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

/**
 * Conclude investigation bad request type:
 * `invalidUserId` - Requested user ID is invalid.
 * `endDateInPast` - The chosen suspension end date is in the past.
 * `noActiveInvestigation` - There is no active investigation to conclude.
 * `noReasonSpecified` - There is no reason specified in the request.

 */
export type EConcludeInvestigationBadRequestTypeSchema =
  (typeof EConcludeInvestigationBadRequestTypeSchema)[keyof typeof EConcludeInvestigationBadRequestTypeSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EConcludeInvestigationBadRequestTypeSchema = {
  invalidUserId: 'invalidUserId',
  endDateInPast: 'endDateInPast',
  noActiveInvestigation: 'noActiveInvestigation',
  noReasonSpecified: 'noReasonSpecified',
} as const;
