import { ChangeEvent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  EMediaGalleryEntryTypeSchema,
  ImageSchema,
  IndividualActionSchema,
  UpdateIndividualActionSchema,
} from '@greenisland-api';
import { capitalize, Typography } from '@mui/material';

import Input from '@greenisland-common/components/molecules/Input';
import { MediaFieldsWithPreview } from '@greenisland-common/components/molecules/MediaField';
import SelectInputDurationField from '@greenisland-common/components/organisms/SelectInputDurationField';

import { ContentFilterButton } from '../../../../Content/ContentFilter/components';
import { LoyaltyDependentIconType } from '../../../Components/LoyaltyDependentValue/LoyaltyDependentValue';
import {
  BonusLoyaltyDependentTypeEnum,
  getDefaultIndividualActionNumberInputProps,
  useBonusDepositLoyaltyDependentOptions,
  useDurationValues,
} from '../helpers';
import IndividualActionFormWrapper from '../IndividualActionFormWrapper';
import IndividualActionBonusLoyaltyDependantSection from './IndividualActionBonusLoyaltyDependantSection';

const PLAYTHROUGH_BONUS_LOYALTY_DEPENDANT_KEY = 'playthroughBonus.loyaltyDependentAmount';
const PLAYTHROUGH_BONUS_LOYALTY_DEPENDANT_PLAYTHROUGH_KEY = 'playthroughBonus.loyaltyDependentPlaythrough';

interface Props {
  individualAction?: IndividualActionSchema;
}

const IndividualActionPlaythroughBonusForm = ({ individualAction }: Props) => {
  const { t } = useTranslation();
  const durationOptions = useDurationValues();
  const loyaltyDependentOptions = useBonusDepositLoyaltyDependentOptions(
    BonusLoyaltyDependentTypeEnum.LIMIT,
    PLAYTHROUGH_BONUS_LOYALTY_DEPENDANT_KEY
  );
  const playthroughOptions = useBonusDepositLoyaltyDependentOptions(
    BonusLoyaltyDependentTypeEnum.PLAYTHROUGH,
    PLAYTHROUGH_BONUS_LOYALTY_DEPENDANT_PLAYTHROUGH_KEY
  );

  const { control, register, watch, setValue, errors } = useFormContext<UpdateIndividualActionSchema>();

  const isPlaythroughBonusEnabled = !!watch('playthroughBonus.enabled');
  const isLoyaltyDependentAmountEnabled = !!watch('playthroughBonus.isLoyaltyDependentAmountEnabled');
  const isLoyaltyDependentPlaythroughEnabled = !!watch('playthroughBonus.isLoyaltyDependentPlaythroughEnabled');
  const watchPlaythroughBonusImages: ImageSchema[] | null = watch('playthroughBonus.images');

  const [playthroughExpiraryDurationSelectOption, setPlaythroughExpiraryDurationSelectOption] = useState(
    durationOptions.SECONDS
  );

  useEffect(() => {
    if (isPlaythroughBonusEnabled && playthroughExpiraryDurationSelectOption) {
      setValue('playthroughBonus.expiryDurationSelectOption', playthroughExpiraryDurationSelectOption.value);
    }
  }, [isPlaythroughBonusEnabled, playthroughExpiraryDurationSelectOption, register, setValue]);

  useEffect(() => {
    if (watchPlaythroughBonusImages) {
      const imagesIds = watchPlaythroughBonusImages?.map(image => image?.id);
      setValue('playthroughBonus.imageIds', imagesIds?.length ? imagesIds : null);
    }
  }, [setValue, watchPlaythroughBonusImages]);

  const handleLoyaltyDependentChange = (event: ChangeEvent<HTMLInputElement>, type: BonusLoyaltyDependentTypeEnum) => {
    if (type === BonusLoyaltyDependentTypeEnum.LIMIT) {
      setValue('playthroughBonus.isLoyaltyDependentAmountEnabled', event.target.checked);
    } else {
      setValue('playthroughBonus.isLoyaltyDependentPlaythroughEnabled', event.target.checked);
    }
  };

  return (
    <IndividualActionFormWrapper
      enabled={isPlaythroughBonusEnabled}
      name="playthroughBonus.enabled"
      listItemText={t('playthroughBonus.mainTitle')}
    >
      <Input
        type="text"
        size="small"
        name="playthroughBonus.name"
        label={capitalize(t('playthroughBonus.fields.name'))}
        inputRef={register({
          required: isPlaythroughBonusEnabled,
          maxLength: 256,
        })}
        required={isPlaythroughBonusEnabled}
        disabled={!isPlaythroughBonusEnabled}
        error={Boolean(errors?.playthroughBonus?.name)}
        helperText={
          errors?.playthroughBonus?.name
            ? capitalize(t('playthroughBonus.helpText.name.error'))
            : capitalize(t('playthroughBonus.helpText.name.default'))
        }
        fullWidth={true}
        defaultValue={individualAction?.playthroughBonus?.name}
      />
      <Typography fontWeight={600}>{capitalize(t('playthroughBonus.subtitle.bonusSettings'))}</Typography>
      <IndividualActionBonusLoyaltyDependantSection
        enabledDeposit={isPlaythroughBonusEnabled}
        name="playthroughBonus.loyaltyDependentSingleValue"
        isExtendedLoyaltyDependentMode={isLoyaltyDependentAmountEnabled}
        loyaltyDependantType={BonusLoyaltyDependentTypeEnum.LIMIT}
        mainLabel={t('playthroughBonus.fields.amountIsLoyaltyDependent')}
        inputLabel={t('playthroughBonus.fields.amount')}
        handleLoyaltyDependentChange={handleLoyaltyDependentChange}
        iconType={LoyaltyDependentIconType.EURO}
        loyaltyDependentOptions={loyaltyDependentOptions}
      />
      <IndividualActionBonusLoyaltyDependantSection
        enabledDeposit={isPlaythroughBonusEnabled}
        name="playthroughBonus.loyaltyDependentPlaythroughSingleValue"
        isExtendedLoyaltyDependentMode={isLoyaltyDependentPlaythroughEnabled}
        loyaltyDependantType={BonusLoyaltyDependentTypeEnum.PLAYTHROUGH}
        mainLabel={t('depositBonus.fields.playthroughIsLoyaltyDependent')}
        inputLabel={t('depositBonus.fields.playthrough')}
        handleLoyaltyDependentChange={handleLoyaltyDependentChange}
        iconType={LoyaltyDependentIconType.BONUS}
        loyaltyDependentOptions={playthroughOptions}
      />
      <Typography fontWeight={600}>{capitalize(t('playthroughBonus.subtitle.contentSettings'))}</Typography>
      <ContentFilterButton control={control} name="playthroughBonus.contentFilterId" />
      <Typography fontWeight={600}>{capitalize(t('playthroughBonus.subtitle.imageSettings'))}</Typography>
      <MediaFieldsWithPreview
        control={control}
        size="small"
        name="playthroughBonus.images"
        error={Boolean(errors?.playthroughBonus?.imageIds)}
        type="text"
        required={false}
        label={capitalize(t('addImages'))}
        mediaGalleryEntryType={EMediaGalleryEntryTypeSchema.BonusImage}
        isMultiSelect={true}
      />
      <Typography fontWeight={600}>{capitalize(t('playthroughBonus.subtitle.timeSettings'))}</Typography>
      <SelectInputDurationField
        size="small"
        name="playthroughBonus.expiryDuration"
        label={capitalize(t('playthroughBonus.fields.expirationDuration'))}
        chosenValue={playthroughExpiraryDurationSelectOption}
        options={Object.values(durationOptions)}
        onChange={setPlaythroughExpiraryDurationSelectOption}
        disabled={!isPlaythroughBonusEnabled}
        required={isPlaythroughBonusEnabled}
        helperText={capitalize(t('playthroughBonus.helpText.expirationDuration.default'))}
        InputProps={getDefaultIndividualActionNumberInputProps()}
        inputRef={register({
          required: isPlaythroughBonusEnabled,
          setValueAs: value => (value ? Number(value) : 0),
        })}
        error={Boolean(errors?.playthroughBonus?.expiryDuration)}
        sx={{
          '.MuiInputBase-root': {
            paddingRight: '2px',
          },
        }}
      />
    </IndividualActionFormWrapper>
  );
};

export default IndividualActionPlaythroughBonusForm;
