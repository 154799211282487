import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EVerificationStatusSchema, EWithdrawalTypeSchema, WithdrawalsSchema } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import {
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridColumns,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import * as ibanTools from 'ibantools';

import { Link } from '@greenisland-common/components/atoms';

import { formatCurrency, formatDateTime } from '@greenisland-common/helpers';

import { usePermission } from '../../../../../app/hooks';
import { LOOKUP_PERMISSIONS } from '../../../../../pages/OnlineCasino/Lookup/LookupPermissions';
import { Row } from '../GeneralWithdrawal';
import ActionCell from '../WithdrawTable/ActionCell';
import FlagDataCell from '../WithdrawTable/FlagDataCell';
import NetDepositCell from '../WithdrawTable/NetDepositCell';
import VerificationCell from '../WithdrawTable/VerificationCell';

const useGetGeneralWithdrawalHeaders = (
  withdrawals: WithdrawalsSchema,
  destinationName: EWithdrawalTypeSchema,
  columnOrderPreferences: string[] | undefined,
  orderDefault: string[],
  orderCasino: string[]
) => {
  const { t, i18n } = useTranslation();

  const canLookupUser = usePermission(LOOKUP_PERMISSIONS, { strict: false });
  const canReadRank = usePermission(OnlineCasinoPermissions.getLoyaltyRankUsers);

  const columns = useMemo<GridColumns<Row>>(() => {
    if (!withdrawals || withdrawals.length === 0) return [];

    const order = destinationName !== EWithdrawalTypeSchema.casino ? orderDefault : orderCasino;
    const keys = columnOrderPreferences
      ? columnOrderPreferences
      : Object.keys(withdrawals[0]).sort(function (a, b) {
          return order.indexOf(b) - order.indexOf(a);
        });

    const dataHeaders = keys.map(key => {
      switch (key) {
        case 'flagReason':
          return {
            headerName: t(key),
            field: key,
            filter: 'select',
            type: 'singleSelect',
            valueOptions: [...new Set(withdrawals.map(w => w.flagReason))],
            minWidth: 180,
          };
        case 'flagData':
          return {
            headerName: t(key),
            field: key,
            renderCell: ({ row }: GridRenderCellParams<string, Row>) => {
              return <FlagDataCell id={row.id!} data={row.flagData?.data} />;
            },
            minWidth: 250,
            type: 'string',
          };
        case 'username':
          return {
            headerName: t(key),
            field: key,
            renderCell: ({ row }: GridRenderCellParams<string, Row>) => {
              const userId = row.userId;
              const username = row.username;
              return canLookupUser ? (
                <Link to={`../../../users/${userId}/details`} underline="none">
                  {username}
                </Link>
              ) : (
                <span>{username}</span>
              );
            },
            minWidth: 180,
          };
        case 'userLoyalty':
          return {
            headerName: t(key),
            field: key,
            renderCell: ({ value }: GridRenderCellParams<string, Row>) =>
              canReadRank ? (
                <Link to={`../../../loyalty/ranks/${value}`} underline="none">
                  {value}
                </Link>
              ) : (
                <span>{value}</span>
              ),
            minWidth: 180,
          };
        case 'netDeposit':
          return {
            headerName: t(key),
            type: 'number',
            field: key,
            renderCell: (params: GridRenderCellParams<number, Row>) => <NetDepositCell value={params.value} />,
            minWidth: 180,
          };
        case 'bankAccountVerification':
        case 'identityVerification':
        case 'domicileVerification':
        case 'walletVerification':
          return {
            headerName: t(key),
            field: key,
            renderCell: (params: GridRenderCellParams<EVerificationStatusSchema>) => (
              <VerificationCell value={params.value} />
            ),
            type: 'singleSelect',
            valueOptions: [...new Set(withdrawals.map(w => w[key]))],
            minWidth: 180,
          };
        case 'amountOfWithdrawal':
        case 'balanceAfterWithdrawal':
          return {
            headerName: t(key),
            field: key,
            valueFormatter: ({ value }: GridValueFormatterParams<number>) => formatCurrency(value, i18n.language),
            type: 'number',
            minWidth: 180,
          };
        case 'destination':
          switch (destinationName) {
            case EWithdrawalTypeSchema.bankaccount:
            case EWithdrawalTypeSchema.truelayer:
              return {
                headerName: 'iban',
                field: key,
                renderCell: ({ value }: GridRenderCellParams<string, Row>) => (
                  <span style={{ whiteSpace: 'nowrap' }}>{ibanTools.friendlyFormatIBAN(value)}</span>
                ),
                minWidth: 180,
              };
            case EWithdrawalTypeSchema.casino:
              return { headerName: 'casino', field: key, minWidth: 180 };
            default: {
              return { headerName: 'wallet id', field: key, minWidth: 180 };
            }
          }
        case 'requestedOn':
          return {
            headerName: t(key),
            field: key,
            type: 'date',
            valueGetter: ({ value }: GridValueGetterParams<number>) => value && new Date(value * 1000),
            valueFormatter: ({ value }: GridValueFormatterParams<Date>) => formatDateTime(value, false),
            minWidth: 180,
          };
        default:
          return { field: key, headerName: t(key), minWidth: 180 };
      }
    });

    return [
      {
        field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
        hideable: false,
      },
      ...dataHeaders,
      {
        field: 'actions',
        disableColumnMenu: true,
        disableExport: true,
        disableReorder: true,
        filterable: false,
        hideable: false,
        sortable: false,
        resizable: false,
        renderHeader: () => <span></span>,
        renderCell: (params: GridRenderCellParams) => <ActionCell row={params.row} destinationName={destinationName} />,
      },
    ];
  }, [
    canLookupUser,
    canReadRank,
    columnOrderPreferences,
    destinationName,
    i18n.language,
    t,
    withdrawals,
    orderCasino,
    orderDefault,
  ]);

  return { columns };
};

export default useGetGeneralWithdrawalHeaders;
