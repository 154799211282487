import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';

import MarkAsSafeDialog from '../../../../Lookup/Components/DetailsComponents/Deposits/MarkAsSafeWithCredentialsDialog';
import { Link } from 'react-router-dom';

interface Props {
  userId: string;
  depositId: string;
}

const SuspiciousDepositAction = ({ userId, depositId }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Button size="small" color="error" onClick={() => setIsOpen(true)} variant="contained">
        {t('deposits.actions.markAsSafe')}
      </Button>
      <MarkAsSafeDialog userId={userId} isOpen={isOpen} onClose={() => setIsOpen(false)} depositId={depositId} />
      <Button
        component={Link}
        to={`../../users/${userId}/deposits/${depositId}`}
        size="small"
        color="primary"
        variant="contained"
        target="_blank"
      >
        {t('goToDeposit')}
      </Button>
      <Button
        component={Link}
        to={`../../users/${userId}/details`}
        size="small"
        color="primary"
        variant="contained"
        target="_blank"
      >
        {t('lookup.users.titles.goToUserDetails')}
      </Button>
    </Box>
  );
};

export default SuspiciousDepositAction;
