import {
  IndividualDepositBonusActionSchema,
  UpdateBoostActionSchema,
  UpdateIndividualActionSchema,
  UpdatePlaythroughBonusActionSchema,
} from '@greenisland-api';

enum DurationsEnum {
  SECONDS = 'seconds',
  MINUTES = 'minutes',
  HOURS = 'hours',
  DAYS = 'days',
}

enum BonusLoyaltyDependentTypeEnum {
  PERCENTAGE = 'percentage',
  LIMIT = 'limit',
  PLAYTHROUGH = 'playthrough',
}

interface ExtendedPlaythroughBonusSchema extends UpdatePlaythroughBonusActionSchema {
  expiryDurationSelectOption: DurationsEnum;
  isLoyaltyDependentAmountEnabled: boolean;
  loyaltyDependentSingleValue: number;
  isLoyaltyDependentPlaythroughEnabled: boolean;
  loyaltyDependentPlaythroughSingleValue: number;
}

interface ExtendedDepositBonusSchema extends IndividualDepositBonusActionSchema {
  isLoyaltyDependentPercentageEnabled: boolean;
  loyaltyDependentPercentageSingleValue: number;
  isLoyaltyDependentLimitEnabled: boolean;
  loyaltyDependentLimitSingleValue: number;
  isLoyaltyDependentPlaythroughEnabled: boolean;
  loyaltyDependentPlaythroughSingleValue: number;
  expiryDurationSelectOption: DurationsEnum;
  expiryDurationDepositSelectOption: DurationsEnum;
}

interface ExtendedBoostSchema extends UpdateBoostActionSchema {
  durationSelectOption: DurationsEnum;
  expiryDurationDepositSelectOption: DurationsEnum;
  isMaxPayoutEnabled: boolean;
  isEarlyMaxPayoutEnabled: boolean;
}

interface ExtendedUpdateIndividualActionSchema
  extends Omit<UpdateIndividualActionSchema, 'playthroughBonus' | 'depositBonus' | 'boost'> {
  playthroughBonus?: ExtendedPlaythroughBonusSchema;
  depositBonus?: ExtendedDepositBonusSchema;
  boost?: ExtendedBoostSchema;
}

export type {
  ExtendedUpdateIndividualActionSchema,
  ExtendedPlaythroughBonusSchema,
  ExtendedDepositBonusSchema,
  ExtendedBoostSchema,
};

export { DurationsEnum, BonusLoyaltyDependentTypeEnum };
