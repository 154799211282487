import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UpdateIndividualActionSchema } from '@greenisland-api';
import { Typography } from '@mui/material';

import IndividualActionFormWrapper from '../IndividualActionFormWrapper';

const IndividualActionPlainMailForm = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext<UpdateIndividualActionSchema>();

  const isPlainCampaignMailEnabled = !!watch('plainCampaignMail.enabled');

  return (
    <IndividualActionFormWrapper
      enabled={isPlainCampaignMailEnabled}
      name="plainCampaignMail.enabled"
      listItemText={t('plainMail')}
    >
      <Typography variant="body2">{t('noParametersToConfigure')}</Typography>
    </IndividualActionFormWrapper>
  );
};

export default IndividualActionPlainMailForm;
