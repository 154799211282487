import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UpdateIndividualActionSchema } from '@greenisland-api';
import { Typography } from '@mui/material';

import IndividualActionFormWrapper from '../IndividualActionFormWrapper';

const IndividualActionTrustpilotForm = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext<UpdateIndividualActionSchema>();

  const isTrustpilotReviewEmailEnabled = !!watch('trustpilotReviewEmail.enabled');

  return (
    <IndividualActionFormWrapper
      enabled={isTrustpilotReviewEmailEnabled}
      name="trustpilotReviewEmail.enabled"
      listItemText={t('marketing.trustpilot.actions.reviewEmailTitle')}
    >
      <Typography variant="body2">{t('noParametersToConfigure')}</Typography>
    </IndividualActionFormWrapper>
  );
};

export default IndividualActionTrustpilotForm;
