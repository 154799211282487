import { useTranslation } from 'react-i18next';
import { useGetCampaign } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Stack } from '@mui/material';

import { PermissionWrapper } from '@greenisland-common/components/atoms';

import { usePermission } from '../../../../../app/hooks';
import IndividualActions from './IndividualActions';
import Templates from './Templates';

interface Props {
  campaignId: string;
}

const CampaignOverviewDetails = ({ campaignId }: Props) => {
  const { t } = useTranslation();
  const canReadCampaign = usePermission(OnlineCasinoPermissions.getCampaign);

  const {
    data: campaign,
    isLoading,
    isError,
  } = useGetCampaign(campaignId, { query: { enabled: canReadCampaign && !!campaignId } });

  return (
    <Stack px={4} py={2}>
      <PermissionWrapper
        errorMessage={t('marketing.campaigns.errors.fetchError')}
        isLoading={isLoading}
        isCircularLoadingStyle={true}
        isError={isError}
        permission={OnlineCasinoPermissions.getCampaign}
      >
        <>
          <IndividualActions campaignId={campaignId} individualActions={campaign?.individualActions} />
          <Templates campaignId={campaignId} templates={campaign?.templates} />
        </>
      </PermissionWrapper>
    </Stack>
  );
};

export default CampaignOverviewDetails;
