import { ChangeEvent, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  EAggregationFunctionSchema,
  EBoostTypeSchema,
  EMediaGalleryEntryTypeSchema,
  EPayoutTypeSchema,
  ImageSchema,
  IndividualActionSchema,
  UpdateIndividualActionSchema,
} from '@greenisland-api';
import { capitalize, InputAdornment, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

import { SelectInput } from '@greenisland-common/components/atoms';
import Input from '@greenisland-common/components/molecules/Input';
import { MediaFieldsWithPreview } from '@greenisland-common/components/molecules/MediaField';

import { ContentFilterButton } from '../../../../Content/ContentFilter/components';
import { LoyaltyDependentIconType } from '../../../Components/LoyaltyDependentValue/LoyaltyDependentValue';
import {
  BonusLoyaltyDependentTypeEnum,
  getDefaultIndividualActionNumberInputProps,
  useBonusDepositLoyaltyDependentOptions,
} from '../helpers';
import IndividualActionFormWrapper from '../IndividualActionFormWrapper';
import IndividualActionBonusLoyaltyDependantSection from './IndividualActionBonusLoyaltyDependantSection';
import IndividualActionBoostEarlyMaxPayoutFormSection from './IndividualActionBoostEarlyMaxPayoutFormSection';
import IndividualActionBoostFormTimeSettings from './IndividualActionBoostFormTimeSettings';

const MAX_PAYOUT_LOYALTY_DEPENDANT_KEY = 'boost.loyaltyDependentMaxPayout';

interface Props {
  individualAction?: IndividualActionSchema;
}

const IndividualActionBoostForm = ({ individualAction }: Props) => {
  const { t } = useTranslation();
  const loyaltyDependentOptions = useBonusDepositLoyaltyDependentOptions(
    BonusLoyaltyDependentTypeEnum.LIMIT,
    MAX_PAYOUT_LOYALTY_DEPENDANT_KEY
  );

  const { register, watch, setValue, errors, control } = useFormContext<UpdateIndividualActionSchema>();

  const isBoostEnabled = !!watch('boost.enabled');
  const watchIsMaxPayoutEnabled = !!watch('boost.isMaxPayoutEnabled');
  const watchIsEarlyMaxPayoutEnabled = watch('boost.isEarlyMaxPayoutEnabled');
  const watchBoostType = watch('boost.boostType') as EBoostTypeSchema;
  const watchBoostImages: ImageSchema[] | null = watch('boost.images');

  useEffect(() => {
    if (watchBoostImages) {
      const imagesIds = watchBoostImages?.map(image => image?.id);
      setValue('boost.imageIds', imagesIds?.length ? imagesIds : null);
    }
  }, [setValue, watchBoostImages]);

  useEffect(() => {
    if (isBoostEnabled && !watchIsEarlyMaxPayoutEnabled) {
      setValue('boost.earlyMaxPayoutEnabled', undefined);
    }
  }, [isBoostEnabled, setValue, watchIsEarlyMaxPayoutEnabled]);

  const handleLoyaltyDependentChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue('boost.isMaxPayoutEnabled', event.target.checked);
  };

  return (
    <>
      <IndividualActionFormWrapper
        enabled={isBoostEnabled}
        name="boost.enabled"
        listItemText={capitalize(t('boost.action.name'))}
      >
        <Controller
          name="boost.boostType"
          control={control}
          defaultValue={individualAction?.boost?.boostType || EBoostTypeSchema.FixedTime}
          render={({ name }) => {
            return (
              <ToggleButtonGroup
                value={watchBoostType}
                exclusive
                onChange={(_, value: EBoostTypeSchema) => setValue(name, value)}
              >
                <ToggleButton value={EBoostTypeSchema.FixedTime} aria-label={t('boost.type.fixedtime')}>
                  {capitalize(t('boost.type.fixedtime'))}
                </ToggleButton>
                <ToggleButton value={EBoostTypeSchema.SelfActivated} aria-label={t('boost.type.selfactivated')}>
                  {capitalize(t('boost.type.selfactivated'))}
                </ToggleButton>
                <ToggleButton value={EBoostTypeSchema.Immediate} aria-label={t('boost.type.immediate')}>
                  {capitalize(t('boost.type.immediate'))}
                </ToggleButton>
              </ToggleButtonGroup>
            );
          }}
        />
        <Input
          type="text"
          size="small"
          name="boost.name"
          label={capitalize(t('boost.name'))}
          inputRef={register({ required: isBoostEnabled, maxLength: 256 })}
          disabled={!isBoostEnabled}
          required={isBoostEnabled}
          error={Boolean(errors?.boost?.name)}
          fullWidth={true}
          helperText={errors?.boost?.name ? errors.boost.name.message : capitalize(t('boost.helpText.name.default'))}
          defaultValue={individualAction?.boost?.name}
        />
        <Typography fontWeight={600}>{capitalize(t('boost.subtitle.payoutSettings'))}</Typography>
        <SelectInput
          size="small"
          name="boost.aggregationFunction"
          label={t('boost.aggregationFunction')}
          options={[
            { value: EAggregationFunctionSchema.Max, label: EAggregationFunctionSchema.Max },
            { value: EAggregationFunctionSchema.Sum, label: EAggregationFunctionSchema.Sum },
          ]}
          required={isBoostEnabled}
          defaultValue={individualAction?.boost?.aggregationFunction}
        />
        <Input
          size="small"
          name="boost.factor"
          helperText={
            errors?.boost?.factor
              ? capitalize(t('boost.helpText.factor.error'))
              : capitalize(t('boost.helpText.factor.default'))
          }
          error={Boolean(errors?.boost?.factor)}
          disabled={!isBoostEnabled}
          required={isBoostEnabled}
          InputProps={{
            ...getDefaultIndividualActionNumberInputProps(1),
            endAdornment: <InputAdornment position={'end'}>%</InputAdornment>,
          }}
          inputRef={register({
            required: isBoostEnabled,
            setValueAs: value => (value ? Number(value) : 100),
          })}
          label={capitalize(t('boost.factor'))}
        />
        <SelectInput
          size="small"
          name="boost.payoutType"
          label={t('boost.payoutType')}
          options={[
            { value: EPayoutTypeSchema.Cash, label: EPayoutTypeSchema.Cash },
            { value: EPayoutTypeSchema.Voucher, label: EPayoutTypeSchema.Voucher },
          ]}
          required={isBoostEnabled}
          defaultValue={individualAction?.boost?.payoutType}
        />
        <IndividualActionBonusLoyaltyDependantSection
          enabledDeposit={isBoostEnabled}
          name="boost.maxPayout"
          isExtendedLoyaltyDependentMode={watchIsMaxPayoutEnabled}
          loyaltyDependantType={BonusLoyaltyDependentTypeEnum.LIMIT}
          mainLabel={t('boost.loyaltyDependantMaxPayout')}
          inputLabel={t('boost.maxPayout')}
          handleLoyaltyDependentChange={handleLoyaltyDependentChange}
          iconType={LoyaltyDependentIconType.EURO}
          loyaltyDependentOptions={loyaltyDependentOptions}
        />
        <IndividualActionBoostEarlyMaxPayoutFormSection
          switchControlName="boost.isEarlyMaxPayoutEnabled"
          name="boost.earlyMaxPayoutEnabled"
        />
        <Typography fontWeight={600}>{capitalize(t('boost.subtitle.contentSettings'))}</Typography>
        <ContentFilterButton control={control} name="boost.contentFilterId" />
        <Typography fontWeight={600}>{capitalize(t('boost.imageSettings'))}</Typography>
        <MediaFieldsWithPreview
          control={control}
          size="small"
          name="boost.images"
          error={Boolean(errors?.boost?.imageIds)}
          type="text"
          required={false}
          label={capitalize(t('addImages'))}
          mediaGalleryEntryType={EMediaGalleryEntryTypeSchema.BoostImage}
          isMultiSelect={true}
        />
        <IndividualActionBoostFormTimeSettings />
      </IndividualActionFormWrapper>
    </>
  );
};

export default IndividualActionBoostForm;
