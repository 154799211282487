import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FreeSpinsCampaignSchema, FreeSpinsCampaignsSchema, UpdateIndividualActionSchema } from '@greenisland-api';

import { uniq } from '@greenisland-common/helpers';

import { LoyaltyDependentIconType } from '../../../Components/LoyaltyDependentValue/LoyaltyDependentValue';
import { BonusLoyaltyDependentTypeEnum, DurationsEnum } from './individualActionsFormInterfaces';

const useGenerateFreeSpinsAutocompleteItems = (
  freeSpinsCampaigns: FreeSpinsCampaignsSchema | undefined,
  selectedProvider: string | null,
  selectedGame: string | null,
  selectedAmountOfSpins: string | null,
  selectedStake: string | null
) => {
  const { watch } = useFormContext<UpdateIndividualActionSchema>();
  const watchFreeSpinsCampaign: FreeSpinsCampaignSchema | undefined = watch('freeSpins.freeSpinsCampaign');

  const campaigns: FreeSpinsCampaignSchema[] = useMemo(() => {
    const filteredCampaigns =
      freeSpinsCampaigns?.filter(campaign => {
        return (
          (!selectedProvider || campaign.provider?.name === selectedProvider) &&
          (!selectedGame || campaign?.game?.name === selectedGame) &&
          (!selectedAmountOfSpins || campaign?.amountOfSpins?.toString() === selectedAmountOfSpins) &&
          (!selectedStake || campaign?.stake?.toString() === selectedStake) &&
          (!watchFreeSpinsCampaign || campaign?.name === watchFreeSpinsCampaign)
        );
      }) ?? [];

    return uniq(
      filteredCampaigns?.sort((a, b) => (a?.name ?? '')?.localeCompare(b?.name ?? '')),
      campaign => campaign?.name
    );
  }, [
    freeSpinsCampaigns,
    selectedAmountOfSpins,
    selectedGame,
    selectedProvider,
    selectedStake,
    watchFreeSpinsCampaign,
  ]);

  const providers = useMemo(() => {
    return watchFreeSpinsCampaign === null
      ? campaigns?.map(({ provider }) => provider?.name ?? '')?.filter(Boolean)
      : [watchFreeSpinsCampaign?.provider?.name ?? ''];
  }, [watchFreeSpinsCampaign, campaigns]);

  const games = useMemo(() => {
    return watchFreeSpinsCampaign === null
      ? campaigns?.flatMap(({ game }) => game?.name ?? '')?.filter(Boolean)
      : [watchFreeSpinsCampaign?.game?.name ?? ''];
  }, [watchFreeSpinsCampaign, campaigns]);

  const amountOfSpins = useMemo(() => {
    return watchFreeSpinsCampaign === null
      ? campaigns?.map(({ amountOfSpins }) => amountOfSpins ?? 0)?.filter(Boolean)
      : [watchFreeSpinsCampaign?.amountOfSpins ?? 0];
  }, [watchFreeSpinsCampaign, campaigns]);

  const stakes = useMemo(() => {
    return watchFreeSpinsCampaign === null
      ? campaigns?.map(({ stake }) => stake ?? 0)?.filter(Boolean)
      : [watchFreeSpinsCampaign?.stake ?? 0];
  }, [watchFreeSpinsCampaign, campaigns]);

  const sortedGames = useMemo(() => uniq(games?.sort((a, b) => a?.localeCompare(b))), [games]);
  const sortedStakes = useMemo(() => uniq(stakes?.sort((a, b) => a - b))?.map(String), [stakes]);
  const sortedProviders = useMemo(() => uniq(providers?.sort((a, b) => a?.localeCompare(b))), [providers]);
  const sortedAmountOfSpins = useMemo(() => uniq(amountOfSpins?.sort((a, b) => a - b))?.map(String), [amountOfSpins]);

  return {
    campaigns,
    sortedProviders,
    sortedGames,
    sortedAmountOfSpins,
    sortedStakes,
  };
};

const useDurationValues = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      SECONDS: { value: DurationsEnum.SECONDS, label: t('seconds') },
      MINUTES: { value: DurationsEnum.MINUTES, label: t('minutes') },
      HOURS: { value: DurationsEnum.HOURS, label: t('hours') },
      DAYS: { value: DurationsEnum.DAYS, label: t('days') },
    }),
    [t]
  );
};

const useBoostLoyaltyDependentOptions = () => {
  const { t } = useTranslation();
  return useMemo(() => {
    return [
      {
        label: t('marketing.loyaltyDependentMaxPayout.standard'),
        name: 'loyaltyDependentMaxPayout.standardValue',
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.bronze'),
        name: 'loyaltyDependentMaxPayout.bronzeValue',
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.silver'),
        name: 'loyaltyDependentMaxPayout.silverValue',
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.gold'),
        name: 'loyaltyDependentMaxPayout.goldValue',
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.platinum'),
        name: 'loyaltyDependentMaxPayout.platinumValue',
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.diamond'),
        name: 'loyaltyDependentMaxPayout.diamondValue',
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.vip'),
        name: 'loyaltyDependentMaxPayout.vipValue',
      },
    ];
  }, [t]);
};

const useGetDepositBonusLoyaltyDependentIcon = (iconType?: LoyaltyDependentIconType) => {
  return useMemo(() => {
    if (iconType === LoyaltyDependentIconType.EURO) return '€';
    else if (iconType === LoyaltyDependentIconType.PERCENTAGE) return '%';
    else if (iconType === LoyaltyDependentIconType.BONUS) return 'x';
    else return '';
  }, [iconType]);
};

const useBonusDepositLoyaltyDependentOptions = (type: BonusLoyaltyDependentTypeEnum, name: string) => {
  const { t } = useTranslation();
  return useMemo(() => {
    const percentageTypeOptions = [
      {
        label: t('marketing.loyaltyDependentMaxPayout.standard'),
        name: `${name}.standardValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.bronze'),
        name: `${name}.bronzeValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.silver'),
        name: `${name}.silverValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.gold'),
        name: `${name}.goldValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.platinum'),
        name: `${name}.platinumValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.diamond'),
        name: `${name}.diamondValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.vip'),
        name: `${name}.vipValue`,
      },
    ];
    const limitTypeOptions = [
      {
        label: t('marketing.loyaltyDependentMaxPayout.standard'),
        name: `${name}.standardValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.bronze'),
        name: `${name}.bronzeValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.silver'),
        name: `${name}.silverValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.gold'),
        name: `${name}.goldValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.platinum'),
        name: `${name}.platinumValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.diamond'),
        name: `${name}.diamondValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.vip'),
        name: `${name}.vipValue`,
      },
    ];
    const playthroughTypeOptions = [
      {
        label: t('marketing.loyaltyDependentMaxPayout.standard'),
        name: `${name}.standardValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.bronze'),
        name: `${name}.bronzeValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.silver'),
        name: `${name}.silverValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.gold'),
        name: `${name}.goldValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.platinum'),
        name: `${name}.platinumValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.diamond'),
        name: `${name}.diamondValue`,
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.vip'),
        name: `${name}.vipValue`,
      },
    ];

    if (type === BonusLoyaltyDependentTypeEnum.PERCENTAGE) return percentageTypeOptions;
    else if (type === BonusLoyaltyDependentTypeEnum.LIMIT) return limitTypeOptions;
    else if (type === BonusLoyaltyDependentTypeEnum.PLAYTHROUGH) return playthroughTypeOptions;
    else return [];
  }, [name, t, type]);
};

export {
  useGenerateFreeSpinsAutocompleteItems,
  useDurationValues,
  useBoostLoyaltyDependentOptions,
  useGetDepositBonusLoyaltyDependentIcon,
  useBonusDepositLoyaltyDependentOptions,
};
