import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ApproveCancelPlayingBreakRequestSchema,
  DeclineCancelPlayingBreakRequestSchema,
  EPlayingBreakBadRequestTypeSchema,
  ErrorSchema,
  getGetAgentTasksQueryKey,
  PlayingBreakBadRequestContentSchema,
  useCancelPlayingBreakV2,
  useDeclinePlayingBreakCancellationRequest,
} from '@greenisland-api';
import { LoadingButton } from '@mui/lab';
import { Button, capitalize, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

import { SelectInput } from '@greenisland-common/components/atoms';

import { useTenantContext } from '../../../../../../app/context/TenantContext';
import { PlayingBreakRequestType, useGetCooldownValues } from './PlayinBreakFormContext';
import { useSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';
import { PLAYING_BREAK_ERROR_REASONS } from '../../../../Lookup/Components/DetailsComponents/Constants/PlayingBreakErrorReasons';

interface Props {
  userId: number;
  playingBreakRequestType: PlayingBreakRequestType | null;
  onClose: () => void;
}

const PlayingBreakDialog = ({ userId, playingBreakRequestType, onClose }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { playingBreakConfigurations } = useTenantContext();
  const cooldownValues = useGetCooldownValues();

  const isCancelRequestType = playingBreakRequestType === PlayingBreakRequestType.CANCEL;

  const methods = useForm<ApproveCancelPlayingBreakRequestSchema>({
    mode: 'onChange',
    defaultValues: {
      cooldownDays: playingBreakConfigurations.timeoutDays,
      decisionReason: '',
    },
  });

  const { handleSubmit, reset, errors, register } = methods;

  const onCloseHandler = () => {
    onClose();
    reset();
  };

  const onSuccessHandler = () => {
    enqueueSnackbar(t('success'), { variant: 'success' });
    queryClient.invalidateQueries(getGetAgentTasksQueryKey());
    onCloseHandler();
  };

  const onErrorHandler = (error: PlayingBreakBadRequestContentSchema | ErrorSchema) => {
    if (error) {
      if ('type' in error && error.type) {
        const errorReason: EPlayingBreakBadRequestTypeSchema = error.type;
        const translationKey = errorReason ? PLAYING_BREAK_ERROR_REASONS[errorReason] : 'somethingWentWrong';
        enqueueSnackbar(t(translationKey), { variant: 'error' });
      } else {
        enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
      }
    }
  };

  const { mutate: cancelPlayingBreakMutation, isLoading: isCancelPlayingBreakMutationLoading } =
    useCancelPlayingBreakV2({
      mutation: {
        onSuccess: onSuccessHandler,
        onError: error => onErrorHandler(error),
      },
    });

  const { mutate: declinePlayingBreakMutation, isLoading: isDeclinePlayingBreakMutationLoading } =
    useDeclinePlayingBreakCancellationRequest({
      mutation: {
        onSuccess: onSuccessHandler,
        onError: error => onErrorHandler(error),
      },
    });

  const onSubmit = (data: ApproveCancelPlayingBreakRequestSchema) => {
    const payload = isCancelRequestType ? data : { decisionReason: data?.decisionReason };
    if (isCancelRequestType) {
      cancelPlayingBreakMutation({ userId, data: payload as ApproveCancelPlayingBreakRequestSchema });
    } else {
      declinePlayingBreakMutation({ userId, data: payload as DeclineCancelPlayingBreakRequestSchema });
    }
  };

  return (
    <Dialog fullWidth open={Boolean(playingBreakRequestType)}>
      <DialogTitle>
        {capitalize(isCancelRequestType ? t('playingbreak.cancel') : t('playingbreak.removeCancelRequest'))}
      </DialogTitle>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            {isCancelRequestType && (
              <SelectInput
                name="cooldownDays"
                label={t('playingbreak.cooldownDays')}
                rules={{ required: t('fieldIsRequired') }}
                options={cooldownValues}
                defaultValue=""
              />
            )}
            <TextField
              fullWidth
              name="decisionReason"
              label={t('reason')}
              error={Boolean(errors['decisionReason'])}
              helperText={errors['decisionReason']?.message}
              required={true}
              inputRef={register({ required: t('fieldIsRequired') })}
              sx={{ my: 1 }}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="error" onClick={onCloseHandler}>
              {t('cancel')}
            </Button>
            <LoadingButton
              loading={isCancelPlayingBreakMutationLoading || isDeclinePlayingBreakMutationLoading}
              variant="contained"
              color="error"
              type="submit"
              disabled={isCancelPlayingBreakMutationLoading || isDeclinePlayingBreakMutationLoading}
            >
              {t('proceed')}
            </LoadingButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default PlayingBreakDialog;
