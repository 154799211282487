import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, capitalize, FormControlLabel, Stack, Switch, Typography, useTheme } from '@mui/material';

import SwitchInput from '@greenisland-common/components/atoms/SwitchInput';

interface Props {
  switchControlName?: string;
  name: string;
}

const IndividualActionBoostEarlyMaxPayoutFormSection = ({ switchControlName = '', name }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { watch, setValue, control } = useFormContext();

  const isEarlyMaxPayoutEnabled = !!watch(switchControlName);

  const loyaltyDependentOptions = useMemo(
    () => [
      {
        title: 'marketing.loyaltyDependentMaxPayout.standard',
        name: `${name}.standardValue`,
      },
      {
        title: 'marketing.loyaltyDependentMaxPayout.bronze',
        name: `${name}.bronzeValue`,
      },
      {
        title: 'marketing.loyaltyDependentMaxPayout.silver',
        name: `${name}.silverValue`,
      },
      {
        title: 'marketing.loyaltyDependentMaxPayout.gold',
        name: `${name}.goldValue`,
      },
      {
        title: 'marketing.loyaltyDependentMaxPayout.platinum',
        name: `${name}.platinumValue`,
      },
      {
        title: 'marketing.loyaltyDependentMaxPayout.diamond',
        name: `${name}.diamondValue`,
      },
      {
        title: 'marketing.loyaltyDependentMaxPayout.vip',
        name: `${name}.vipValue`,
      },
    ],
    [name]
  );

  const updateAllLoyaltyValues = (enabled: boolean) => {
    return loyaltyDependentOptions.forEach(option => {
      setValue(option.name, enabled);
    });
  };

  return (
    <Box p={1} borderRadius="5px" border={`1px solid ${theme.palette.text.disabled}`}>
      <FormControlLabel
        sx={{ m: 0 }}
        control={
          <Controller
            name={switchControlName}
            control={control}
            render={({ value, onChange }) => (
              <Switch onChange={event => onChange(event.target.checked)} checked={value} />
            )}
          />
        }
        label={capitalize(t('boost.loyaltyDependentEarlyMaxPayoutEnabled'))}
      />
      {isEarlyMaxPayoutEnabled && (
        <>
          <Box
            display="flex"
            gap={1}
            py={2}
            sx={{
              [theme.breakpoints.up('xs')]: {
                flexDirection: 'column',
              },
              [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
              },
            }}
          >
            <Button size="small" variant="contained" color="primary" onClick={() => updateAllLoyaltyValues(true)}>
              {capitalize(t('common.enableAll'))}
            </Button>
            <Button size="small" variant="contained" color="error" onClick={() => updateAllLoyaltyValues(false)}>
              {capitalize(t('common.disableAll'))}
            </Button>
          </Box>
          <Stack>
            {loyaltyDependentOptions?.map((option, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap={1}
                pl={1}
                sx={{
                  [theme.breakpoints.up('xs')]: {
                    width: '100%',
                  },
                  [theme.breakpoints.up('sm')]: {
                    width: '150px',
                  },
                }}
              >
                <Typography variant="body2">{capitalize(t(option.title))}</Typography>
                <SwitchInput label="" name={option.name} sx={{ m: 0 }} />
              </Box>
            ))}
          </Stack>
        </>
      )}
    </Box>
  );
};

export default IndividualActionBoostEarlyMaxPayoutFormSection;
