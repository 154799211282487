import { ComponentPropsWithoutRef, MouseEvent, useCallback, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetContentFilterById } from '@greenisland-api';
import { Avatar, Box, Button, capitalize, Chip, CircularProgress, Stack, TextField } from '@mui/material';
import { green } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';

import { ExtendedContentFilterSchema } from '../helpers/ContentFilterFormContext';
import ContentFilterDialog from './ContentFilterDialog';

type Props = ComponentPropsWithoutRef<typeof TextField> & {
  name: string;
  control: Control;
};

const ContentFilterButton = (props: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { name, control } = props;
  const {
    field: { value, onChange },
  } = useController({ name, control });
  const [showContentFilterDialog, setShowContentFilterDialog] = useState(false);

  const { data: contentFilter, isLoading } = useGetContentFilterById(value, { query: { enabled: !!value } });

  const onSelectHandler = useCallback(
    (contentFilter: ExtendedContentFilterSchema) => {
      if (contentFilter) {
        onChange(contentFilter.id);
      }
      setShowContentFilterDialog(false);
    },
    [onChange]
  );

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowContentFilterDialog(true);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (contentFilter) {
    return (
      <Stack direction="column" spacing={1}>
        <p>{capitalize(t('content.contentFilter.title'))}</p>
        <Chip
          avatar={<Avatar sx={{ bgcolor: green[100] }}>{contentFilter?.id}</Avatar>}
          label={`${contentFilter?.name}`}
          onDelete={() => {
            onChange(undefined);
          }}
          size="medium"
          color="success"
          sx={{ width: 'fit-content' }}
        />
      </Stack>
    );
  }

  return (
    <>
      <Button
        variant="contained"
        size="medium"
        startIcon={<FontAwesomeIcon icon={faAdd} />}
        sx={{
          [theme.breakpoints.up('xs')]: {
            width: '100%',
          },
          [theme.breakpoints.up('md')]: {
            width: '50%',
          },
        }}
        onClick={handleClick}
      >
        {t('content.contentFilter.title')}
      </Button>
      <ContentFilterDialog
        isOpen={showContentFilterDialog}
        onSelectHandler={onSelectHandler}
        onClose={() => {
          setShowContentFilterDialog(false);
        }}
      />
    </>
  );
};

export default ContentFilterButton;
