import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EAggregationFunctionSchema, useGetBoostUserStatesForUser } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Card, CardContent, CardHeader } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';

import { DataGridContainer, DataGridPagination, PermissionWrapper } from '@greenisland-common/components/atoms';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import {
  formatCurrency,
  getDateTimeFromUnix,
  getDurationFromSeconds,
} from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';

type BoostsProps = {
  userId: string;
};

type AggregationFunctionKeys = {
  [key in EAggregationFunctionSchema]: string;
};

const aggregationFunctionKeys: AggregationFunctionKeys = {
  [EAggregationFunctionSchema.Sum]: 'boost.global.aggregationFunction.sum',
  [EAggregationFunctionSchema.Average]: 'boost.global.aggregationFunction.average',
  [EAggregationFunctionSchema.Count]: 'boost.global.aggregationFunction.count',
  [EAggregationFunctionSchema.Max]: 'boost.global.aggregationFunction.max',
  [EAggregationFunctionSchema.Min]: 'boost.global.aggregationFunction.min',
};

const Boosts = ({ userId }: BoostsProps) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const canReadUserBoosts = usePermission(OnlineCasinoPermissions.getBoostUserStatesForUser);

  const {
    data: boostsData,
    isLoading,
    isError,
  } = useGetBoostUserStatesForUser(
    parseInt(userId),
    { page: page, pageSize: pageSize },
    { query: { enabled: canReadUserBoosts } }
  );

  const headers: GridColumns = [
    {
      headerName: t('boost.table.header.name'),
      field: 'name',
    },
    {
      headerName: t('boost.table.header.boostType'),
      field: 'boostType',
      width: 120,
    },
    {
      headerName: t('boost.table.header.payoutType'),
      field: 'payoutType',
    },
    {
      headerName: t('boost.table.header.multiplier'),
      field: 'factor',
    },
    {
      headerName: t('boost.table.header.maxPayout'),
      field: 'userMaxPayout',
      valueFormatter: params => formatCurrency(params.value),
    },
    {
      headerName: t('boost.table.header.reward'),
      field: 'reward',
      valueFormatter: params => formatCurrency(params.value),
    },
    {
      headerName: t('boost.table.header.earlyMaxPayoutEnabled'),
      field: 'earlyMaxPayoutEnabled',
      width: 120,
      valueFormatter: params => {
        if (params.value) {
          return t('true');
        }
        return t('false');
      },
    },
    {
      headerName: t('boost.table.header.hasBeenPaidOut'),
      field: 'hasBeenPaidOut',
      valueFormatter: params => {
        if (params.value) {
          return t('true');
        }
        return t('false');
      },
    },
    {
      headerName: t('boost.table.header.payoutDate'),
      field: 'payoutDate',
      width: 180,
      valueFormatter: params => (params.value ? getDateTimeFromUnix(params.value) : '-'),
    },
    {
      headerName: t('boost.table.header.aggregationFunction'),
      field: 'aggregationFunction',
      width: 250,
      valueFormatter: params => t(aggregationFunctionKeys[params.value as EAggregationFunctionSchema]),
    },
    {
      headerName: t('boost.table.header.isLoyaltyDependent'),
      field: 'isLoyaltyDependent',
      width: 160,
      valueFormatter: params => {
        if (params.value) {
          return t('true');
        }
        return t('false');
      },
    },
    {
      headerName: t('boost.table.header.creationDate'),
      field: 'created',
      width: 180,
      valueFormatter: params => (params.value ? getDateTimeFromUnix(params.value) : '/'),
    },
    {
      headerName: t('boost.table.header.startDate'),
      field: 'startDate',
      width: 180,
      valueFormatter: params => (params.value ? getDateTimeFromUnix(params.value) : '/'),
    },
    {
      headerName: t('boost.table.header.endDate'),
      field: 'endDate',
      width: 180,
      valueFormatter: params => (params.value ? getDateTimeFromUnix(params.value) : '/'),
    },
    {
      headerName: t('boost.table.header.duration'),
      field: 'duration',
      valueFormatter: params => (params.value ? getDurationFromSeconds(params.value) : '/'),
    },
    {
      headerName: t('boost.table.header.count'),
      field: 'count',
    },
    {
      headerName: t('boost.table.header.contentFilterId'),
      field: 'contentFilterId',
      width: 150,
    },
    {
      headerName: t('boost.table.header.isActivated'),
      field: 'isActivated',
      valueFormatter: params => {
        if (params.value) {
          return t('true');
        }
        return t('false');
      },
    },
    {
      headerName: t('boost.table.header.expiryDate'),
      field: 'expiryDate',
      width: 180,
      valueFormatter: params => (params.value ? getDateTimeFromUnix(params.value) : '/'),
    },
  ];

  const rows: GridRowsProp = boostsData?.items
    ? boostsData.items.map((item, i) => {
        return {
          ...item,
          id: i,
        };
      })
    : [];

  return (
    <PermissionWrapper
      errorMessage={t('boost.global.errors.fetchError')}
      isError={isError}
      isLoading={isLoading}
      permission={OnlineCasinoPermissions.getBoostUserStatesForUser}
    >
      <Card>
        <CardHeader title={t('boost.global.titles.boosts')} />
        <CardContent>
          <DataGridContainer>
            <LimitedDataGrid
              autoHeight
              density="compact"
              columns={headers}
              rows={rows}
              pageSize={pageSize}
              paginationMode="server"
              pagination
              page={boostsData?.pagingDetails?.currentPage}
              rowCount={boostsData?.pagingDetails?.totalItems}
              onPageChange={setPage}
              onPageSizeChange={setPageSize}
              components={{ Pagination: DataGridPagination }}
              componentsProps={{ pagination: { rowsPerPageOptions: [10, 25, 50, 100, 200] } }}
            />
          </DataGridContainer>
        </CardContent>
      </Card>
    </PermissionWrapper>
  );
};

export default memo(Boosts);
