import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LoyaltyDependentDecimalSchema } from '@greenisland-api';
import { Box, InputAdornment } from '@mui/material';

import Input from '@greenisland-common/components/molecules/Input';

import { useGetDepositBonusLoyaltyDependentIcon } from '../../Campaigns/forms/helpers';
import { LoyaltyDependentIconType } from './LoyaltyDependentValue';

export const getLoyaltyDependentMaxPayoutValues = (
  isLoyaltyDependent: boolean,
  data: LoyaltyDependentDecimalSchema | undefined
): LoyaltyDependentDecimalSchema | undefined => {
  if (!isLoyaltyDependent || !data) return undefined;
  const loyaltyDependentMaxPayout: LoyaltyDependentDecimalSchema = {};

  Object.keys(data).forEach(property => {
    const value = data[property as keyof LoyaltyDependentDecimalSchema];
    if (value) {
      loyaltyDependentMaxPayout[property as keyof LoyaltyDependentDecimalSchema] = parseFloat(value.toString());
    }
  });

  return loyaltyDependentMaxPayout;
};

const defaultInputProps = {
  inputProps: {
    min: 0,
    step: 'any',
    type: 'number',
    pattern: '[0-9]*',
  },
};

interface Props {
  options: {
    label: string;
    name: string;
  }[];
  isRequired?: boolean;
  iconType?: LoyaltyDependentIconType;
}

const LoyaltyDependentValueDecimal = ({ options, iconType, isRequired = true }: Props) => {
  const { t } = useTranslation();
  const { register } = useFormContext();

  const icon = useGetDepositBonusLoyaltyDependentIcon(iconType);

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {options?.map((option, index) => (
        <Input
          key={index}
          size="small"
          label={option.label}
          name={option.name}
          inputRef={register({
            required: isRequired ? t('fieldIsRequired') : false,
            setValueAs: value => (value ? Number(value) : undefined),
          })}
          InputProps={{
            ...defaultInputProps,
            startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
          }}
          required={isRequired}
        />
      ))}
    </Box>
  );
};

export default LoyaltyDependentValueDecimal;
