import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useUpdateSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return useCallback(
    (key: string, value: string) => {
      if (value === null || value === '') {
        searchParams.delete('page');
        searchParams.delete(key);
      } else {
        searchParams.set('page', '0');
        searchParams.set(key, value);
      }
      setSearchParams(searchParams, { replace: true });
    },
    [searchParams, setSearchParams]
  );
};
