import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { useGetUserFreeSpins } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Link, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { endOfDay, getUnixTime, sub } from 'date-fns';

import { StyledDataGrid } from '@greenisland-common/components/atoms';
import { DateRangePicker } from '@greenisland-common/components/molecules/CustomDateRangePicker/DateRangePicker';

import { replaceUrlQueryParameters } from '@greenisland-common/helpers';

import {
  transformCurrencyV2,
  transformDecimalV2,
  transformDefaultV2,
  transformStatusV2,
  transformTypeV2,
  transformUnixDateV2,
} from '../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../app/hooks';
import { EventStreamParamsType } from '../Constants/Eventstreams';

const FreeSpinsPage = () => {
  const canReadFreeSpins = usePermission(OnlineCasinoPermissions.getUserFreeSpins);
  return canReadFreeSpins ? <FreeSpinsPageInnerChild /> : null;
};

const currentDate = new Date();

const FreeSpinsPageInnerChild = () => {
  const { t } = useTranslation();
  const { userId = '' } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = searchParams.get('startdate');
  const endDate = searchParams.get('enddate');
  const start = startDate ? Number(startDate) : getUnixTime(sub(currentDate, { days: 30 }));
  const end = endDate ? Number(endDate) : getUnixTime(endOfDay(currentDate));

  const { data: freeSpins, isLoading: freeSpinsLoading } = useGetUserFreeSpins(userId, { start, end });

  const columns: GridColDef[] = useMemo(() => {
    if (!freeSpins || freeSpins.length === 0) return [];
    return Object.keys(freeSpins[0])
      .map(key => {
        switch (key) {
          case 'expiresOn':
          case 'createdOn':
            return transformUnixDateV2(key, t);
          case 'status':
            return transformStatusV2(key, t);
          case 'stakePerSpin':
            return transformCurrencyV2(key, t);
          case 'amountOfSpins':
            return transformDecimalV2(key, t);
          case 'game':
            return transformDefaultV2(key, t, true);
          case 'freeSpinsType':
            return transformTypeV2(key, t);
          default:
            return transformDefaultV2(key, t);
        }
      })
      .concat({
        field: 'history',
        headerName: t('history'),
        sortable: false,
        filterable: false,
        renderCell: params => (
          <Link
            href={`../../lookup/eventstreams?${EventStreamParamsType.STREAM_ID}=${params.row.streamId.toLowerCase()}`}
          >
            {t('history')}
          </Link>
        ),
      });
  }, [freeSpins, t]);

  const rows = useMemo(() => {
    return (
      freeSpins?.map((item, index) => ({
        ...item,
        id: index,
        game: item.game?.name ?? '',
      })) || []
    );
  }, [freeSpins]);

  return (
    <Stack spacing={2}>
      <DateRangePicker
        unixStartDate={start * 1000}
        unixEndDate={end * 1000}
        onChange={(startDate, endDate) => {
          replaceUrlQueryParameters({ startDate, endDate }, setSearchParams);
        }}
      />
      <StyledDataGrid
        columns={columns}
        loading={freeSpinsLoading}
        rows={rows}
        autoHeight
        pagination
        pageSize={5}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        initialState={{
          columns: {
            columnVisibilityModel: {
              freeSpinsGameConfigurationFK: false,
              description: false,
              streamId: false,
            },
          },
        }}
      />
    </Stack>
  );
};

export default FreeSpinsPage;
